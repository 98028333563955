import {useState} from 'react'

import {CatalogoDropdown} from '../CatalogoDropdown'
import {CatalogoDropdownDate} from '../CatalogoDropdownDate'

import bookmark from '../../assets/img/bookmark.svg'
import edit from '../../assets/img/edit.svg'
import calender from '../../assets/img/calender.svg'
// import shop from '../../assets/img/shop.svg'
// import Jumbo from '../../assets/img/Jumbo.svg'
// import newFilter from '../../assets/img/filter_new.svg'
import dropdown from '../../assets/img/dropdown.svg'
import Cerrar from '../../assets/img/Cerrar.svg'
import explanationError from '../../assets/img/error_explanation.svg'
import explanationTick from '../../assets/img/tick_explanation.svg'

export const Catalogo = ({setActive}) => {
	const [FirstDropdown, setFirstDropdown] = useState(false)
	const [FirstFilter, setFirstFilter] = useState('Nacional')
	const arr = [
		'Arica',
		'Antofagasta',
		'La Serena',
		'Region Metropolitana',
		'Valparaiso',
	]
	return (
		<div className='popupWrapper OfertaWrapper CatalogoNew'>
			<div className='popup' style={{height: `585px`}}>
				<div className='close_icon' onClick={(e) => setActive(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<h1 style={{marginTop: 20}}>
					<img src={bookmark} alt='' />
					<span>
						<span>PROMO</span>BOOSTER:
					</span>
					Catálogo
				</h1>
				<div className='oferta_buttons'>
					<button>Crear Catálogo nuevo</button>
					<button>Crear a partir de Catálogo existente</button>
				</div>

				<div className='box_border' style={{marginTop: 20}}>
					<div className='input_wrapper'>
						<label htmlFor=''>
							<img src={edit} alt='' />
							Nombre del Catálogo
						</label>
						<input
							type='text'
							placeholder='Nombre del Catálogo'
							maxLength={25}
							onBlur={(e) => {
								if (e.target.value !== '') {
									e.target.classList.remove('error_oferto')
									e.target.classList.add('success_oferto')
								} else {
									e.target.classList.add('error_oferto')
									e.target.classList.remove('success_oferto')
								}
							}}
						/>
						<img src={explanationError} alt='' />
						<img src={explanationTick} alt='' />
					</div>
				</div>

				<div
					className='box_border'
					style={{marginTop: 15, zIndex: 9999, position: 'relative'}}
				>
					<div className='input_wrapper'>
						<label htmlFor=''>
							<img src={calender} alt='' />
							Fechas
						</label>
					</div>

					<div className='input_container'>
						<div className='input_wrapper' style={{height: 50}}>
							<label htmlFor=''>Fecha Vigencia</label>
							<CatalogoDropdownDate head='01/04/2022 al 31/04/2022' />
						</div>

						<div className='input_wrapper' style={{height: 50}}>
							<label htmlFor=''>Fecha</label>
							<CatalogoDropdown
								head='Selecciona un mes'
								list={[
									'Enero',
									'Febrero',
									'Marzo',
									'Abril',
									'Mayo',
									'Junio',
									'Enero',
									'Febrero',
									'Marzo',
									'Abril',
									'Mayo',
									'Junio',
								]}
							/>
						</div>
					</div>
				</div>

				<div className='box_border' style={{marginTop: 15}}>
					<div className='input_wrapper'>
						<label htmlFor=''>
							<img src={calender} alt='' />
							Segmentación
						</label>
					</div>

					<div className='input_container'>
						<div className='input_wrapper' style={{height: 50}}>
							<label htmlFor=''>Oficinas</label>

							<div className='dropdown dropdown_new'>
								<div
									className='head'
									onClick={(e) => {
										setFirstDropdown(!FirstDropdown)
									}}
								>
									<p>{FirstFilter}</p>
									<img src={dropdown} alt='' />
								</div>
								{FirstDropdown && (
									<ul>
										{arr.map((EachArr) => (
											<li key={EachArr}>
												<input
													type='radio'
													name='second_filter'
													id={`${EachArr}1`}
												/>
												<label
													htmlFor={`${EachArr}1`}
													onClick={(e) => {
														setFirstFilter(EachArr)
													}}
												></label>
												<p>{EachArr}</p>
											</li>
										))}
									</ul>
								)}
							</div>
						</div>

						<div className='input_wrapper' style={{height: 50}}>
							<label htmlFor=''>Línea</label>
							<CatalogoDropdown
								head='Selecciona un mes'
								list={[
									'Cecinas',
									'Lácteos',
									'Platos Preparados',
									'Elaborados',
									'Pizzas',
									'Proyectos',
									'Vegetales',
								]}
							/>
						</div>
					</div>
				</div>

				<div
					className='badge_long badge_long_catalogo'
					style={{
						gridTemplateColumns: '1fr 1fr 1fr 1fr',
						paddingLeft: '1rem',
						paddingRight: '1rem',
					}}
				>
					<div className='box_inner'>
						<h1>Canal</h1>
						<p>Tradicional</p>
					</div>
					<div className='box_inner'>
						<h1>Fecha</h1>
						<p>Abril 2022</p>
					</div>
					<div className='box_inner'>
						<h1>Oficinas</h1>
						<p>Nacional</p>
					</div>
					<div className='box_inner'>
						<h1>Línea</h1>
						<p>Cecinas</p>
					</div>
				</div>

				<div
					className='bottom_buttton'
					style={{
						paddingTop: 20,
						borderTop: `1px solid #aeb0b0`,
					}}
				>
					<button>Volver atrás</button>
					<button>Continuar</button>
				</div>
			</div>
		</div>
	)
}
