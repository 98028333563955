import {CatalogoDropdownDate} from '../CatalogoDropdownDate'
import {CatalogoDropdown} from '../CatalogoDropdown'

import Cerrar from '../../assets/img/Cerrar.svg'

export const CambiarFechasPopup = ({setActive}) => {
	return (
		<div className='popupWrapper OfertaWrapper CatalogoNew CambiarFechasPopup'>
			<div className='popup' style={{height: `585px`}}>
				<div className='close_icon' onClick={(e) => setActive(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<h1 style={{marginTop: 10}}>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<mask
							id='mask0_2586_636828'
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='24'
							height='24'
						>
							<rect width='24' height='24' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_2586_636828)'>
							<path
								d='M4.95 22.8499C4.16667 22.8499 3.5 22.5709 2.95 22.0129C2.4 21.4542 2.125 20.7916 2.125 20.0249V5.8999C2.125 5.11657 2.4 4.4499 2.95 3.8999C3.5 3.3499 4.16667 3.0749 4.95 3.0749H5.2V2.4999C5.2 2.13324 5.32933 1.81657 5.588 1.5499C5.846 1.28324 6.15833 1.1499 6.525 1.1499C6.90833 1.1499 7.22933 1.28324 7.488 1.5499C7.746 1.81657 7.875 2.13324 7.875 2.4999V3.0749H16.125V2.4999C16.125 2.13324 16.2543 1.81657 16.513 1.5499C16.771 1.28324 17.0917 1.1499 17.475 1.1499C17.8417 1.1499 18.1543 1.28324 18.413 1.5499C18.671 1.81657 18.8 2.13324 18.8 2.4999V3.0749H19.05C19.8333 3.0749 20.5 3.3499 21.05 3.8999C21.6 4.4499 21.875 5.11657 21.875 5.8999V12.6749H19.05V9.5749H4.95V20.0249H12V22.8499H4.95ZM4.95 7.0249H19.05V5.8999H4.95V7.0249ZM22.825 18.1249L20.125 15.4249L20.85 14.6999C21.1167 14.4332 21.4457 14.2999 21.837 14.2999C22.229 14.2999 22.5583 14.4332 22.825 14.6999L23.55 15.4249C23.8167 15.7082 23.95 16.0459 23.95 16.4379C23.95 16.8292 23.8167 17.1499 23.55 17.3999L22.825 18.1249ZM14 23.5749V22.1499C14 21.9499 14.0333 21.7709 14.1 21.6129C14.1667 21.4542 14.2667 21.2999 14.4 21.1499L19.425 16.1249L22.125 18.8249L17.1 23.8499C16.9667 23.9999 16.8167 24.1042 16.65 24.1629C16.4833 24.2209 16.3083 24.2499 16.125 24.2499H14.7C14.5 24.2499 14.3333 24.1872 14.2 24.0619C14.0667 23.9372 14 23.7749 14 23.5749Z'
								fill='url(#paint0_linear_2586_636828)'
							/>
						</g>
						<defs>
							<linearGradient
								id='paint0_linear_2586_636828'
								x1='3.0098'
								y1='1.14991'
								x2='28.844'
								y2='2.68381'
								gradientUnits='userSpaceOnUse'
							>
								<stop stopColor='#FFAC23' />
								<stop offset='1' stopColor='#FF5334' />
							</linearGradient>
						</defs>
					</svg>

					<span>Cambiar Fechas</span>
				</h1>
				<p>Define nuevas fechas para el Catálogo</p>

				<div
					className='box_border'
					style={{marginTop: 15, zIndex: 9999, position: 'relative'}}
				>
					<div className='input_container'>
						<div className='input_wrapper' style={{height: 50}}>
							<label htmlFor=''>Fecha Vigencia</label>
							<CatalogoDropdownDate head='01/04/2022 al 31/04/2022' />
						</div>

						<div className='input_wrapper' style={{height: 50}}>
							<label htmlFor=''>Fecha</label>
							<CatalogoDropdown
								head='Selecciona un mes'
								list={[
									'Enero',
									'Febrero',
									'Marzo',
									'Abril',
									'Mayo',
									'Junio',
									'Enero',
									'Febrero',
									'Marzo',
									'Abril',
									'Mayo',
									'Junio',
								]}
							/>
						</div>
					</div>
				</div>

				<div
					className='badge_long badge_long_catalogo'
					style={{
						gridTemplateColumns: '1fr 1fr',
						paddingLeft: '.5rem',
						paddingRight: '.5rem',
					}}
				>
					<div className='box_inner'>
						<h1>Fecha Vigencia</h1>
						<p>01/04/22 al 29/04/22</p>
					</div>
					<div className='box_inner'>
						<h1>Mes</h1>
						<p>Abril 2022</p>
					</div>
				</div>

				<div className='bottom_buttton'>
					<button>Cancelar</button>
					<button>Aceptar</button>
				</div>
			</div>
		</div>
	)
}
