import React from 'react'
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts'

const data = [
	{
		name: '01',
		uv: 4000,
		pv: 2400,
		zv: 1200,
		amt: 2400,
	},
	{
		name: '02',
		uv: 3000,
		pv: 1398,
		zv: 900,
		amt: 2210,
	},
	{
		name: '03',
		uv: 2000,
		pv: 9800,
		zv: 1080,
		amt: 2290,
	},
	{
		name: '04',
		uv: 2780,
		pv: 3908,
		zv: 2080,
		amt: 2000,
	},
	{
		name: '05',
		uv: 1890,
		pv: 4800,
		zv: 3080,
		amt: 2181,
	},
	{
		name: '06',
		uv: 2390,
		pv: 3800,
		zv: 1980,
		amt: 2500,
	},
	{
		name: '07',
		uv: 3490,
		pv: 4300,
		zv: 1680,
		amt: 2100,
	},
]

export const BixialLineChart = () => {
	return (
		<div style={{height: 300, width: '100%'}}>
			<ResponsiveContainer width='100%' height='100%'>
				<LineChart
					width={500}
					height={300}
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' stroke='#F1F1F1' />
					<XAxis
						dataKey='name'
						style={{
							fontSize: '10px',
							fontFamily: 'Montserrat',
						}}
						tick={{fill: '#7A7A7A'}}
						tickLine={{stroke: '#fff'}}
						stroke='#F1F1F1'
					/>
					<YAxis
						yAxisId='left'
						style={{
							fontSize: '10px',
							fontFamily: 'Montserrat',
						}}
						tick={{fill: '#7A7A7A'}}
						tickLine={{stroke: '#fff'}}
						stroke='#F1F1F1'
					/>
					<YAxis
						yAxisId='right'
						orientation='right'
						style={{
							fontSize: '10px',
							fontFamily: 'Montserrat',
						}}
						tick={{fill: '#7A7A7A'}}
						tickLine={{stroke: '#fff'}}
						stroke='#F1F1F1'
					/>
					<Tooltip />
					<Legend
						formatter={(value, entry, index) => (
							<span
								className='text-color-class'
								style={{
									fontSize: 13,
									color: '#000',
									fontWeight: 600,
									fontFamily: 'Montserrat',
								}}
							>
								{value}
							</span>
						)}
					/>
					<Line
						yAxisId='left'
						type='monotone'
						dataKey='pv'
						stroke='#FFAC24'
						activeDot={{r: 8}}
					/>
					<Line
						yAxisId='right'
						type='monotone'
						dataKey='zv'
						stroke='#3775F5'
						activeDot={{r: 8}}
					/>
					<Line yAxisId='right' type='monotone' dataKey='uv' stroke='#7A7A7A' />
				</LineChart>
			</ResponsiveContainer>
		</div>
	)
}
