import React, {useState} from 'react'
import {Navbar} from './Navbar'
import '../assets/css/ResumenDeCataloge.css'
import arrowLeft from '../assets/img/arrow_left.svg'
import {CataLogoResumenFooter} from './CataLogoResumenFooter'
import {Link} from 'react-router-dom'
import {NombreTable} from './NombreTable'
import Icono from '..//assets/img/Icono.svg'
import reset from '..//assets/img/reset.svg'
import time from '..//assets/img/time.svg'
import AvatarPop from '../assets/img/AvatarPop.svg'
import Avatargirl from '../assets/img/Avatargirl.svg'
import {Usuario} from './Usuario'
import visibility from '../assets/img/visibility.svg'
import Table from 'react-bootstrap/Table'
import {TopNavigator} from './TopNavigator'
export const ResumenDeCataloge = () => {
	const [TextareaFocus, setTextareaFocus] = useState(false)
	const [disableCheck, setdisableCheck] = useState(true)
	return (
		<div className='CataLogoResumen ResumenDeCatalogeNew'>
			<TopNavigator />
			<Navbar no_need_search />
			<div className='top_area'>
				<div className='left_side'>
					<Link to='/ResumenDeCataloge' className='active'>
						Resumen Catálogo
					</Link>
					<Link to='/Seguimiento'>Seguimiento Catálogo</Link>
					<Link to='/CataLogoResumen'>Resumen Línea</Link>
				</div>
				<div className='right_side'>
					<img src={arrowLeft} alt='' />
					<p>Ve al Resumen de Línea para seguir editando el catálogo</p>
				</div>
			</div>
			<div className='resumen_box_shadow_wrapper'>
				<NombreTable />

				<div className='grid_area_resumen_de_cataloge'>
					<div className='chat_area'>
						<div className='head'>
							<img src={Icono} alt='' />
							<p>Usuarios</p>
						</div>
						<Usuario />
						<Usuario />
						<Usuario />
						<Usuario />
						<Usuario />
					</div>

					<div className='chat_area profile_Area_resumen'>
						<div className='head'>
							<img src={reset} alt='' />
							<p>Actividad</p>
						</div>

						<div className='profile_box_wrapper profile_box_wrapperNew'>
							<div className='profile_box' style={{alignItems: 'flex-start'}}>
								<img src={Avatargirl} alt='' />
								<div className='presentation' style={{position: 'relative'}}>
									<textarea
										cols='30'
										rows='10'
										className={`${TextareaFocus && 'messageType'}`}
										placeholder='Escribe un comentario...'
										onChange={(e) => {
											if (e.target.value !== '') {
												setdisableCheck(false)
											} else {
												setdisableCheck(true)
											}
										}}
										onFocus={(e) => {
											setTextareaFocus(true)
										}}
										onBlur={(e) => {
											setTextareaFocus(false)
										}}
									></textarea>
									<button className='comment' disabled={disableCheck}>
										Comment
									</button>
								</div>
							</div>

							<div className='profile_box responder'>
								<img src={Avatargirl} alt='' />
								<div className='presentation'>
									<div className='top_area'>
										<h1>María M.</h1>
										<p>hace 10 mínutos</p>
									</div>
									<input
										type='text'
										value='Saquemos el Jamón Ahumado'
										readOnly
										className='responder'
									/>
									{/* <button>Responder</button> */}
								</div>
							</div>

							<div className='profile_box'>
								<img src={AvatarPop} alt='' />
								<div className='presentation'>
									<div className='top_area'>
										<h1>Juan Martínez</h1>
										<p>ha movido esta promoción al estado</p>
										<span>EN EDICIÓN</span>
									</div>
									<p>31 / 01 / 2022 a las 14:25</p>
								</div>
							</div>
							<div className='profile_box'>
								<img src={AvatarPop} alt='' />
								<div className='presentation'>
									<div className='top_area'>
										<h1>Pedro Perez</h1>
										<p>ha movido esta promoción al estado</p>
									</div>
									<p>31 / 01 / 2022 a las 14:25</p>
								</div>
							</div>
							<div className='profile_box'>
								<img src={AvatarPop} alt='' />
								<div className='presentation'>
									<div className='top_area'>
										<h1>María M.</h1>
										<p>ha movido esta promoción al estado</p>
									</div>
									<p>31 / 01 / 2022 a las 14:25</p>
								</div>
							</div>
							<div className='profile_box'>
								<img src={AvatarPop} alt='' />
								<div className='presentation'>
									<div className='top_area'>
										<h1>María M.</h1>
										<p>ha movido esta promoción al estado</p>
									</div>
									<p>31 / 01 / 2022 a las 14:25</p>
								</div>
							</div>
							<div className='profile_box'>
								<img src={AvatarPop} alt='' />
								<div className='presentation'>
									<div className='top_area'>
										<h1>María M.</h1>
										<p>ha movido esta promoción al estado</p>
									</div>
									<p>31 / 01 / 2022 a las 14:25</p>
								</div>
							</div>
						</div>
					</div>

					<div className='chat_area table_third_resumen'>
						<div className='head'>
							<img src={time} alt='' />
							<p>Versiones</p>
						</div>

						<Table>
							<thead>
								<tr>
									<th>Fecha</th>
									<th>Hora</th>
									<th>Fase</th>
									<th>Estado</th>
									<th>Usuario</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<p>18/08/2022</p>
									</td>
									<td>
										<p>11:30</p>
									</td>
									<td>
										<p>Propuesta Inicial</p>
									</td>
									<td>
										<p>En Edición</p>
									</td>
									<td>
										<div className='d-flex'>
											<p>María M.</p>
											<img src={visibility} alt='' />
										</div>
									</td>
								</tr>

								<tr>
									<td>
										<p>18/08/2022</p>
									</td>
									<td>
										<p>11:30</p>
									</td>
									<td>
										<p>Propuesta Inicial</p>
									</td>
									<td>
										<p>En Edición</p>
									</td>
									<td>
										<div className='d-flex'>
											<p>María M.</p>
											<img src={visibility} alt='' />
										</div>
									</td>
								</tr>

								<tr>
									<td>
										<p>18/08/2022</p>
									</td>
									<td>
										<p>11:30</p>
									</td>
									<td>
										<p>Propuesta Inicial</p>
									</td>
									<td>
										<p>En Edición</p>
									</td>
									<td>
										<div className='d-flex'>
											<p>María M.</p>
											<img src={visibility} alt='' />
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<p>18/08/2022</p>
									</td>
									<td>
										<p>11:30</p>
									</td>
									<td>
										<p>Propuesta Inicial</p>
									</td>
									<td>
										<p>En Edición</p>
									</td>
									<td>
										<div className='d-flex'>
											<p>María M.</p>
											<img src={visibility} alt='' />
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<p>18/08/2022</p>
									</td>
									<td>
										<p>11:30</p>
									</td>
									<td>
										<p>Propuesta Inicial</p>
									</td>
									<td>
										<p>En Edición</p>
									</td>
									<td>
										<div className='d-flex'>
											<p>María M.</p>
											<img src={visibility} alt='' />
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<p>18/08/2022</p>
									</td>
									<td>
										<p>11:30</p>
									</td>
									<td>
										<p>Propuesta Inicial</p>
									</td>
									<td>
										<p>En Edición</p>
									</td>
									<td>
										<div className='d-flex'>
											<p>María M.</p>
											<img src={visibility} alt='' />
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<p>18/08/2022</p>
									</td>
									<td>
										<p>11:30</p>
									</td>
									<td>
										<p>Propuesta Inicial</p>
									</td>
									<td>
										<p>En Edición</p>
									</td>
									<td>
										<div className='d-flex'>
											<p>María M.</p>
											<img src={visibility} alt='' />
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<p>18/08/2022</p>
									</td>
									<td>
										<p>11:30</p>
									</td>
									<td>
										<p>Propuesta Inicial</p>
									</td>
									<td>
										<p>En Edición</p>
									</td>
									<td>
										<div className='d-flex'>
											<p>María M.</p>
											<img src={visibility} alt='' />
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<p>18/08/2022</p>
									</td>
									<td>
										<p>11:30</p>
									</td>
									<td>
										<p>Propuesta Inicial</p>
									</td>
									<td>
										<p>En Edición</p>
									</td>
									<td>
										<div className='d-flex'>
											<p>María M.</p>
											<img src={visibility} alt='' />
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<p>18/08/2022</p>
									</td>
									<td>
										<p>11:30</p>
									</td>
									<td>
										<p>Propuesta Inicial</p>
									</td>
									<td>
										<p>En Edición</p>
									</td>
									<td>
										<div className='d-flex'>
											<p>María M.</p>
											<img src={visibility} alt='' />
										</div>
									</td>
								</tr>
							</tbody>
						</Table>
					</div>
				</div>
			</div>
			<CataLogoResumenFooter />
		</div>
	)
}
