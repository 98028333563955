import React from 'react'

export const ProductSearch = ({
	topText,
	mainp,
	bottomp1,
	bottomp2,
	img,
	orange = null,
	black = null,
}) => {
	return (
		<div className='ActiviyBox'>
			<div className='top_area'>
				<p className={`badge ${orange && 'orange'} ${black && 'black'}`}>
					{topText}
				</p>
				<span>01 / 01 / 2022</span>
			</div>
			<p>{mainp}</p>
			<div className='bottom_area'>
				<p style={{cursor: 'pointer'}}>{bottomp1}</p>
				<p style={{cursor: 'pointer'}}>{bottomp2}</p>

				<div className='img_wrapper'>
					<img src={img} alt='' />
				</div>
			</div>
		</div>
	)
}
