import {useReducer} from 'react'
import {AuthContext} from './AuthContext'
import {authReducer} from './authReducer'
import {types} from './types'
import {signIn} from '../../services/auht.service'

const user = localStorage.getItem('user_data')
	? localStorage.getItem('user_data')
	: ''
const token = localStorage.getItem('token') ? localStorage.getItem('token') : ''

const initialState = {
	logged: !!user,
	user: user || '',
	token: token || '',
	loading: false,
	errorMessage: null,
}

export const AuthProvider = ({children}) => {
	const [authState, dispatch] = useReducer(authReducer, initialState)

	const login = async (email, password) => {
		try {
			dispatch({
				type: types.request,
			})
			const response = await signIn({email, password})

			if (response.data.user_data.id) {
				dispatch({
					type: types.login,
					payload: response.data,
				})
				console.log(response);
				localStorage.setItem('token', response.data.token)
				localStorage.setItem('user_data', JSON.stringify(response.data))
				localStorage.setItem('nameUser', response.data.user_data.nombre)
				localStorage.setItem('lastName', response.data.user_data.apellido)
				localStorage.setItem('urlPhoto', response.data.user_data.url_foto)
				localStorage.setItem('idUser', response.data.user_data.id)
				localStorage.setItem(
					'rol_and_permissions',
					JSON.stringify(response.data.rol_and_permissions)
				)
			} else {
				console.log("ERROR DETECTADO");
				dispatch({
					type: types.error,
					payload: response.data.response,
				})
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: types.error,
				payload: error?.toString(),
			})
		}
	}

	const logout = async () => {
		dispatch({type: types.logout})
		localStorage.removeItem('user_data')
		localStorage.removeItem('token')
		localStorage.removeItem('rol_and_permissions')
	}

	return (
		<AuthContext.Provider value={{authState, login, logout}}>
			{children}
		</AuthContext.Provider>
	)
}
