import React, {useState} from 'react'
import PromoBooster from '../assets/img/PromoBooster.svg'
import {Link, useNavigate} from 'react-router-dom'
import lineBg from '../assets/img/line_bg.svg'
import eye from '../assets/img/eye.svg'
export const ResetPasswordFound = () => {
	const navigate = useNavigate()

	const [pass, setpass] = useState('')
	const [confirmpass, setconfirmpass] = useState('')
	const [ConfirmPassError, setConfirmPassError] = useState(null)
	const [PassError, setPassError] = useState(null)
	const [EmailError, setEmail] = useState(null)
	const handleLogin = (e) => {
		e.preventDefault()
		if (confirmpass === '' || pass === '') {
			/* empty */
		} else if (confirmpass === pass) {
			navigate('/PasswordChangeSuccess')
		} else {
			setConfirmPassError(false)
		}
	}

	return (
		<div className='Login ResetPassword change-pass'>
			<img src={lineBg} alt='' style={{opacity: 0.4}} />
			<form action=''>
				<h1>Reestablece tu Contraseña</h1>
				<p>
					Las contraseñas fuertes incluyen números, letras y signos de
					puntuación.
				</p>
				<div
					className={`input_wrapper ${
						PassError === true
							? 'input_active'
							: PassError === false && 'passerror'
					}`}
				>
					<input
						type='password'
						value={pass}
						onChange={(e) => {
							setpass(e.target.value)
						}}
						onFocus={(e) => {
							setPassError(true)
						}}
						onBlur={(e) => {
							if (e.target.value === '') {
								setPassError(null)
							}
						}}
						placeholder='Ingresa tu nueva contraseña'
					/>
					<img
						src={eye}
						alt=''
						onClick={(e) => {
							const parent = e.target.closest('.input_wrapper')
							const input = parent.querySelector('input')
							if (input.type === 'password') {
								input.type = 'text'
							} else {
								input.type = 'password'
							}
						}}
					/>
				</div>
				<div
					className={`input_wrapper ${
						ConfirmPassError === true
							? 'input_active'
							: ConfirmPassError === false && 'passerror'
					} `}
				>
					<input
						type='password'
						value={confirmpass}
						onChange={(e) => {
							setconfirmpass(e.target.value)
						}}
						onFocus={(e) => {
							setConfirmPassError(true)
						}}
						onBlur={(e) => {
							if (e.target.value === '') {
								setConfirmPassError(null)
							}
						}}
						placeholder='Ingresa tu contraseña una vez más'
					/>
					<img
						src={eye}
						alt=''
						onClick={(e) => {
							const parent = e.target.closest('.input_wrapper')

							const input = parent.querySelector('input')

							if (input.type === 'password') {
								input.type = 'text'
							} else {
								input.type = 'password'
							}
						}}
					/>
				</div>
				{ConfirmPassError === false && (
					<p className='error_code'>El usuario/contrasena es invalido</p>
				)}

				<div className='button_wrapper button_wrapper_new'>
					<button onClick={handleLogin}>Reestablecer Contraseña</button>

					<Link>Cancelar</Link>
				</div>
			</form>
			<img src={PromoBooster} className='logo_one' alt='' />
		</div>
	)
}
