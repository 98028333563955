import {useState} from 'react'
import {Link} from 'react-router-dom'
import Table from 'react-bootstrap/Table'

import {Navbar} from '../../components/Navbar'
import downwardArrow from '../../assets/img/arrow_downward.svg'
import {TopNavigator} from '../../components/TopNavigator'
import {CombiarPopup} from '../../components/CombiarPopup'
import {RenombrarPopup} from '../../components/RenombrarPopup'
import {CataLogoResumenTableCopy} from '../../components/CataLogoResumenTableCopy'
import {CataLogoResumenFooter} from '../../components/CataLogoResumenFooter'

import '../../assets/css/CataLogoResumen.css'

export const CataLogoResumen = () => {
	// const [active, setActive] = useState(false)
	const [combiar, setcombiar] = useState(false)
	const [Renombrar, setRenombrar] = useState(false)
	return (
		<div className='CataLogoResumen'>
			<TopNavigator />
			<Navbar no_need_search />
			{combiar && <CombiarPopup setcombiar={setcombiar} />}
			{Renombrar && <RenombrarPopup setRenombrar={setRenombrar} />}

			<div className='top_area top_area_rev'>
				<div className='left_side' style={{flex: 'none'}}>
					<Link to='/Seguimiento'>Seguimiento Catálogo</Link>
					<Link to='/CataLogoResumen' className='active'>
						Resumen Línea
					</Link>
				</div>
				<div className='right_side'>
					<img src={downwardArrow} alt='' />
					<p>Selecciona una Línea para ver sus Familias</p>
				</div>
			</div>
			<div className='link_area'>
				<p>Jumbo Abril 2022</p>
				<p>/</p>
				<p>Resumen Línea</p>
			</div>

			<div className='option_table' style={{marginTop: 0}}>
				<Table>
					<tbody>
						<tr>
							<td className='active'>
								<Link to='/CataLogoResumen'>Todas</Link>
							</td>
							<td>
								<Link to='/Cecinas'>Cecinas</Link>
							</td>
							<td>
								<Link to='/'>Lácteos</Link>
							</td>
							<td>
								<Link to='/'>Platos Prep.</Link>
							</td>
							<td>
								<Link to='/'>Elaborados</Link>
							</td>
							<td>
								<Link to='/'>Pizzas</Link>
							</td>
							<td>
								<Link to='/'>Proyectos</Link>
							</td>
							<td>
								<Link to='/'>Vegetales</Link>
							</td>
						</tr>
					</tbody>
				</Table>
			</div>

			{/* <CataLogoResumenTable /> */}
			<CataLogoResumenTableCopy />

			<CataLogoResumenFooter
				setcombiar={setcombiar}
				setRenombrar={setRenombrar}
			/>
		</div>
	)
}
