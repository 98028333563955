import {types} from './types'

export const catalogReducer = (state = {}, action) => {
	console.log(action)
	switch (action.type) {
		case types.request:
			return {
				...state,
				loading: true,
			}

		case types.catalogs:
			return {
				...state,
				loading: false,
				catalogs: action.payload,
			}
			
		case types.error:
			return {
				...state,
				loading: false,
				errorMessage: action.payload,
			}

		default:
			return state
	}
}
