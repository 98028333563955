import iconCatalog from '../../assets/img/Iconodel.svg'

export const CatalogDelete = ({setActive}) => {
	return (
		<div
			className='popupWrapper'
			id='popupCatalog'
			onClick={(e) => {
				if (e.target.id === 'popupCatalog') {
					setActive(false)
				}
			}}
		>
			<div className='popup SaveCatalog' style={{height: 232}}>
				<img src={iconCatalog} alt='' />
				<h1>
					¿Estás seguro que deseas <br /> eliminar el catálogo?
				</h1>
				<p>
					Si la eliminas, no aparecerá en las Promociones Activas <br /> y sólo
					podrás verlo desde el Historial de Promociones
				</p>
				<div className='buttons_wrapper'>
					<button className='fillbtn'>Seguir Editando</button>
					<button className='delbtn'>Eliminar Catálogo</button>
				</div>
			</div>
		</div>
	)
}
