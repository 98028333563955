import React from 'react'
import {
	ScatterChart,
	Scatter,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Cell,
	Legend,
	ResponsiveContainer,
} from 'recharts'

const data = [
	{x: 100, y: 200, z: 200},
	{x: 120, y: 100, z: 260},
	{x: 170, y: 300, z: 400},
	{x: 140, y: 250, z: 280},
	{x: 150, y: 400, z: 500},
	{x: 110, y: 280, z: 200},
]
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink']
export const ScatterChartComponent = () => {
	return (
		<div style={{height: 300, width: '92%'}}>
			<ResponsiveContainer width='100%' height='100%'>
				<ScatterChart
					width={400}
					height={400}
					margin={{
						top: 20,
						right: 20,
						bottom: 20,
						left: 20,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' stroke='#F1F1F1' />
					<XAxis
						type='number'
						dataKey='x'
						name='stature'
						unit='cm'
						style={{
							fontSize: '10px',
							fontFamily: 'Montserrat',
						}}
						tick={{fill: '#7A7A7A'}}
						tickLine={{stroke: '#fff'}}
						stroke='#F1F1F1'
					/>
					<YAxis
						type='number'
						dataKey='y'
						name='weight'
						unit='kg'
						style={{
							fontSize: '10px',
							fontFamily: 'Montserrat',
						}}
						tick={{fill: '#7A7A7A'}}
						tickLine={{stroke: '#fff'}}
						stroke='#F1F1F1'
					/>
					<Tooltip cursor={{strokeDasharray: '3 3'}} />
					<Scatter name='A school' data={data} fill='#8884d8'>
						{data.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={COLORS[index % COLORS.length]}
							/>
						))}
					</Scatter>
					<Legend
						formatter={(value, entry, index) => (
							<span
								className='text-color-class'
								style={{
									fontSize: 13,
									color: '#000',
									fontWeight: 600,
									fontFamily: 'Montserrat',
								}}
							>
								{value}
							</span>
						)}
					/>
				</ScatterChart>
			</ResponsiveContainer>
		</div>
	)
}
