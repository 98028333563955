import React from 'react'

export const ActiviyBox = ({
	topText,
	mainp,
	bottomp1,
	bottomp2,
	img,
	orange = null,
	black = null,
	Offerta = null,
	catalogo = null,
	jumbo = null,
	tutto = null,
	bgColor = null,
	percentage = null,
	date = null,
	setActivityactive,
	Activityactive,
}) => {
	return (
		<div
			className='ActiviyBox'
			onClick={(e) => {
				setActivityactive({
					img,
					percentage,
				})
			}}
		>
			<div className='top_area'>
				<p className={`badge ${orange && 'orange'} ${black && 'black'}`}>
					{topText}
				</p>
				<span>01 / 01 / 2022</span>
				<div className='percentage'>
					<span style={{backgroundColor: bgColor}}>{percentage}</span>
				</div>
			</div>
			<p>{mainp}</p>
			<div className='bottom_area'>
				<p>{date}</p>
				<p
					style={{cursor: 'pointer'}}
					onClick={(e) => {
						if (bottomp1 === 'OFERTA') {
							Offerta()
						}
						if (bottomp1 === 'CATÁLOGO') {
							catalogo()
						}
					}}
				>
					{bottomp1}
				</p>
				<p
					style={{cursor: 'pointer'}}
					onClick={(e) => {
						if (bottomp2 === 'OFERTA') {
							Offerta()
						}
						if (bottomp2 === 'CATÁLOGO') {
							catalogo()
						}
					}}
				>
					{bottomp2}
				</p>

				<div
					className='img_wrapper'
					style={{cursor: 'pointer'}}
					onClick={(e) => {
						if (jumbo) {
							jumbo()
						}
						if (tutto) {
							tutto()
						}
					}}
				>
					<img src={img} alt='' />
				</div>
			</div>
		</div>
	)
}
