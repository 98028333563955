import React, {useState} from 'react'
import {Navbar} from './Navbar'
import logotwo from '..//assets/img/logo_secondary.svg'
import icon1 from '..//assets/img/Icono (1).svg'
import img1 from '../assets/img/Mayorista.png'
import img2 from '../assets/img/Unimarc.png'
import Jumbo from '../assets/img/Jumbo.svg'
import Líder from '../assets/img/Lider.png'
import Santa from '../assets/img/Santa Isabel.svg'
import Tottus from '../assets/img/Tottus.svg'
import hostorial from '../assets/img/hostorial.svg'
import '../assets/css/home.css'
import {Popup} from './Popup'
import {Oferta} from './Oferta'
import {TottusPop} from './Tottus'
import repores from '../assets/img/repores.svg'
import dropdownStyle from '../assets/img/dropdown_style.svg'
import {FilterArea} from './FilterArea'
import {JumboPopNew} from './JumboPopNew'
import {CodigoPop} from './CodigoPop'
import {CodigoPopNew} from './CodigoPopNew'
import {OfertaNew} from './OfertaNew'
import {PromotionalVariableNew} from './PromotionalVariableNew'
import {ActiviyBox} from '../componentsRef'
import {CambiarFechasPopup} from '../componentsRef/CambiarFechasPopup'
import {Catalogo} from '../componentsRef/Catalogo'

export const Home = () => {
	const [active, setActive] = useState(true)
	const [Ofertaactive, setOfertaactive] = useState(false)
	const [Catalogoactive, setCatalogoActive] = useState(false)
	const [OfertaNewactive, setOfertaNewactive] = useState(false)
	const [Jumboactive, setJumboactive] = useState(false)
	const [Activityactive, setActivityactive] = useState(false)
	const [Tuttosactive, setTuttosactive] = useState(false)
	const [dropdownSep, setdropdownSep] = useState(false)
	const [CodigoPopState, setCodigoPopState] = useState(false)
	const [CodigoNewPopState, setCodigoNewPopState] = useState(false)
	const [firstcollection, setfirstCollection] = useState([])
	const [secondcollection, setsecondcollection] = useState([])
	const [thirdcollection, setthirdcollection] = useState([])
	const [fourthcollection, setfourthcollection] = useState([])

	return (
		<div className='Home'>
			{active && (
				<Popup setActive={setActive} setOfertaNewactive={setOfertaNewactive} />
			)}
			{Ofertaactive && <Oferta setActive={setOfertaactive} />}
			{Catalogoactive && <Catalogo setActive={setCatalogoActive} />}
			{OfertaNewactive && <OfertaNew setActive={setOfertaNewactive} />}
			{/* <PromotionalVariableNew /> */}

			{Jumboactive && <JumboPopNew setActive={setJumboactive} />}
			{Activityactive && (
				<JumboPopNew
					setActive={setActivityactive}
					Activityactive={Activityactive}
				/>
			)}
			{Tuttosactive && <TottusPop setActive={setTuttosactive} />}
			{CodigoPopState && <CodigoPop setCodigoPop={setCodigoPopState} />}
			{CodigoNewPopState && (
				<CodigoPopNew setCodigoPop={setCodigoNewPopState} />
			)}

			<Navbar />
			<div className='top_area top_area_top'>
				<div className='single_box'>
					<img src={logotwo} alt='' />
					<button onClick={(e) => setActive(true)}>Ingresar Promoción</button>
				</div>
				<div className='green_box'>
					<img src={icon1} alt='' />
					<p>Optimización</p>
					<button>Ver</button>
				</div>

				<div className='green_box'>
					<img src={repores} alt='' />
					<p>Reportes</p>
					<button>Ver</button>
				</div>

				<div className='green_box'>
					<img src={hostorial} alt='' />
					<p>Historial de Promociones</p>
					<button>Ver</button>
				</div>
			</div>

			<div className='box_big'>
				<h1>Promociones Activas</h1>
				<div className='boxes_wrapper'>
					<div className='box_long'>
						<h1>Propuesta Inicial</h1>
						<div className='content_area' style={{position: 'relative'}}>
							<FilterArea
								point={'one'}
								collection={firstcollection}
								setCollection={setfirstCollection}
							/>

							<ActiviyBox
								topText='EN EDICIÓN'
								mainp='Jumbo Abril 2022'
								bottomp1='CATÁLOGO'
								bottomp2='MODERNO'
								catalogo={(e) => setCatalogoActive(true)}
								jumbo={(e) => setJumboactive(true)}
								img={Jumbo}
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								bgColor='#FF5334'
								date='Jun.2022'
								percentage='10%'
							/>

							<ActiviyBox
								topText='EN EDICIÓN'
								mainp='Unimarc Cecinas'
								bottomp1='OFERTA'
								Offerta={(e) => setOfertaactive(true)}
								bottomp2='MODERNO'
								img={img2}
								bgColor='#37A7F5'
								percentage='50%'
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								date='Sep.2022'
							/>

							<ActiviyBox
								topText='DESCARTADA'
								mainp='Líder 12-21'
								bottomp1='CATÁLOGO'
								bottomp2='MODERNO'
								catalogo={(e) => setCatalogoActive(true)}
								img={Líder}
								black
								bgColor='#37A7F5'
								percentage='80%'
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								date='Aug.2022'
							/>

							<ActiviyBox
								topText='EN EDICIÓN'
								mainp='Prueba'
								bottomp1='CATÁLOGO'
								catalogo={(e) => setCatalogoActive(true)}
								bottomp2='MODERNO'
								img={img1}
								bgColor='#FFAC24'
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								percentage='20%'
								date='Jul.2022'
							/>
						</div>
					</div>

					<div className='box_long'>
						<h1>Propuesta Colaborativa</h1>
						<div className='content_area' style={{position: 'relative'}}>
							<FilterArea
								point={'two'}
								collection={secondcollection}
								setCollection={setsecondcollection}
							/>

							<div
								className={`dropdown_cover ${dropdownSep && 'active_dropdown'}`}
								onClick={(e) => {
									setdropdownSep(!dropdownSep)
								}}
							>
								<button className='dropdown_style'>
									Pendientes de Aprobación
									<img src={dropdownStyle} alt='' />
								</button>
								{dropdownSep && (
									<>
										<ActiviyBox
											topText='EN EDICIÓN'
											mainp='Fiestar patrias Unimarc'
											bottomp1='CATÁLOGO'
											catalogo={(e) => setCatalogoActive(true)}
											bottomp2='MODERNO'
											img={img2}
											bgColor='#37A7F5'
											Activityactive={Activityactive}
											setActivityactive={setActivityactive}
											date='Jul.2022'
											percentage='80%'
										/>

										<ActiviyBox
											topText='EN EDICIÓN'
											mainp='Fiestar patrias Unimarc'
											bottomp1='CATÁLOGO'
											catalogo={(e) => setCatalogoActive(true)}
											bottomp2='MODERNO'
											Activityactive={Activityactive}
											setActivityactive={setActivityactive}
											img={img2}
											bgColor='#37A7F5'
											date='Jul.2022'
											percentage='80%'
										/>
									</>
								)}
							</div>

							<ActiviyBox
								topText='EN EDICIÓN'
								mainp='Fiestar patrias Unimarc'
								bottomp1='CATÁLOGO'
								catalogo={(e) => setCatalogoActive(true)}
								bottomp2='MODERNO'
								img={img2}
								bgColor='#37A7F5'
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								date='Jul.2022'
								percentage='80%'
							/>

							<ActiviyBox
								topText='EN EDICIÓN'
								mainp='18Sept Lider'
								bottomp1='OFERTA'
								Offerta={(e) => setOfertaactive(true)}
								bottomp2='MODERNO'
								img={Líder}
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								bgColor='#37A7F5'
								percentage='55%'
								date='Jun.2022'
							/>

							<ActiviyBox
								topText='DESCARTADA'
								mainp='Santa Isabel Sept'
								bottomp1='CATÁLOGO'
								catalogo={(e) => setCatalogoActive(true)}
								bottomp2='MODERNO'
								img={Santa}
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								bgColor='#FFAC24'
								date='Apr.2022'
								percentage='25%'
							/>

							<ActiviyBox
								topText='EN EDICIÓN'
								mainp='Prueba'
								bottomp1='CATÁLOGO'
								catalogo={(e) => setCatalogoActive(true)}
								bottomp2='MODERNO'
								img={Santa}
								black
								bgColor='#FF5334'
								percentage='5%'
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								date='Aug.2022'
							/>
						</div>
					</div>

					<div className='box_long'>
						<h1>Negociación</h1>
						<div className='content_area' style={{position: 'relative'}}>
							<FilterArea
								point={'three'}
								collection={thirdcollection}
								setCollection={setthirdcollection}
							/>

							<ActiviyBox
								topText='PREAPROBADA'
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								mainp='Salchichas Agosto 22'
								bottomp1='MODALIDAD'
								bottomp2='CANAL'
								img={Santa}
								orange
								bgColor='#FF5334'
								percentage='5%'
								date='Jan. 2022'
							/>

							<ActiviyBox
								topText='PREAPROBADA'
								mainp='Salchichas Sept 22'
								bottomp1='MODALIDAD'
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								bottomp2='CANAL'
								img={Santa}
								orange
								bgColor='#37A7F5'
								percentage='55%'
								date='Nov. 2022'
							/>

							<ActiviyBox
								topText='SIN ACUERDO'
								mainp='Black Friday Tottus'
								bottomp1='CATÁLOGO'
								catalogo={(e) => setCatalogoActive(true)}
								bottomp2='MODERNO'
								img={Tottus}
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								tutto={(e) => setTuttosactive(true)}
								bgColor='#37A7F5'
								percentage='95%'
								date='Oct.2022'
							/>

							<ActiviyBox
								topText='La Once Octubre'
								mainp='Prueba'
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								bottomp1='CATÁLOGO'
								catalogo={(e) => setCatalogoActive(true)}
								bottomp2='MODERNO'
								jumbo={(e) => setJumboactive(true)}
								img={Jumbo}
								black
								bgColor='#FF5334'
								percentage='3%'
								date='Sep.2022'
							/>
						</div>
					</div>

					<div className='box_long'>
						<h1>Negociación</h1>
						<div className='content_area' style={{position: 'relative'}}>
							<FilterArea
								point={'four'}
								collection={fourthcollection}
								setCollection={setfourthcollection}
							/>

							<ActiviyBox
								topText='CANCELADA CLIENTE'
								mainp='Grado 1 Febrero'
								bottomp1='Oferta'
								Offerta={(e) => setOfertaactive(true)}
								bottomp2='CANAL'
								img={Santa}
								orange
								bgColor='#FFAC24'
								percentage='25%'
								date='Feb. 2022'
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
							/>
							<ActiviyBox
								topText='CONFIRMADA'
								mainp='Perecibles Agosto'
								bottomp1='MODALIDAD'
								bottomp2='CANAL'
								img={Líder}
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								bgColor='#37A7F5'
								percentage='1%'
								date='Jun. 2022'
							/>
							<ActiviyBox
								topText='CONFIRMADA'
								mainp='Perecibles Agosto'
								bottomp1='MODALIDAD'
								bottomp2='CANAL'
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								img={Tottus}
								tutto={(e) => setTuttosactive(true)}
								black
								bgColor='#37A7F5'
								percentage='65%'
								date='Ago. 2022'
							/>
							<ActiviyBox
								topText='CANCELADA CLIENTE'
								mainp='Grado 1 Febrero'
								bottomp1='Oferta'
								Offerta={(e) => setOfertaactive(true)}
								bottomp2='CANAL'
								img={Santa}
								orange
								Activityactive={Activityactive}
								setActivityactive={setActivityactive}
								bgColor='#37A7F5'
								date='. 2022'
								percentage='95%'
							/>
						</div>
					</div>
				</div>
			</div>

			{/* <Footer /> */}
		</div>
	)
}
