export const Promotion = {
    "activaciones": [
      {
        "activacion": "Lista/Catalogo", 
        "id": 1
      }, 
      {
        "activacion": "T\u00e1ctico", 
        "id": 2
      }, 
      {
        "activacion": "Limpiazo", 
        "id": 3
      }, 
      {
        "activacion": "Mega", 
        "id": 4
      }, 
      {
        "activacion": "CAU", 
        "id": 5
      }, 
      {
        "activacion": "FDS", 
        "id": 6
      }, 
      {
        "activacion": "Quincenales", 
        "id": 7
      }, 
      {
        "activacion": "Especial limpieza", 
        "id": 8
      }, 
      {
        "activacion": "Ciclo", 
        "id": 9
      }, 
      {
        "activacion": "Apoteosico", 
        "id": 10
      }, 
      {
        "activacion": "Operaci\u00f3n 990", 
        "id": 11
      }, 
      {
        "activacion": "3x2", 
        "id": 12
      }
    ], 
    "data_promocion": {
      "baseline": "-", 
      "fase_str": "Revisi\u00f3n", 
      "fecha_actualizacion": "28/07/2023 | 00:00", 
      "fecha_ingreso": "15/06/2023", 
      "id": 600, 
      "mg_incremental": "-", 
      "nombre_cadena": "PROVIMARKET", 
      "nombre_promocion": "CATALOGO JUNIO", 
      "roi": "-", 
      "sku_count": 3, 
      "tipo_promocion": "SELL-IN", 
      "uplift": "-"
    }, 
    "data_rows": [
      {
        "activacion": "Lista/Catalogo", 
        "cod_producto": 18, 
        "descripcion_producto": null, 
        "descuento": 15.43, 
        "fecha_actualizacion": "", 
        "id_activacion": 1, 
        "id_exhibicion": 5, 
        "id_mecanica": 1, 
        "id_promotora": 2, 
        "id_publicacion": 5, 
        "inicio_sellin": "27/06/2023", 
        "inicio_sellout": "27/06/2023", 
        "inversion_adicional": 0.0, 
        "mecanica": "1xY", 
        "nombre_promo": "CATALOGO JUNIO", 
        "precio_lista": 1193, 
        "precio_promocion": 1880, 
        "promotora": "NO", 
        "publicacion": "No Publicado", 
        "termino_sellin": "18/07/2023", 
        "termino_sellout": "18/07/2023"
      }, 
      {
        "activacion": "Lista/Catalogo", 
        "cod_producto": 14, 
        "descripcion_producto": null, 
        "descuento": 15.43, 
        "fecha_actualizacion": "", 
        "id_activacion": 1, 
        "id_exhibicion": 5, 
        "id_mecanica": 1, 
        "id_promotora": 2, 
        "id_publicacion": 5, 
        "inicio_sellin": "27/06/2023", 
        "inicio_sellout": "27/06/2023", 
        "inversion_adicional": 0.0, 
        "mecanica": "1xY", 
        "nombre_promo": "CATALOGO JUNIO", 
        "precio_lista": 1193, 
        "precio_promocion": 1880, 
        "promotora": "NO", 
        "publicacion": "No Publicado", 
        "termino_sellin": "18/07/2023", 
        "termino_sellout": "18/07/2023"
      }, 
      {
        "activacion": "Lista/Catalogo", 
        "cod_producto": 20, 
        "descripcion_producto": null, 
        "descuento": 15.43, 
        "fecha_actualizacion": "", 
        "id_activacion": 1, 
        "id_exhibicion": 5, 
        "id_mecanica": 1, 
        "id_promotora": 2, 
        "id_publicacion": 5, 
        "inicio_sellin": "27/06/2023", 
        "inicio_sellout": "27/06/2023", 
        "inversion_adicional": 0.0, 
        "mecanica": "1xY", 
        "nombre_promo": "CATALOGO JUNIO", 
        "precio_lista": 1193, 
        "precio_promocion": 1880, 
        "promotora": "NO", 
        "publicacion": "No Publicado", 
        "termino_sellin": "18/07/2023", 
        "termino_sellout": "18/07/2023"
      }
    ], 
    "exhibiciones": [
      {
        "exhibicion": "Cabecera", 
        "id": 1
      }, 
      {
        "exhibicion": "Isla", 
        "id": 2
      }, 
      {
        "exhibicion": "muro", 
        "id": 3
      }, 
      {
        "exhibicion": "mueble", 
        "id": 4
      }, 
      {
        "exhibicion": "NO", 
        "id": 5
      }
    ], 
    "mecanicas": [
      {
        "descripcion": "descuento directo", 
        "id": 1, 
        "mecanica": "1xY"
      }, 
      {
        "descripcion": "descuento al comprar 2 unidades", 
        "id": 2, 
        "mecanica": "2xY"
      }, 
      {
        "descripcion": "descuento al comprar 3 unidades", 
        "id": 3, 
        "mecanica": "3xY"
      }
    ], 
    "movimientos": {
      "estado_promocional_previo": 1, 
      "estado_str": "En Revisi\u00f3n", 
      "fase_str": "Revisi\u00f3n", 
      "id": 5, 
      "movimientos_posibles": [
        {
          "estado_fase": "Descartado", 
          "fase_promocion": "Revisin", 
          "id": 6
        }, 
        {
          "estado_fase": "Solicita Cambios", 
          "fase_promocion": "Revisi\u00f3n", 
          "id": 7
        }, 
        {
          "estado_fase": "Aprobado", 
          "fase_promocion": "Revisi\u00f3n", 
          "id": 8
        }
      ]
    }, 
    "promotora": [
      {
        "id": 1, 
        "promotora": "SI"
      }, 
      {
        "id": 2, 
        "promotora": "NO"
      }
    ], 
    "publicaciones": [
      {
        "id": 3, 
        "publicacion": "digital"
      }, 
      {
        "id": 5, 
        "publicacion": "No Publicado"
      }, 
      {
        "id": 2, 
        "publicacion": "Radio"
      }, 
      {
        "id": 4, 
        "publicacion": "sampling"
      }, 
      {
        "id": 1, 
        "publicacion": "TV"
      }
    ]
  }
  