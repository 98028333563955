import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {NewInputModule} from './NewInputModule'

import Banner1Login from '../assets/img/Banner1Login.png'
import Banner2Login from '../assets/img/Banner2Login.png'
import fullBlackGreen from '../assets/img/Full_Negro+Verde.svg'
import '../assets/css/LoginNew.css'

export const SetPasswordNew = () => {
	const [activeScreen, setActiveScreen] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		setTimeout(() => {
			setActiveScreen(true)
		}, 200)
	}, [])
	return (
		<div
			className={`LoginNew SetPasswordNew ForgetPasswordNew ${
				activeScreen && 'active'
			}`}
		>
			<img src={Banner1Login} alt='' className='Banner1Login' />
			<img src={Banner2Login} alt='' className='Banner2Login' />
			<img src={fullBlackGreen} alt='' className='Full_NegroVerde' />
			<form action='#'>
				<div className='top_box'>
					<svg
						width='52'
						height='52'
						viewBox='0 0 52 52'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect width='52' height='52' rx='26' fill='#F0F4FC' />
						<mask
							id='mask0_756_241764'
							maskUnits='userSpaceOnUse'
							x='6'
							y='6'
							width='40'
							height='40'
						>
							<rect x='6' y='6' width='40' height='40' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_756_241764)'>
							<path
								d='M16.125 42.9584C15.0695 42.9584 14.1739 42.5906 13.4384 41.8551C12.7017 41.1184 12.3334 40.2223 12.3334 39.1667V22.7501C12.3334 21.7223 12.7017 20.8334 13.4384 20.0834C14.1739 19.3334 15.0695 18.9584 16.125 18.9584H17.5V15.9167C17.5 13.5556 18.3262 11.5484 19.9784 9.89508C21.6317 8.24286 23.6389 7.41675 26 7.41675C28.3612 7.41675 30.3684 8.24286 32.0217 9.89508C33.6739 11.5484 34.5 13.5556 34.5 15.9167V18.9584H35.875C36.9306 18.9584 37.8267 19.3334 38.5634 20.0834C39.2989 20.8334 39.6667 21.7223 39.6667 22.7501V39.1667C39.6667 40.2223 39.2989 41.1184 38.5634 41.8551C37.8267 42.5906 36.9306 42.9584 35.875 42.9584H16.125ZM16.125 39.1667H35.875V22.7501H16.125V39.1667ZM26 34.2917C26.9167 34.2917 27.7017 33.9651 28.355 33.3117C29.0073 32.6595 29.3334 31.8751 29.3334 30.9584C29.3334 30.0417 29.0073 29.2567 28.355 28.6034C27.7017 27.9512 26.9167 27.6251 26 27.6251C25.0834 27.6251 24.2989 27.9512 23.6467 28.6034C22.9934 29.2567 22.6667 30.0417 22.6667 30.9584C22.6667 31.8751 22.9934 32.6595 23.6467 33.3117C24.2989 33.9651 25.0834 34.2917 26 34.2917ZM21.2917 18.9584H30.7084V15.9167C30.7084 14.6112 30.25 13.5001 29.3334 12.5834C28.4167 11.6667 27.3056 11.2084 26 11.2084C24.6945 11.2084 23.5834 11.6667 22.6667 12.5834C21.75 13.5001 21.2917 14.6112 21.2917 15.9167V18.9584Z'
								fill='#3676F5'
							/>
						</g>
					</svg>
				</div>
				<div className='body_area'>
					<p>Reestablece tu Contraseña</p>

					<div className='input_wrapper'>
						<label htmlFor='contraseña'>Ingresa tu nueva contraseña</label>
						<NewInputModule
							type='password'
							id='contraseña'
							placeholder='**********'
						/>

						<svg
							width='16'
							height='17'
							viewBox='0 0 16 17'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_459_129523'
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='16'
								height='17'
							>
								<rect y='0.5' width='16' height='16' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_459_129523)'>
								<path
									d='M4.5332 14.3C4.25543 14.3 4.01943 14.2027 3.8252 14.008C3.63054 13.8138 3.5332 13.5778 3.5332 13.3V7.69999C3.5332 7.42222 3.63054 7.18599 3.8252 6.99133C4.01943 6.79711 4.25543 6.69999 4.5332 6.69999H5.5332V5.36666C5.5332 4.67777 5.77209 4.09444 6.24987 3.61666C6.72765 3.13888 7.31098 2.89999 7.99987 2.89999C8.68876 2.89999 9.27209 3.13888 9.74987 3.61666C10.2276 4.09444 10.4665 4.67777 10.4665 5.36666V6.69999H11.4665C11.7443 6.69999 11.9803 6.79711 12.1745 6.99133C12.3692 7.18599 12.4665 7.42222 12.4665 7.69999V13.3C12.4665 13.5778 12.3692 13.8138 12.1745 14.008C11.9803 14.2027 11.7443 14.3 11.4665 14.3H4.5332ZM4.5332 13.8333H11.4665C11.6221 13.8333 11.7499 13.7833 11.8499 13.6833C11.9499 13.5833 11.9999 13.4556 11.9999 13.3V7.69999C11.9999 7.54444 11.9499 7.41666 11.8499 7.31666C11.7499 7.21666 11.6221 7.16666 11.4665 7.16666H4.5332C4.37765 7.16666 4.24987 7.21666 4.14987 7.31666C4.04987 7.41666 3.99987 7.54444 3.99987 7.69999V13.3C3.99987 13.4556 4.04987 13.5833 4.14987 13.6833C4.24987 13.7833 4.37765 13.8333 4.5332 13.8333ZM7.99987 11.4C8.25542 11.4 8.46943 11.314 8.64187 11.142C8.81387 10.9696 8.89987 10.7555 8.89987 10.5C8.89987 10.2444 8.81387 10.0304 8.64187 9.85799C8.46943 9.68599 8.25542 9.59999 7.99987 9.59999C7.74431 9.59999 7.53054 9.68599 7.35854 9.85799C7.18609 10.0304 7.09987 10.2444 7.09987 10.5C7.09987 10.7555 7.18609 10.9696 7.35854 11.142C7.53054 11.314 7.74431 11.4 7.99987 11.4ZM5.99987 6.69999H9.99987V5.36666C9.99987 4.81111 9.80542 4.33888 9.41654 3.94999C9.02765 3.56111 8.55542 3.36666 7.99987 3.36666C7.44431 3.36666 6.97209 3.56111 6.5832 3.94999C6.19431 4.33888 5.99987 4.81111 5.99987 5.36666V6.69999Z'
									fill='#3676F5'
								/>
							</g>
						</svg>
					</div>

					<div className='input_wrapper' style={{marginTop: 16}}>
						<label htmlFor='contraseñauna'>
							Ingresa tu contraseña una vez más
						</label>
						<NewInputModule
							type='password'
							id='contraseñauna'
							placeholder='**********'
						/>

						<svg
							width='16'
							height='17'
							viewBox='0 0 16 17'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_459_129523'
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='16'
								height='17'
							>
								<rect y='0.5' width='16' height='16' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_459_129523)'>
								<path
									d='M4.5332 14.3C4.25543 14.3 4.01943 14.2027 3.8252 14.008C3.63054 13.8138 3.5332 13.5778 3.5332 13.3V7.69999C3.5332 7.42222 3.63054 7.18599 3.8252 6.99133C4.01943 6.79711 4.25543 6.69999 4.5332 6.69999H5.5332V5.36666C5.5332 4.67777 5.77209 4.09444 6.24987 3.61666C6.72765 3.13888 7.31098 2.89999 7.99987 2.89999C8.68876 2.89999 9.27209 3.13888 9.74987 3.61666C10.2276 4.09444 10.4665 4.67777 10.4665 5.36666V6.69999H11.4665C11.7443 6.69999 11.9803 6.79711 12.1745 6.99133C12.3692 7.18599 12.4665 7.42222 12.4665 7.69999V13.3C12.4665 13.5778 12.3692 13.8138 12.1745 14.008C11.9803 14.2027 11.7443 14.3 11.4665 14.3H4.5332ZM4.5332 13.8333H11.4665C11.6221 13.8333 11.7499 13.7833 11.8499 13.6833C11.9499 13.5833 11.9999 13.4556 11.9999 13.3V7.69999C11.9999 7.54444 11.9499 7.41666 11.8499 7.31666C11.7499 7.21666 11.6221 7.16666 11.4665 7.16666H4.5332C4.37765 7.16666 4.24987 7.21666 4.14987 7.31666C4.04987 7.41666 3.99987 7.54444 3.99987 7.69999V13.3C3.99987 13.4556 4.04987 13.5833 4.14987 13.6833C4.24987 13.7833 4.37765 13.8333 4.5332 13.8333ZM7.99987 11.4C8.25542 11.4 8.46943 11.314 8.64187 11.142C8.81387 10.9696 8.89987 10.7555 8.89987 10.5C8.89987 10.2444 8.81387 10.0304 8.64187 9.85799C8.46943 9.68599 8.25542 9.59999 7.99987 9.59999C7.74431 9.59999 7.53054 9.68599 7.35854 9.85799C7.18609 10.0304 7.09987 10.2444 7.09987 10.5C7.09987 10.7555 7.18609 10.9696 7.35854 11.142C7.53054 11.314 7.74431 11.4 7.99987 11.4ZM5.99987 6.69999H9.99987V5.36666C9.99987 4.81111 9.80542 4.33888 9.41654 3.94999C9.02765 3.56111 8.55542 3.36666 7.99987 3.36666C7.44431 3.36666 6.97209 3.56111 6.5832 3.94999C6.19431 4.33888 5.99987 4.81111 5.99987 5.36666V6.69999Z'
									fill='#3676F5'
								/>
							</g>
						</svg>
					</div>

					<div className='button_wrapper'>
						<button
							onClick={(e) => {
								navigate('/PasswordChangeDone')
							}}
						>
							Reestablecer Contraseña
							<svg
								width='13'
								height='12'
								viewBox='0 0 13 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g clipPath='url(#clip0_816_63088)'>
									<mask
										id='mask0_816_63088'
										maskUnits='userSpaceOnUse'
										x='0'
										y='0'
										width='13'
										height='12'
									>
										<rect x='0.5' width='12' height='12' fill='#D9D9D9' />
									</mask>
									<g mask='url(#mask0_816_63088)'>
										<path
											d='M4.02488 10.6C3.89154 10.4583 3.82488 10.2937 3.82488 10.106C3.82488 9.91867 3.89154 9.75417 4.02488 9.6125L7.63738 6L4.01238 2.375C3.87904 2.24167 3.81238 2.07917 3.81238 1.8875C3.81238 1.69583 3.88321 1.52917 4.02488 1.3875C4.15821 1.25417 4.32071 1.1875 4.51238 1.1875C4.70404 1.1875 4.87071 1.25417 5.01238 1.3875L9.21238 5.6C9.27071 5.65833 9.31238 5.72083 9.33738 5.7875C9.36238 5.85417 9.37488 5.925 9.37488 6C9.37488 6.075 9.36238 6.14583 9.33738 6.2125C9.31238 6.27917 9.27071 6.34167 9.21238 6.4L4.99988 10.6125C4.86654 10.7458 4.70604 10.8125 4.51838 10.8125C4.33104 10.8125 4.16654 10.7417 4.02488 10.6Z'
											fill='white'
										/>
									</g>
								</g>
								<defs>
									<clipPath id='clip0_816_63088'>
										<rect
											width='12'
											height='12'
											fill='white'
											transform='translate(0.5)'
										/>
									</clipPath>
								</defs>
							</svg>
						</button>
						<Link to='/EmailCheck'>Cancelar</Link>
					</div>
				</div>
			</form>
		</div>
	)
}
