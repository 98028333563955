import {Navbar} from './Navbar'
import '../assets/css/CataLogoResumen.css'
import {CataLogoResumenFooter} from './CataLogoResumenFooter'
import Table from 'react-bootstrap/Table'
import {Link} from 'react-router-dom'
import {TopNavigator} from './TopNavigator'

import {CecinasResumenTable} from './CecinasResumenTable'
export const CecinasResumen = () => {
	return (
		<div className='CataLogoResumen'>
			<TopNavigator />
			<Navbar no_need_search />
			<div className='top_area top_area_differ'>
				{/* <div className="left_side"></div> */}
				<div className='option_table'>
					<Table>
						<tbody>
							<tr>
								<td>
									<Link to='/Seguimiento'>Seguimiento Catálogo</Link>
								</td>
								<td>
									<Link to='/CataLogoResumen' className='active'>
										Resumen Línea
									</Link>
								</td>
								<td>
									<Link to='/Vienesas'>Vienesas</Link>
								</td>
								<td>
									<Link to='/'>Jamones</Link>
								</td>
								<td>
									<Link to='/'>Mortadelas</Link>
								</td>
								<td>
									<Link to='/'>Longanizas</Link>
								</td>
								<td>
									<Link to='/'>Pates</Link>
								</td>
								<td>
									<Link to='/'>Madurados</Link>
								</td>
								<td>
									<Link to='/'>Arrollados</Link>
								</td>
								<td>
									<Link to='/SeguimientoLinea'>Seguimiento Línea</Link>
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>
			<div className='link_area'>
				<p>Jumbo Abril 2022</p>
				<p>/</p>
				<p>Resumen Línea</p>
			</div>

			<div className='option_table' style={{marginTop: 0}}>
				<Table>
					<tbody>
						<tr>
							<td>
								<Link to='/CataLogoResumen'>Todas</Link>
							</td>
							<td className='active'>
								<Link to='/Cecinas'>Cecinas</Link>
							</td>
							<td>
								<Link to='/'>Lácteos</Link>
							</td>
							<td>
								<Link to='/'>Platos Prep.</Link>
							</td>
							<td>
								<Link to='/'>Elaborados</Link>
							</td>
							<td>
								<Link to='/'>Pizzas</Link>
							</td>
							<td>
								<Link to='/'>Proyectos</Link>
							</td>
							<td>
								<Link to='/'>Vegetales</Link>
							</td>
						</tr>
					</tbody>
				</Table>
			</div>

			{/* <CataLogoResumenTable /> */}
			<CecinasResumenTable />
			<CataLogoResumenFooter />
		</div>
	)
}
