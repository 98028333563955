const AlertWarningSvg = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={18}
		height={15}
		fill='none'
		{...props}
	>
		<path
			fill='url(#a)'
			d='M1.55 14.7a.716.716 0 0 1-.65-.375.681.681 0 0 1 0-.75L8.35.725A.716.716 0 0 1 9 .35c.283 0 .5.125.65.375l7.45 12.85c.15.25.15.5 0 .75a.716.716 0 0 1-.65.375H1.55ZM9 5.5a.34.34 0 0 0-.35.35v4.3a.34.34 0 0 0 .35.35.34.34 0 0 0 .35-.35v-4.3A.34.34 0 0 0 9 5.5Zm0 7a.47.47 0 0 0 .363-.15A.503.503 0 0 0 9.5 12a.493.493 0 0 0-.137-.363A.493.493 0 0 0 9 11.5a.502.502 0 0 0-.35.137.47.47 0 0 0-.15.363c0 .133.05.25.15.35.1.1.217.15.35.15ZM1.45 14h15.1L9 1 1.45 14Z'
		/>
		<defs>
			<linearGradient
				id='a'
				x1={1.453}
				x2={20.864}
				y1={0.35}
				y2={1.746}
				gradientUnits='userSpaceOnUse'
			>
				<stop />
				<stop offset={1} />
			</linearGradient>
		</defs>
	</svg>
)
export default AlertWarningSvg
