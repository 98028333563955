import React, {useState, useEffect} from 'react'
import errorExplanation from '../../assets/img/error_explanation.svg'
import 'react-calendar/dist/Calendar.css'
import Calendar from 'react-calendar'
export const CatálogoDropdownDate = ({
	valueState,
	activeState,
	setlistCombine = null,
	setbgCover = null,
}) => {
	const [dropdown, setDropdown] = useState(false)
	const [value, onChange] = useState(new Date())
	const [dropdownValue, setdropdownValue] = useState('')
	const [response, setresponse] = useState(null)
	const [count, setCount] = useState(0)

	useEffect(() => {
		if (count === 2) {
			if (dropdownValue === '') {
				setresponse('error')
			} else {
				setresponse('success')
			}
		}

		if (count < 2) {
			setCount(count + 1)
		}
	}, [dropdownValue, dropdown])

	useEffect(() => {
		if (activeState !== valueState && dropdown === true) {
			setDropdown(false)
		}
	}, [activeState])

	return (
		<div className='CatálogoDropdown CatálogoDropdownDate CatálogoDropdownDateNew'>
			<Calendar
				onChange={(e) => {
					onChange(e)

					setDropdown(false)

					const FirstStart = String(e[0])
					const SecondStart = String(e[1])

					const FirstcorrectDay = `${FirstStart.split(' ')[2]}/${
						FirstStart.split(' ')[1]
					}/${FirstStart.split(' ')[3]}`

					const SecondcorrectDay = `${SecondStart.split(' ')[2]}/${
						SecondStart.split(' ')[1]
					}/${SecondStart.split(' ')[3]}`

					setdropdownValue(`${FirstcorrectDay} al ${SecondcorrectDay}`)
					if (setlistCombine) {
						setlistCombine(`${FirstcorrectDay} al ${SecondcorrectDay}`)
					}
				}}
				value={value}
				selectRange={true}
				formatShortWeekday={(locale, value) =>
					['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'][value.getDay()]
				}
			/>
		</div>
	)
}
