import './App.css'
import {AppRoutes} from './routes'
import {AuthProvider} from './context/'
import {CatalogsProvider} from './context/catalogs/CatalogsProvider'
import './global.scss'
import {ChainsProvider} from './context/chains/ChainProvider'

function App() {
	return (
		<div className='App'>
			<AuthProvider>
				<CatalogsProvider>
					<ChainsProvider>
						<AppRoutes />
					</ChainsProvider>
				</CatalogsProvider>
			</AuthProvider>
		</div>
	)
}

export default App
