export const NewInputModule = ({type, id, placeholder}) => {
	return (
		<input
			type={type}
			id={id}
			placeholder={placeholder}
			onFocus={(e) => {
				e.target.style.borderColor = '#3676F5'
			}}
			onBlur={(e) => {
				if (type === 'email') {
					if (
						// eslint-disable-next-line no-useless-escape
						/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)
					) {
						e.target.style.borderColor = '#3676F5'
					} else {
						e.target.style.borderColor = '#ee0000'
					}
				} else {
					if (e.target.value.length > 7) {
						e.target.style.borderColor = '#3676F5'
					} else {
						e.target.style.borderColor = '#ee0000'
					}
				}
			}}
		/>
	)
}
// 3676F5
