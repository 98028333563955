import {useReducer} from 'react'
import {ChainsContext} from './ChainsContex'
import {chainsReducer} from './chainsReducer'
import {types} from './types'
import {getAllChains} from '../../services/catalog.service'

const initialState = {
	chains: [],
	loading: false,
	errorMessage: null,
}

export const ChainsProvider = ({children}) => {
	const [chainState, dispatch] = useReducer(chainsReducer, initialState)

	const allChains = async () => {
		try {
			dispatch({
				type: types.request,
			})
			const response = await getAllChains()
			if (response.statusText === 'OK') {
				console.log(response.data)
				dispatch({
					type: types.chains,
					payload: response.data,
				})
			} else {
				dispatch({
					type: types.error,
					payload: response.data.response,
				})
			}
		} catch (error) {
			dispatch({
				type: types.error,
				payload: error?.toString(),
			})
		}
	}

	return (
		<ChainsContext.Provider value={{chainState, allChains}}>
			{children}
		</ChainsContext.Provider>
	)
}
