import React, {useEffect, useState} from 'react'
import Banner1Login from '../assets/img/Banner1Login.png'
import Banner2Login from '../assets/img/Banner2Login.png'
import blackGreenFull from '../assets/img/Full_Negro+Verde.svg'
import '../assets/css/LoginNew.css'
import {Link, useNavigate} from 'react-router-dom'
export const PasswordChangeDone = () => {
	const [activeScreen, setActiveScreen] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		setTimeout(() => {
			setActiveScreen(true)
		}, 200)
	}, [])
	return (
		<div
			className={`LoginNew  ForgetPasswordNew PasswordChangeDone ${
				activeScreen && 'active'
			}`}
		>
			<img src={Banner1Login} alt='' className='Banner1Login' />
			<img src={Banner2Login} alt='' className='Banner2Login' />
			<img src={blackGreenFull} alt='' className='Full_NegroVerde' />
			<form action='#' style={{position: 'relative'}}>
				<svg
					className='close-icon'
					width='16'
					height='16'
					viewBox='0 0 16 16'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<g clipPath='url(#clip0_818_169207)'>
						<mask
							id='mask0_818_169207'
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='16'
							height='16'
						>
							<rect width='16' height='16' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_818_169207)'>
							<path
								d='M7.99994 9.0666L4.79993 12.2666C4.65549 12.411 4.47771 12.4833 4.2666 12.4833C4.05549 12.4833 3.87771 12.411 3.73327 12.2666C3.58882 12.1222 3.5166 11.9444 3.5166 11.7333C3.5166 11.5222 3.58882 11.3444 3.73327 11.1999L6.93327 7.99994L3.73327 4.79993C3.58882 4.65549 3.5166 4.47771 3.5166 4.2666C3.5166 4.05549 3.58882 3.87771 3.73327 3.73327C3.87771 3.58882 4.05549 3.5166 4.2666 3.5166C4.47771 3.5166 4.65549 3.58882 4.79993 3.73327L7.99994 6.93327L11.1999 3.73327C11.3444 3.58882 11.5222 3.5166 11.7333 3.5166C11.9444 3.5166 12.1222 3.58882 12.2666 3.73327C12.411 3.87771 12.4833 4.05549 12.4833 4.2666C12.4833 4.47771 12.411 4.65549 12.2666 4.79993L9.0666 7.99994L12.2666 11.1999C12.411 11.3444 12.4833 11.5222 12.4833 11.7333C12.4833 11.9444 12.411 12.1222 12.2666 12.2666C12.1222 12.411 11.9444 12.4833 11.7333 12.4833C11.5222 12.4833 11.3444 12.411 11.1999 12.2666L7.99994 9.0666Z'
								fill='#ADADAD'
							/>
						</g>
					</g>
					<defs>
						<clipPath id='clip0_818_169207'>
							<rect width='16' height='16' fill='white' />
						</clipPath>
					</defs>
				</svg>

				<div className='top_box'>
					<svg
						width='40'
						height='40'
						viewBox='0 0 40 40'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect width='40' height='40' rx='20' fill='#F0F4FC' />
						<g clipPath='url(#clip0_818_169200)'>
							<mask
								id='mask0_818_169200'
								maskUnits='userSpaceOnUse'
								x='4'
								y='4'
								width='32'
								height='32'
							>
								<rect x='4' y='4' width='32' height='32' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_818_169200)'>
								<path
									d='M16.7342 27.633C16.5342 27.633 16.3454 27.5997 16.1676 27.533C15.9898 27.4663 15.8231 27.3552 15.6676 27.1997L9.93424 21.4663C9.64536 21.1775 9.50669 20.8161 9.51824 20.3823C9.52891 19.9495 9.67869 19.5886 9.96758 19.2997C10.2565 19.0108 10.6178 18.8663 11.0516 18.8663C11.4845 18.8663 11.8342 19.0108 12.1009 19.2997L16.7342 23.933L27.9009 12.7663C28.1676 12.4775 28.5231 12.333 28.9676 12.333C29.412 12.333 29.7787 12.4775 30.0676 12.7663C30.3342 13.0552 30.4676 13.4161 30.4676 13.849C30.4676 14.2828 30.3342 14.6441 30.0676 14.933L17.8009 27.1997C17.6454 27.3552 17.4787 27.4663 17.3009 27.533C17.1231 27.5997 16.9342 27.633 16.7342 27.633Z'
									fill='#3676F5'
								/>
							</g>
						</g>
						<defs>
							<clipPath id='clip0_818_169200'>
								<rect x='4' y='4' width='32' height='32' rx='16' fill='white' />
							</clipPath>
						</defs>
					</svg>
				</div>
				<div className='body_area'>
					<p>Tu contraseña ha sido cambiada con éxito</p>
					<p>Ya puedes volver a ingresar con tu nueva contraseña</p>

					<div className='button_wrapper'>
						<button
							onClick={(e) => {
								navigate('/LoaderNew')
							}}
						>
							Volver a Ingresar
						</button>
					</div>
				</div>
			</form>
		</div>
	)
}
