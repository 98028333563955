import React from 'react'

import {ProductSearch} from './ProductSearch'

export const SearchResult = ({SearchingProducts}) => {
	return (
		<div className='SearchResult'>
			<h1>Busqueda de Promociones</h1>
			<div className='recent_area'>
				<h1>Búsquedas Recientes</h1>
				<div className='horizontal_sc'>
					<p className='badge'>TEXTO EJEMPLO</p>
				</div>
			</div>

			<div className='information_area'>
				{SearchingProducts.map((EachProduct) => (
					<ProductSearch
						key={EachProduct.topText}
						topText={EachProduct.topText}
						mainp={EachProduct.mainp}
						bottomp1={EachProduct.bottomp1}
						bottomp2={EachProduct.bottomp2}
						img={EachProduct.Img}
					/>
				))}
			</div>
		</div>
	)
}
