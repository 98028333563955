import React, {useState} from 'react'
import file from '../../assets/img/file.svg'

import loaderImg from '../../assets/img/loader_img.png'
export const GuadarProductSidebar = ({setAccionGuardar, setafterState,setfrontBar,setSvg
, textoValidacion, actionNormalMenu, simulacionPage, setSimulacionPage}) => {
	const [steps, setSteps] = useState(1)
	const clicEditar = () => {
			// Obtén una referencia al elemento por su ID
			const targetElement = document.getElementById('botonAgregar');

			// Crea un nuevo evento de clic
			const clickEvent = new Event('click', {
			bubbles: true, // Para que el evento se propague a través de la jerarquía de elementos
			cancelable: true, // Para que el evento sea cancelable
			});

			// Dispara el evento en el elemento objetivo
			targetElement.dispatchEvent(clickEvent);
	}
	return (
		<div className='AddProductSdebar GuadarProductSidebar'>
			<div className='topbar'>
				<h1>Guardar Catálogo</h1>
			</div>

			<div className='form-wrapper-area MoverProductSidebar'>
				{steps === 1 && (
					<div className='third fourth'>
						<img src={file} alt='' />
						<h1>
							¿Estás seguro que deseas guardar <br /> el Catálogo?
						</h1>

						<div className='buttons-wrapper'>
							<button
							onClick={(e) => {
								// clicEditar()
								actionNormalMenu()
								if(simulacionPage){
									setSimulacionPage(false)
								}
							}}
							>Seguir Editando</button>
							<button
								onClick={(e) => {
									
									setSteps(2)
									setTimeout(() => {
										setSteps(3)
										setAccionGuardar(true)
									}, 2000)
								}}
							>
								Guardar
							</button>
						</div>
					</div>
				)}

				{steps === 2 && (
					<div className='third'>
						<img src={file} alt='' />
						<h1>Guardando Catálogo</h1>
						<div className='LoaderNew customize'>
							<img src={loaderImg} alt='' />
							<svg
								width='25'
								height='30'
								viewBox='0 0 25 30'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M0 0V29.4H7.476V21.126H12.81C20.328 21.126 24.654 17.136 24.654 10.248C24.654 3.696 20.328 0 12.81 0H0ZM7.476 5.88H12.432C15.708 5.88 17.598 7.434 17.598 10.5C17.598 13.608 15.708 15.246 12.432 15.246H7.476V5.88Z'
									fill='#2E2E2E'
								/>
							</svg>
							<svg
								width='6'
								height='6'
								viewBox='0 0 6 6'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<circle
									cx='3'
									cy='3'
									r='3'
									fill='url(#paint0_linear_459_158955)'
								/>
								<defs>
									<linearGradient
										id='paint0_linear_459_158955'
										x1='0.243243'
										y1='1.16798e-06'
										x2='7.34243'
										y2='0.446138'
										gradientUnits='userSpaceOnUse'
									>
										<stop stopColor='#FFAC23' />
										<stop offset='1' stopColor='#FF5334' />
									</linearGradient>
								</defs>
							</svg>
							 </div> 
						{/* <button onClick={(e) => {clicEditar()}}>Seguir Editando</button> */}
				</div> 
				)}
				{steps === 3 && (
					<div className='third fourth'>
						<img src={file} alt='' />
						<h1>Catálogo Guardado</h1>
						<p className='warning'>
							El Catálogo ha sido guardado exitosamente. Puedes volver al inicio
							o seguir editandolo.
							{textoValidacion.length > 0 &&
								<><br></br><br></br> 
								<h3>Atención a los siguientes errores pendientes:</h3> </>
							}
							
							{textoValidacion.length > 0 && 
								
									textoValidacion.map((item) => {
											return (
												<>{item} <br></br></>
											)
									}
							)}
						</p>
						<div className='buttons-wrapper'>
							<button 
							onClick={(e) => {
								// clicEditar()
								actionNormalMenu()
								if(simulacionPage){
									setSimulacionPage(false)
								}
							}}
							>Seguir Editando</button>
							<button
							onClick={(e) => {
								window.location.pathname = '/HomeNew'
							}}
							>Ir al Inicio</button>
						</div>
					</div>
				)}
			</div>

			<div className='bottom-area'>
				<button className='button-cancel'
				onClick={(e) => {
					// clicEditar()
					actionNormalMenu()
				}}
				>Cerrar</button>
			</div>
		</div>
	)
}
