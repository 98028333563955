import React, {useState, useEffect} from 'react'
import searchImg from '../../assets/img/search-sidebar.svg'
import {CatalogoDropdown} from './CatalogoDropdown'
import {RowProductSidebar} from './RowProductSidebar'
export const AddProductSdebar = () => {
	const [Height, setHeight] = useState(0)
	const HandleResizeWork = () => {
		const WindowHeight = window.innerHeight - 530
		setHeight(WindowHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', HandleResizeWork)
		HandleResizeWork()
	}, [])
	return (
		<div className='AddProductSdebar'>
			<div className='topbar'>
				<h1>Agregar Productos</h1>
			</div>
			<form action='' className='form-wrapper-product-add'>
				<div className='input-wrapper'>
					<label htmlFor=''>Buscar por Código y/o Nombre</label>
					<input type='text' placeholder='123456' />
					<img src={searchImg} alt='' />
				</div>

				<div className='grid-2'>
					<div className='input-wrapper'>
						<label htmlFor=''>Línea</label>
						<CatalogoDropdown
							head='Línea'
							list={[
								'Cecinas',
								'Lácteos',
								'Platos Preparados',
								'Elaborados',
								'Pizzas',

								'Vegetales',
							]}
						/>
					</div>
					<div className='input-wrapper'>
						<label htmlFor=''>Familia</label>
						<CatalogoDropdown
							head='Familia'
							list={[
								'Vienesas',
								'Mortadelas',
								'Jamones',
								'Longanizas',
								'Pates',
								'Arrolados',
							]}
						/>
					</div>
				</div>

				<div className='table-area'>
					<h1>25 Productos</h1>

					<div className='RowProductSidebar RowProductSidebarHead'>
						<div className='first-row-sidebar-product'>
							<input
								type='checkbox'
								id='checkbox_first_head'
								className='body-checkboxes-sidebar-head'
								onChange={(e) => {
									const checkboxesBodySidebarRow = document.querySelectorAll(
										'.body-checkboxes-sidebar-row'
									)

									checkboxesBodySidebarRow.forEach((EachElement) => {
										const checkbox = EachElement.querySelector(
											'.body-checkboxes-sidebar'
										)
										if (checkbox) {
											if (e.target.checked === true) {
												EachElement.classList.add('activecheckboxRow')
												checkbox.checked = true
											} else {
												EachElement.classList.remove('activecheckboxRow')
												checkbox.checked = false
											}
										}
									})
								}}
							/>

							<label htmlFor={`checkbox_first_head`}>
								<svg
									width='11'
									height='8'
									viewBox='0 0 11 8'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M3.78918 8C3.68417 8 3.58499 7.98257 3.49165 7.94771C3.3983 7.91285 3.31079 7.85476 3.22912 7.77342L0.218775 4.7756C0.0670908 4.62455 -0.00571732 4.43561 0.00035004 4.2088C0.00595068 3.98245 0.0845929 3.79375 0.236277 3.6427C0.387961 3.49165 0.577682 3.41612 0.805442 3.41612C1.03273 3.41612 1.21639 3.49165 1.3564 3.6427L3.78918 6.06536L9.65235 0.226579C9.79236 0.0755264 9.97905 0 10.2124 0C10.4458 0 10.6383 0.0755264 10.79 0.226579C10.93 0.377632 11 0.566333 11 0.79268C11 1.01949 10.93 1.20842 10.79 1.35948L4.34924 7.77342C4.26757 7.85476 4.18006 7.91285 4.08671 7.94771C3.99337 7.98257 3.89419 8 3.78918 8Z'
										fill='white'
									/>
								</svg>
							</label>
						</div>
						<div className='second-row-sidebar-product'>
							<p>Código</p>
						</div>
						<div className='third-row-sidebar-product'>
							<p>Descripción</p>
						</div>
						<div className='fourth-row-sidebar-product'>
							<p>Línea</p>
						</div>
						<div className='fifth-row-sidebar-product'>
							<p>Familia</p>
						</div>
					</div>
					<div className='rows-area' style={{height: `${Height}px`}}>
						<RowProductSidebar id={1} />
						<RowProductSidebar checkboxAvail id={2} />
						<RowProductSidebar checkboxAvail id={3} />
						<RowProductSidebar checkboxAvail id={4} />
						<RowProductSidebar checkboxAvail id={5} />
						<RowProductSidebar id={6} />
						<RowProductSidebar id={7} />
						<RowProductSidebar checkboxAvail id={8} />
						<RowProductSidebar checkboxAvail id={9} />
						<RowProductSidebar id={10} />
						<RowProductSidebar checkboxAvail id={11} />
					</div>
				</div>
			</form>

			<div className='bottom-area'>
				<button>Agregar Productos</button>
			</div>
		</div>
	)
}
