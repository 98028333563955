import React from 'react'

export const SwitchCustom = ({id, defaultChecked = null}) => {
	return (
		<label htmlFor={id} className='SwitchCustom'>
			<input type='checkbox' defaultChecked={defaultChecked} id={id} />
			<div className='checkbox-row'>
				<span className='ball'></span>
			</div>
		</label>
	)
}
