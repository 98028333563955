import iconoCatalog from '../../assets/img/IconoMoved.svg'

export const CatalogMoved = ({setActive}) => {
	return (
		<div
			className='popupWrapper'
			id='popupCatalog'
			onClick={(e) => {
				if (e.target.id === 'popupCatalog') {
					setActive(false)
				}
			}}
		>
			<div className='popup SaveCatalog'>
				<img src={iconoCatalog} alt='' />
				<h1>El Catálogo se ha movido exitosamente</h1>
				<p>
					Puedes ver el resumen o volver al inicio de <span>PROMOBOOSTER</span>
				</p>
				<div className='buttons_wrapper'>
					<button>Volver al Inicio</button>
					<button>Seguir Editando</button>
				</div>
			</div>
		</div>
	)
}
