import React from 'react'

import logoMover from '../../assets/img/loader_img.png'
export const InicioPop2 = ({setInicioPopState}) => {
	return (
		<>
			<span
				className='black_bg popup'
				onClick={(e) => {
					setInicioPopState(null)
				}}
			></span>
			<div className='LogoutPopupNew InicioPop InicioPop3'>
				<div className='LoaderNew customize'>
					<img src={logoMover} alt='' />
					<svg
						width='25'
						height='30'
						viewBox='0 0 25 30'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M0 0V29.4H7.476V21.126H12.81C20.328 21.126 24.654 17.136 24.654 10.248C24.654 3.696 20.328 0 12.81 0H0ZM7.476 5.88H12.432C15.708 5.88 17.598 7.434 17.598 10.5C17.598 13.608 15.708 15.246 12.432 15.246H7.476V5.88Z'
							fill='#2E2E2E'
						/>
					</svg>
					<svg
						width='6'
						height='6'
						viewBox='0 0 6 6'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<circle cx='3' cy='3' r='3' fill='url(#paint0_linear_459_158955)' />
						<defs>
							<linearGradient
								id='paint0_linear_459_158955'
								x1='0.243243'
								y1='1.16798e-06'
								x2='7.34243'
								y2='0.446138'
								gradientUnits='userSpaceOnUse'
							>
								<stop stopColor='#FFAC23' />
								<stop offset='1' stopColor='#FF5334' />
							</linearGradient>
						</defs>
					</svg>
				</div>
				<h1>Guardando Catálogo</h1>

				<div className='button_wrapper'>
					<button
						style={{display: 'none'}}
						onClick={(e) => {
							setInicioPopState(3)
							window.location.pathname = '/HomeNew'
						}}
					>
						Ir al Inicio
					</button>
					<button
						onClick={(e) => setInicioPopState(false)}
						className='canceler'
					>
						Cancelar
					</button>
				</div>

				<svg
					onClick={(e) => setInicioPopState(false)}
					width='10'
					height='10'
					viewBox='0 0 10 10'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M4.99994 6.06666L1.79993 9.26666C1.65549 9.41111 1.47771 9.48333 1.2666 9.48333C1.05549 9.48333 0.877713 9.41111 0.733268 9.26666C0.588824 9.12222 0.516602 8.94444 0.516602 8.73333C0.516602 8.52222 0.588824 8.34444 0.733268 8.2L3.93327 5L0.733268 1.8C0.588824 1.65555 0.516602 1.47777 0.516602 1.26666C0.516602 1.05555 0.588824 0.877774 0.733268 0.733329C0.877713 0.588885 1.05549 0.516663 1.2666 0.516663C1.47771 0.516663 1.65549 0.588885 1.79993 0.733329L4.99994 3.93333L8.19993 0.733329C8.34438 0.588885 8.52216 0.516663 8.73327 0.516663C8.94438 0.516663 9.12216 0.588885 9.2666 0.733329C9.41105 0.877774 9.48327 1.05555 9.48327 1.26666C9.48327 1.47777 9.41105 1.65555 9.2666 1.8L6.0666 5L9.2666 8.2C9.41105 8.34444 9.48327 8.52222 9.48327 8.73333C9.48327 8.94444 9.41105 9.12222 9.2666 9.26666C9.12216 9.41111 8.94438 9.48333 8.73327 9.48333C8.52216 9.48333 8.34438 9.41111 8.19993 9.26666L4.99994 6.06666Z'
						fill='#ADADAD'
					/>
				</svg>
			</div>
		</>
	)
}
