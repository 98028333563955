import React, {useState} from 'react'
import './input.css'

export const IngresoPromocionDropDown = ({head, list, disabled = null, id, idCompuesto, setValueDropDown}) => {
	const [active, setActive] = useState(false)
	const [ValueState, setValue] = useState(head)
	const [ValueSet, setValueSet] = useState(false)
	const [backgroundActive, setbackgroundActive] = useState(false)
	return (
		<>
			{backgroundActive && (
				<div
					className='background-work'
					onClick={(e) => {
						setActive(false)
						setbackgroundActive(false)
					}}
				></div>
			)}
			<div
				className={`IngresoPromocionDropDown  ${ValueSet && 'value-set'} ${
					disabled && 'disabled'
				}`}
				id={idCompuesto}
			>
				<div
					className='head'
					onClick={(e) => {
						setActive(!active)
						setbackgroundActive(true)
					}}
				>
					<span style={{whiteSpace: "nowrap", fontSize: "10px"}}>{ValueState}</span>
					<svg
						className={`${active && 'active-svg'}`}
						width='16'
						height='17'
						viewBox='0 0 16 17'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<mask
							id='mask0_640_313959'
							maskUnits='userSpaceOnUse'
							x='2'
							y='2'
							width='12'
							height='13'
						>
							<rect
								x='2'
								y='14.3334'
								width='12'
								height='12'
								transform='rotate(-90 2 14.3334)'
								fill='#D9D9D9'
							/>
						</mask>
						<g mask='url(#mask0_640_313959)'>
							<path
								d='M7.25004 5.78479L9.55004 8.08479C9.60837 8.14312 9.65004 8.20562 9.67504 8.27229C9.70004 8.33896 9.71254 8.40979 9.71254 8.48479C9.71254 8.55979 9.70004 8.63062 9.67504 8.69729C9.65004 8.76396 9.60837 8.82646 9.55004 8.88479L7.25004 11.1848C7.1417 11.2931 7.00837 11.3473 6.85004 11.3473C6.6917 11.3473 6.55837 11.2931 6.45004 11.1848C6.3417 11.0765 6.28754 10.9431 6.28754 10.7848C6.28754 10.6265 6.3417 10.4931 6.45004 10.3848L8.35004 8.48479L6.45004 6.58479C6.3417 6.47646 6.28754 6.34312 6.28754 6.18479C6.28754 6.02646 6.3417 5.89312 6.45004 5.78479C6.55837 5.67646 6.6917 5.62229 6.85004 5.62229C7.00837 5.62229 7.1417 5.67646 7.25004 5.78479Z'
								fill='#3676F5'
							/>
						</g>
					</svg>
				</div>
				{active && (
					<ul className='inputDropDown'>
						{list.map((EachList) => (
							<li style={{whiteSpace: "nowrap", fontSize: "10px"}}
								key={EachList}
								onClick={(e) => {
									setActive(false)
									setValue(EachList)
									setValueSet(true)
									setbackgroundActive(false)
									setValueDropDown(EachList);
									const campo = id.split("_")[0];
									const idRest = id.split("_")[1]+"_"+id.split("_")[2];
									if(campo === 'mecanica'){
										document.getElementById(`adherenciaField_${idRest}`).classList.remove("inputError");

										if(EachList.toUpperCase() === '1XY'){
											document.getElementById(`adherencia_${idRest}`).value = '100,00';
											document.getElementById(`adherencia_${idRest}`).disabled = true;
										}else{
											document.getElementById(`adherencia_${idRest}`).disabled = false;
										}
									}else{
										document.getElementById(id).value=EachList
									}
								}}
							>
								{EachList}
							</li>
						))}
					</ul>
				)}
			</div>{' '}
		</>
	)
}
