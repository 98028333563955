import {NewInputModuleName} from '../NewInputModuleName'

export const NombreDropdown = ({handleInpFilter, value}) => {
	return (
		<div className='ProdDropdown NombreDropdown'>
			<NewInputModuleName
				id='nombredropdown'
				type='text'
				placeholder='Buscar por nombre de Catálogo'
				valueInp={value}
				handleChange={handleInpFilter}
			/>
		</div>
	)
}
