import React from 'react'
import iconCatalog from '../assets/img/Iconoexport.svg'
export const MoveCataLog = ({setActive}) => {
	return (
		<div
			className='popupWrapper'
			id='popupCatalog'
			onClick={(e) => {
				if (e.target.id === 'popupCatalog') {
					setActive(false)
				}
			}}
		>
			<div className='popup SaveCatalog ExportPop' style={{height: 480}}>
				<div className='topexport'>
					<img src={iconCatalog} alt='' />
					<h1>Mover Catálogo</h1>
				</div>
				<p>Actualmente el catálogo se encuentra en</p>
				<ul className='list_diff'>
					<li>
						<h1>Fase</h1>
						<span>PROPUESTA INICIAL</span>
					</li>
					<li>
						<h1>Estado</h1>
						<span>EN EDICIÓN</span>
					</li>
				</ul>
				<p className='special_p'>Elige el destino de la Promoción</p>

				<a href='#' className='special_a'>
					Jumbo Abril 2022
				</a>
				<div className='select_area'>
					<label htmlFor=''>Estado</label>
					<select name='' id=''>
						<option value=''>Selecciona un Estado</option>
					</select>
				</div>
				<textarea
					name=''
					id=''
					cols='30'
					rows='10'
					placeholder='Deja un comentario.. (opcional)'
				></textarea>
				<p>*El catálogo avanzará a la siguiente fase</p>

				<div className='buttons_wrapper'>
					<button>Cancelar</button>
					<button>Exportar</button>
				</div>
			</div>
		</div>
	)
}
