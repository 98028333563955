import React, {useEffect, useState, useRef} from 'react'
import {Header} from '../Header'
import {LogoutPopup} from '../LogoutPopup'
import {IngresoPromocionHeader} from './IngresoPromocionHeader'
import {LengendColumns} from './LengendColumns'
import {LengendColumnsInput} from './LengendColumnsInput'
import '../../assets/css/IngresoPromocion.css'
import '@splidejs/react-splide/css'
import {LegendColumnRight} from './LegendColumnRight'
import {getPromotion} from '../../services/promotion.service'
import {getAllProducts} from '../../services/products.service'
import {getPreviousSimulation, simulatePromotion} from '../../services/simulation.service'
import {savePromotion} from '../../services/save.service'
import {deleteRow} from '../../services/delete.service'
import {Promotion} from '../../utils/PromotionInit'
import { useParams} from 'react-router-dom'
import {InicioPopEliminar} from './InicioPopEliminar'
import {InicioPopValidacion} from './InicioPopValidacion'
import { descuentoaNumero, dineroaNumero, numeroaDescuento, numeroaDinero} from './helper'
import {Simulacion} from './Simulacion'
import {Spinner} from '../../componentsRef/ui'
import './input.css'
import { CSVLink } from 'react-csv';

function leftJoin(data, products, keyName, keyName2) {
	return data.map(item => {
	  const product = products.find(p => p[keyName] === item[keyName2]);
	  return { ...item, ...product };
	});
  }

export const IngresoPromocion = () => {
	const [LogoutState, setLogoutState] = useState(false)
	const [HeightState, setHeight] = useState(0)
	const [HeightStateRight, setHeighRight] = useState(0)
	const [TransformState, setTransformState] = useState(0)
	const [legendColumns, setLegendColumns] = useState([]);
	const [promotion, setPromotion] = useState(null);
	const { idPromocion } = useParams()
	const [idProductoEliminar, setIdProductoEliminar] = useState(null)
	const [products, setProducts] = useState(null)
	const [dataRows, setDataRows] = useState(null)
	const [isInitialized, setIsInitialized] = useState(false)
	const [accionGuardar, setAccionGuardar] = useState(false)
	const [InicioPopState, setInicioPopState] = useState(null)
	const [idEliminar, setIdEliminar] = useState(null)
	const [validacion, setValidacion] = useState(null)
	const [textoValidacion, setTextoValidacion] = useState([])
	const [aprobarMovimiento, setAprobarMovimiento] = useState(false);
	const urlParams = new URLSearchParams(window.location.search);
  	const viewPage = urlParams.get('v');
	const [simulacionPage, setSimulacionPage] = useState(false);
	const [simulacionProcess, setSimulacionProcess] = useState(false);
	const [simulacionData, setSimulacionData] = useState(null);
	const [validacionProcess, setValidacionProcess] = useState(false);
	const [dataDescarga, setDataDescarga] = useState([]);
	const [flagReloadPromotion, setFlagReloadPromotion] = useState(true);
	const csvLinkRef = useRef(null);
	// alert(viewPage);

	const HandleResizeWork = () => {
		const WindowHeight = window.innerHeight - 130
		const WindowHeightRight = window.innerHeight - 103
		setHeight(WindowHeight)
		setHeighRight(WindowHeightRight)
	}
	const toggleSimulacionPage = (page) => {
		// Cambiar la ubicación en el historial de navegación
		const url = new URL(window.location.href);
		if (page) {
			url.searchParams.set('simulacion', 'true');
		} else {
			url.searchParams.delete('simulacion');
		}
		window.history.pushState(null, '', url.toString());
		// setSimulacionPage(!simulacionPage);
	  };
	useEffect(() => {
		const handlePopstate = (event) => {
		  if (simulacionPage) {
			setSimulacionPage(false);
			// setcurrentOption(null);
		  } else {
			history.back(); // Permite al navegador manejar el comportamiento por defecto del botón "Atrás"
		  }
		};
	
		window.addEventListener('popstate', handlePopstate);
	
		return () => {
		  window.removeEventListener('popstate', handlePopstate);
		};
	  }, [simulacionPage]);

	useEffect(() => {
		window.addEventListener('resize', HandleResizeWork)
		HandleResizeWork()
	}, [])
	// Eliminar producto
	useEffect(() => {
		console.log("legendColumns::::: index!!!");
		console.log(legendColumns);
		if(legendColumns && idProductoEliminar){
			/*
			console.log("eliminarProducto", id)
			
			const ColumnInput = document.getElementById("ColumnInput_"+id)
			console.log("ColumnInput", ColumnInput)
			ColumnInput.remove()
			*/
		// alert("Eliminando "+id);
		console.log("Estado actual:::. ");
		console.log(legendColumns)
		
		legendColumns.forEach((component) => {
			console.log("Componente actual:::....::. ");
			console.log(component.props.key+" id promocion promociones ");
			})
		
		const filteredColumns = legendColumns.filter(
				component => component.props.id !== idProductoEliminar
		);

		const componentToDelete = legendColumns.filter(
			component => component.props.id === idProductoEliminar
		);

		console.log("Columnas NO ELIMINADAS AL FINAL:::.");
		console.log(filteredColumns);
		// setLegendColumns(filteredColumns); // se cambia por una función useEFFECT de retorno
		setLegendColumns((prevLegendColumns) => {
			const filteredColumns = prevLegendColumns.filter(
			  (component) => component.props.id !== idProductoEliminar
			);
			return filteredColumns;
		  });
		/*
		const newPromotion = {...promotion};
		newPromotion.data_rows = newPromotion.data_rows.filter((row) => {
			return row.cod_producto !== getId(componentToDelete[0].props.id.split("_")[0], "codigo_producto");
		});
		setPromotion(newPromotion);
		*/
		// El id está compuesto del código de cliente y la cadena separado por _
		let codProducto = getId(componentToDelete[0].props.id.split("_")[0], "codigo_producto");
		if(codProducto === -1){
			codProducto = componentToDelete[0].props.id.split("_")[0];
		}
		/*
		setPromotion((prevPromotion) => {
			const newPromotion = {...prevPromotion};
			newPromotion.data_rows = newPromotion.data_rows.filter((row) => {
				return row.cod_producto !== getId(componentToDelete[0].props.id.split("_")[0], "codigo_producto");
			});
			return newPromotion;
		});
		*/
		let idFila = -1;
		promotion.data_rows.forEach((row, index) => {
			if(row.cod_producto === codProducto){
				idFila = row.id;
			}
		});
		setIdProductoEliminar(null)
		console.log("A eliminar:::::::::::: ");
		console.log(componentToDelete[0].props.keyId);
		console.log("ID REal a eliminar:::: "+idFila);

		try{
			console.log("ID A ELIMINAR DE TABLA::: "+componentToDelete[0].props.keyId);
			console.log("ID REAL A ELIMINAR:::: "+idFila)
				// if(componentToDelete[0].props.keyId !== null && componentToDelete[0].props.keyId !== undefined){
					deleteRow(idFila).then((response) => {
						console.log("Respuesta de guardado de promocion::: ");
						console.log(response);
						});
				// }
			}catch(error){
				console.log("Error al intentar eliminar........");
				console.log(error);
				console.log("El producto no existe en la BD, es nuevo");
			}
			
		// set Flag para reinicializar todo
		// setIsInitialized(true); // No resultó
		// setAccionGuardar(true); // No resultó
		}
		setFlagReloadPromotion(true);
	}, [legendColumns, idProductoEliminar])
	// Obtener la promoción
	useEffect(() => {
		;(async () => {
			try {
				// setLoading(true)
				const {data} = await getPromotion(idPromocion)
				if (data) {
					setPromotion(data)
					console.log("Promocion DATA!!!::::: ");
					console.log(data);
					// setLoading(false)
				}
			} catch (error) {
				setPromotion(Promotion)
				console.log(error)
				// setLoading(false)
			} finally {
				// setLoading(false)
			}
			setFlagReloadPromotion(false);
		})()
	}, [flagReloadPromotion]);
	// Obtener todos los productos
	useEffect(() => {
		;(async () => {
			try {
				// setLoading(true)
				const {data} = await getAllProducts()
				if (data) {
					setProducts(data)
					console.log("Products DATA!!!::::: ");
					console.log(data);
					// setLoading(false)
				}
			} catch (error) {
				// setPromotion(Promotion)
				console.log(error)
				// setLoading(false)
			} finally {
				// setLoading(false)
			}
		})()
	}, []);

	// Obtener Simulación Previa
	useEffect(() => {
		;(async () => {
			try {
				// setLoading(true)
				const {data} = await getPreviousSimulation(idPromocion)
				
				if (data) {
					data.resultado_simulacion.forEach((item, index) => {
						// item.margen_incremental_pct = item.margen_incremental_;
						item.margen_incremental_dinero = item.margen_promocion;

					})
					setSimulacionData(data)
					console.log("simulacion final lista DATA!!!::::: ");
					console.log(data);
					// console.log(simulacionData);
					// setLoading(false)
				}else{
					setSimulacionData(
						{
							"data_promocion": {
								"baseline": "-",
								"uplift": "-",
								"roi": "-",
								"margen_incremental": "-"
							}
						}
					)
				}
			} catch (error) {
				// setPromotion(Promotion)
				console.log("ERROR SIMULACION SET DATA");
				console.log(error)
				setSimulacionData(
					{
						"data_promocion": {
							"baseline": "-",
							"uplift": "-",
							"roi": "-",
							"margen_incremental": "-"
						}
					}
				)
				// setLoading(false)
			} finally {
				// setLoading(false)
			}
		})()
	}, []);
	// Intersección de productos y promociones
	useEffect(() => {
		if(promotion && products){
			const result = leftJoin(promotion.data_rows, products, "id_producto", "cod_producto");
			setDataRows(result);
			console.log("DATA ROWS NUEVO!!!!");
			console.log(result);
		}
	}, [promotion, products])
	// Inicio carga de columnas
	useEffect(() => {
		// Este efecto se ejecutará cada vez que 'dataRows' cambie
		console.log("DATA ROWS NUEVO ORIGINAL ..!!!!");
		console.log(dataRows);
		// Desplegar los productos en la columna central::: 

		const componentColumns = [];
		if(Array.isArray(dataRows) && !isInitialized){
			dataRows.forEach((product) => {
				componentColumns.push(
					<LengendColumnsInput 
					keyId={product.id} 
					id={product.codigo_producto+"_"+promotion.data_promocion.nombre_cadena} 
					legendColumns={legendColumns} 
					setLegendColumns={setLegendColumns}
					promotion={promotion}
					codigoProducto={product.codigo_producto}
					descripcion={product.descripcion}
					precioLista={product.precio_lista}
					precioPromocion={product.precio_promocion}
					descuento={product.descuento}
					mecanica={product.mecanica}
					adherencia={product.adherencia}
					exhibicion={product.id_exhibicion}
					activacion={product.activacion}
					invAdicional={product.inversion_adicional}
					mgCliente={product.margen_cliente} // Solo se lee.
					pvp={product.pvp} // Solo se lee
					inicioSellin={product.inicio_sellin}
					terminoSellin={product.termino_sellin}
					inicioSellout={product.inicio_sellout}
					terminoSellout={product.termino_sellout}
					setIdProductoEliminar={setIdProductoEliminar}
					tipoPromocion={promotion.data_promocion.tipo_promocion}
					setInicioPopState={setInicioPopState}
					setIdEliminar={setIdEliminar}
					/>
				);
			});
			// Agregar un nuevo componente <LengendColumnsInput/> con el ID 5000 a la lista
			/* <LengendColumnsInput key={1000} id={1000} 
				legendColumns={legendColumns} 
				setLegendColumns={setLegendColumns}
				promotion={promotion}
				/> */
				setLegendColumns(prevLegendColumns => {
					if (Array.isArray(prevLegendColumns)) {
					  return [...prevLegendColumns, ...componentColumns];
					} else {
					  // En caso de que prevLegendColumns no sea un array inicialmente, puedes manejarlo como desees.
					  // Por ejemplo, aquí estamos devolviendo un array solo con componentColumns en caso de que no sea un array.
					  return [...componentColumns];
					}
				  });
			setIsInitialized(true); // Para que no se vuelva a ejecutar este efecto.
			setValidacionProcess(true); // Para validar los campos al inicio.
		}
	}, [isInitialized, dataRows]);

	// funciones para formatear data antes de guardar
	
	const getId = (valor, tipo) => {
		let id;
		console.log("GET ID.:::: "+tipo);
		switch (tipo) {
			case "activacion":
				try{
					id = promotion.activaciones.find((activacion) => activacion.activacion.replace(/\s/g, '').toUpperCase() === valor.replace(/\s/g, '').toUpperCase()).id;
					return id;
				}catch(error){
					return -1;
				}				
			case "exhibicion":
				// alert(valor);
				try{
					id = promotion.exhibiciones.find((exhibicion) => exhibicion.exhibicion.replace(/\s/g, '').toUpperCase() === valor.replace(/\s/g, '').toUpperCase()).id;
					// alert("ID exhibicion "+id);
					return id;
				}catch(error){
					return -1;
				}
			case "mecanica":
				try{
					id = promotion.mecanicas.find((mecanica) => mecanica.mecanica.replace(/\s/g, '').toUpperCase() === valor.replace(/\s/g, '').toUpperCase()).id;
					// alert("ID mecanica "+id);
					return id;
				}catch(error){
					return -1;
				}
			case "codigo_producto":
				try{
					id = products.find((product) => product.codigo_producto.replace(/\s/g, '').toUpperCase() === valor.replace(/\s/g, '').toUpperCase()).id_producto;
					// alert("ID producto "+id);
					return id;
				}catch(error){
					return -1;
				}
			case "activacionValue":
				try{
					id = promotion.activaciones.find((activacion) => activacion.id === valor).activacion;
					return id;
				}catch(error){
					return -1;
				}
			case "exhibicionValue":
					try{
						id = promotion.exhibiciones.find((exhibicion) => exhibicion.id === valor).exhibicion;
						return id;
					}catch(error){
						return -1;
					}
			case "mecanicaValue":
				try{
					id = promotion.mecanicas.find((mecanica) => String(mecanica.id).replace(/\s/g, '').toUpperCase() === String(valor).replace(/\s/g, '').toUpperCase()).mecanica;
					return id;
				}catch(error){
					return -1;
				}
			case "codigo_productoValue":
					try{
						// alert(product.id_producto.replace(/\s/g, '').toUpperCase()+" === "+valor.replace(/\s/g, '').toUpperCase());
						id = products.find((product) => String(product.id_producto).replace(/\s/g, '').toUpperCase() === String(valor).replace(/\s/g, '').toUpperCase()).codigo_producto;
						// alert("ID producto "+id);
						return id;
					}catch(error){
						console.log("ERROR CODIGO PRODUCTO VALUE ::. "+error);
						return -1;
					}
		}
		return 1;
	}

	const getDateFormat = (date) => {
		return date.split("-").reverse().join("/");
	} 

	const getCorrectDate = (date) => {
		return date.split("/").reverse().join("-");
	}

	// Guardar cambios definitivos
	useEffect(() => {
		if(accionGuardar || simulacionProcess || validacionProcess){
			console.log("Guardando cambios!!!!!!!!!");
			// Capturar los datos de cada columna desde los ID's en base a las 
			const newColumns = []
			const now = new Date();
			legendColumns.forEach((component) => {
				// alert(component.props.id);
				let inicioSellin = '';
				let terminoSellin = '';
				let inicioSellout = '';
				let terminoSellout = '';
				if(promotion.data_promocion.tipo_promocion === 'SELL-IN'){
					inicioSellin = getCorrectDate(document.getElementById("inicio_"+component.props.id+"_sellin").value)
					inicioSellout = getCorrectDate(document.getElementById("inicio_"+component.props.id+"_sellout").value)
					terminoSellin = getCorrectDate(document.getElementById("termino_"+component.props.id+"_sellin").value)
					terminoSellout= getCorrectDate(document.getElementById("termino_"+component.props.id+"_sellout").value)					
				}else{
					inicioSellin = getCorrectDate(document.getElementById("inicio_"+component.props.id+"_sellout").value)
					inicioSellout = getCorrectDate(document.getElementById("inicio_"+component.props.id+"_sellout").value)
					terminoSellin = getCorrectDate(document.getElementById("termino_"+component.props.id+"_sellout").value)
					terminoSellout= getCorrectDate(document.getElementById("termino_"+component.props.id+"_sellout").value)
				}
					
				const newColumn = {

					// codigo_producto : component.props.codigoProducto,
					// id_compuesto: component.props.id,
					activacion: getId(document.getElementById("activacion_"+component.props.id).value, "activacion"),
					adherencia: descuentoaNumero(document.getElementById("adherencia_"+component.props.id).value),
					cod_producto: getId(component.props.id.split("_")[0], "codigo_producto"),
					descripcion : component.props.descripcion,
					// descripcion_producto: component.props.descripcion,
					descuento: descuentoaNumero(document.getElementById("descuento_"+component.props.id).value),
					fecha_actualizacion: now.toISOString().slice(0, 10)+" "+now.toISOString().slice(11, 19),
					// id_activacion: getId(document.getElementById("activacion_"+component.props.id).value, "activacion"),
					// id_exhibicion: getId(document.getElementById("exhibicion_"+component.props.id).value, "exhibicion"),
					// id_mecanica: getId(document.getElementById("mecanica_"+component.props.id).value, "mecanica"),
					// id_promotora: 2,
					// id_publicacion: 5,

					inicio_sellin: inicioSellin,
					inicio_sellout: inicioSellout,
					inversion_adicional: dineroaNumero(document.getElementById("invadicional_"+component.props.id).value),
					margen_cliente: descuentoaNumero(document.getElementById("mgcliente_"+component.props.id).value),
					mecanica: getId(document.getElementById("mecanica_"+component.props.id).value, "mecanica"),
					nombre_promo: promotion.data_promocion.nombre_promocion,
					precio_lista: component.props.precioLista,// parseInt(document.getElementById("preciolista_"+component.props.id).value),
					precio_promocion: dineroaNumero(document.getElementById("preciopromocion_"+component.props.id).value),
					promotora: 2,
					publicacion: 5,
					pvp: dineroaNumero(document.getElementById("pvp_"+component.props.id).value),
					duracion_sellin: 1,
					duracion_sellout: 1,
					termino_sellin: terminoSellin,
					termino_sellout: terminoSellout,
					exhibicion: getId(document.getElementById("exhibicion_"+component.props.id).value, "exhibicion"),
					
					// codigo_producto: component.props.id.split("_")[0],
				};
				newColumns.push(newColumn);
			})
			console.log("Columnas nuevas y antiguas a actualizar::::::: ");
			console.log(newColumns); // hasta acá todo bien
			// legendColumnInput (producto-cadena)
			// Tomar copia de data_rows y data init completo.
			const newPromotion =  {...promotion};
			/*
			const newDataRowsToAppend = [];
			// Recorrer cada legendColumnInput y actualizar los datos de data_rows con coincidencia de producto.
				newColumns.forEach((column) => {
					
					const row = newPromotion.data_rows.find((frow) => frow.cod_producto === column.cod_producto);

					if(row){
						row.activacion = column.activacion;
						row.adherencia = column.adherencia;
						row.descripcion_producto = column.descripcion_producto;
						row.descripcion = column.descripcion;
						row.descuento = column.descuento;
						row.fecha_actualizacion = column.fecha_actualizacion;
						row.id_activacion = column.id_activacion;
						row.id_exhibicion = column.id_exhibicion;
						row.id_mecanica = column.id_mecanica;
						row.id_promotora = column.id_promotora;
						row.id_publicacion	= column.id_publicacion;
						row.inicio_sellin = column.inicio_sellin;
						row.termino_sellin = column.termino_sellin;
						row.inicio_sellout = column.inicio_sellout;
						row.termino_sellout = column.termino_sellout;
						row.inversion_adicional = column.inversion_adicional;
						row.margen_cliente = column.margen_cliente;
						row.mecanica = column.mecanica;
						row.precio_lista = column.precio_lista;
						row.precio_promocion = column.precio_promocion;
						row.pvp = column.pvp;
						row.exhibicion = column.exhibicion;	
						row.promotora = column.promotora;
						row.publicacion	= column.publicacion;
						row.duracion_sellin = column.duracion_sellin;
						row.duracion_sellout = column.duracion_sellout;
					}else{// Quienes NO tengan coincidencia, se almacenarán los datos en un nuevo JSON o array.
						
						newDataRowsToAppend.push(column);
						
					}
				});
			// Al finalizar, se actualizará el data_rows con el nuevo JSON o array apendeado al data_rows.
			console.log("Data rows actualizado::::::: ");
			console.log(newPromotion.data_rows)
			console.log("NUEVOS REGISTROS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
			console.log(newDataRowsToAppend);
			// alert(newDataRowsToAppend.length);
			
			const uniqueCodProductos = {}; // Objeto para realizar un seguimiento de los códigos únicos
			
			if (newDataRowsToAppend.length > 0) {
				const newDataRowsToAppendFiltered = newDataRowsToAppend.filter(row => {
					if (!uniqueCodProductos[row.cod_producto]) {
						uniqueCodProductos[row.cod_producto] = true; // Marcar el código como procesado
						return true; // Mantener esta fila en newDataRowsToAppend
					}
					return false; // Filtrar esta fila duplicada
				});
				newPromotion.data_rows = newPromotion.data_rows.filter(row => {
					if (!uniqueCodProductos[row.cod_producto]) {
						uniqueCodProductos[row.cod_producto] = true; // Marcar el código como procesado
						return true; // Mantener esta fila en newPromotion.data_rows
					}
					return false; // Filtrar esta fila duplicada
				});
				newPromotion.data_rows = [...newPromotion.data_rows, ...newDataRowsToAppendFiltered];
			} else {
				newPromotion.data_rows = newPromotion.data_rows.filter(row => {
					if (!uniqueCodProductos[row.cod_producto]) {
						uniqueCodProductos[row.cod_producto] = true; // Marcar el código como procesado
						return true; // Mantener esta fila en newPromotion.data_rows
					}
					return false; // Filtrar esta fila duplicada
				});
			}
			

			*/
			newPromotion.data_rows = [...newColumns];
			
			// Consulta al servicio Web.
			// alert(idPromocion);
			// Filtrar los elementos en data_rows
			newPromotion.data_rows = newPromotion.data_rows.filter((row) => {
				// Verificar si cod_producto no es igual a -1 y no es de tipo 'string'
				return row.cod_producto !== -1 && typeof row.cod_producto !== 'string';
			});
			console.log("Enviado Final::::::::::::::::::: ");
			console.log(newPromotion);
			
			if(accionGuardar || simulacionProcess){
				savePromotion(idPromocion, newPromotion).then((response) => {
					console.log("Respuesta de guardado de promocion::: ");
					console.log(response);
					// Servicio para mover promoción en caso de que esté setteado.
					// movePromotion(idPromocion, newPromotion);
					// setPromotion(newPromotion);
					setFlagReloadPromotion(true);
				});
			}

			if(validatePromotion(newPromotion) && simulacionProcess){ // Se puede simular
				// alert("Simulando!!!!!!!!!");
				newPromotion.data_rows.forEach((row) => {
					// row.activacion = getId(row.activacion, "activacionValue");
					row.mecanica = getId(row.mecanica, "mecanicaValue");
					row.promotora = "NO";
					row.inicio_sellin = row.inicio_sellin !== "" ? row.inicio_sellin : "2023-10-01";
					row.inicio_sellout = row.inicio_sellout !== "" ? row.inicio_sellout : "2023-10-01";
					row.termino_sellout = row.termino_sellout !== "" ? row.termino_sellout : "2023-10-14";
					row.termino_sellin = row.termino_sellin !== "" ? row.termino_sellin : "2023-10-14";
				});
				try{
					simulatePromotion(newPromotion).then((response) => {
						console.log("Respuesta de Simulación Promoción::: ");
						console.log(response);
						// Servicio para mover promoción en caso de que esté setteado.
						// movePromotion(idPromocion, newPromotion);
						// setPromotion(newPromotion);
						// alert(response.status);
						if(response.status === 200){
							setSimulacionData(response);
							console.log(response);
						}else{
							alert("Por favor, completar todos los campos obligatorios. e: 1001");
							console.log("ERROR 500 U OTRO EN SIMULADOR 1!!!!");
						}
						
						setSimulacionProcess(false)
					}).catch((error) => {
						alert("Por favor, completar todos los campos obligatorios. e: 1002");
						console.log("2")
						console.log(error);
						setSimulacionProcess(false)
					});
				}catch(error){
					alert("Por favor, completar todos los campos obligatorios. e: 1003");
					console.log("ERROR 500 U OTRO EN SIMULADOR 3!!!!");
					setSimulacionProcess(false)
				}
				
			}else if(!validatePromotion(newPromotion) && simulacionProcess){
				// alert("Por favor, completar todos los campos obligatorios.");
				let errores = "Por favor revisar los siguientes errores marcados en rojo \n";
				textoValidacion.forEach((item) => {
					errores += item+"\n";
				})
				alert(errores);
				setSimulacionProcess(false)
			}

			// setSimulacionProcess(false)
			setAprobarMovimiento(validatePromotion(newPromotion));
			setValidacionProcess(false);
			// Proceso terminado
			// alert("Guardando cambios!!!!");
			setAccionGuardar(false)

			// Formateo de campos a descargar:::::
			const newPromotionDescarga = {...newPromotion};
			newPromotionDescarga.data_rows.forEach((row) => {
				row.inversion_adicional = numeroaDinero(row.inversion_adicional);
				row.margen_cliente = numeroaDescuento(row.margen_cliente);
				row.precio_lista = numeroaDinero(row.precio_lista);
				row.precio_promocion = numeroaDinero(row.precio_promocion);
				row.pvp = numeroaDinero(row.pvp);
				row.descuento = numeroaDescuento(row.descuento);
				row.adherencia = numeroaDescuento(row.adherencia);
				row.mecanica = getId(row.mecanica, "mecanicaValue");
				row.cod_producto = getId(row.cod_producto, "codigo_productoValue");
				row.activacion = getId(row.activacion, "activacionValue");
				row.exhibicion = getId(row.exhibicion, "exhibicionValue");
				row.inicio_sellin = getDateFormat(row.inicio_sellin);
				row.inicio_sellout = getDateFormat(row.inicio_sellout);
				row.termino_sellin = getDateFormat(row.termino_sellin);
				row.termino_sellout = getDateFormat(row.termino_sellout);
				delete row.duracion_sellin;
				delete row.duracion_sellout;
				delete row.promotora;
				delete row.publicacion;
				delete row.id_activacion;
				delete row.id_exhibicion;
				delete row.id_mecanica;
				delete row.id_promotora;
				delete row.id_publicacion;
				delete row.descripcion_producto;
			});
			setDataDescarga(newPromotionDescarga.data_rows);
			
		}
	}, [legendColumns, accionGuardar, simulacionProcess, validacionProcess])

	const validatePromotion = (promotion) => {
		console.log("PROMOCIONES A VALIDAR:::::::::::");
		console.log(promotion);
		let erroresDescuentos = 0; // mayores a 100 o negativos
		let erroresPrecioPromo = 0;
		let erroresInvadicional = 0;
		let erroresSellin = 0;
		let erroresSellout = 0;
		let erroresAdherencia = 0;
		let erroresMecanica = 0;
		let erroresExhibicion = 0;
		let erroresActivacion = 0;
		let erroresPvp = 0;
		// let erroresMargenCliente = 0;

		promotion.data_rows.forEach((row) => {
			const idCompuesto =  getId(row.cod_producto, "codigo_productoValue")+"_"+promotion.data_promocion.nombre_cadena;
			console.log(idCompuesto+" ::::: NUEVO ID A CATALOGAR");
			console.log(row);
			if(row.mecanica === "" || row.mecanica === null || row.mecanica === -1){
				erroresMecanica++;
				console.log("mecanicaField_"+idCompuesto);
				try{
					document.getElementById("mecanicaField_"+idCompuesto).classList.add("inputError");
				}catch(error){
					console.log("ERROR AL AGREGAR CLASE");
					if(erroresMecanica > 0){
						erroresMecanica--;
					}
				}
			}else{
				try{
					document.getElementById("mecanicaField_"+idCompuesto).classList.remove("inputError");
				}catch(error){
					console.log("ERROR AL QUITAR CLASE ERROR");
					if(erroresMecanica > 0){
						erroresMecanica--;
					}
				}
				// document.getElementById("mecanicaField_"+idCompuesto).classList.remove("inputError");
			}
			if(row.exhibicion === "" || row.exhibicion === null || row.exhibicion === -1){
				erroresExhibicion++;
				try{
					document.getElementById("exhibicionField_"+idCompuesto).classList.add("inputError");
				}catch(error){
					console.log("ERROR AL AGREGAR CLASE");
					if(erroresExhibicion > 0){
						erroresExhibicion--;
					}
				}
			}else{
				try{
					document.getElementById("exhibicionField_"+idCompuesto).classList.remove("inputError");
				}catch(error){
					console.log("ERROR AL QUITAR CLASE ERROR");
					if(erroresExhibicion > 0){
						erroresExhibicion--;
					}
				}
			}
			if(row.activacion === "" || row.activacion === null || row.activacion === -1){
				erroresActivacion++;
				try{
					document.getElementById("activacionField_"+idCompuesto).classList.add("inputError");
				}catch(error){
					console.log("ERROR AL AGREGAR CLASE");
					if(erroresActivacion > 0){
						erroresActivacion--;
					}
				}
			}else{
				try{
					document.getElementById("activacionField_"+idCompuesto).classList.remove("inputError");
				}catch(error){
					console.log("ERROR AL QUITAR CLASE ERROR");
					if(erroresActivacion > 0){
						erroresActivacion--;
					}
				}
			}
			if(row.pvp <= 0 || row.pvp === "" || row.pvp === null){
				erroresPvp++;
				try{
					document.getElementById("pvpField_"+idCompuesto).classList.add("inputError");
				}catch(error){
					console.log("ERROR AL AGREGAR CLASE");
					if(erroresPvp > 0){
						erroresPvp--;
					}
				}
			}else{
				try{
					document.getElementById("pvpField_"+idCompuesto).classList.remove("inputError");
				}catch(error){
					console.log("ERROR AL QUITAR CLASE ERROR");
					if(erroresPvp > 0){
						erroresPvp--;
					}
				}
			}
			if(row.descuento > 100 || row.descuento <= 0 || row.descuento === "" || row.descuento === null){
				erroresDescuentos++;
				try{
					document.getElementById("descuentoField_"+idCompuesto).classList.add("inputError");
				}catch(error){
					console.log("ERROR AL AGREGAR CLASE");
					if(erroresDescuentos > 0){
						erroresDescuentos--;
					}
				}
			}else{
				try{
					document.getElementById("descuentoField_"+idCompuesto).classList.remove("inputError");
				}catch(error){
					console.log("ERROR AL QUITAR CLASE ERROR");
					if(erroresDescuentos > 0){
						erroresDescuentos--;
					}
				}
			}
			if(promotion.data_promocion.tipo_promocion === 'SELL-IN' && (row.precio_promocion < 0 || row.precio_promocion > row.precio_lista || row.precio_promocion === "" || row.precio_promocion === null)){
				erroresPrecioPromo++;
				try{
					document.getElementById("preciopromocionField_"+idCompuesto).classList.add("inputError");
				}catch(error){
					console.log("ERROR AL AGREGAR CLASE");
					if(erroresPrecioPromo > 0){
						erroresPrecioPromo--;
					}
				}
			}else{
				try{
					document.getElementById("preciopromocionField_"+idCompuesto).classList.remove("inputError");
				}catch(error){
					console.log("ERROR AL QUITAR CLASE ERROR");
					if(erroresPrecioPromo > 0){
						erroresPrecioPromo--;
					}
				}
			}
			if(row.inversion_adicional < 0 || row.inversion_adicional === "" || row.inversion_adicional === null){
				erroresInvadicional++;
				try{
					document.getElementById("invadicionalField_"+idCompuesto).classList.add("inputError");
				}catch(error){
					console.log("ERROR AL AGREGAR CLASE");
					if(erroresInvadicional > 0){
						erroresInvadicional--;
					}
				}
			}else{
				try{
					document.getElementById("invadicionalField_"+idCompuesto).classList.remove("inputError");
				}catch(error){
					console.log("ERROR AL QUITAR CLASE ERROR");
					if(erroresInvadicional > 0){
						erroresInvadicional--;
					}
				}
			}
			if(promotion.data_promocion.tipo_promocion === 'SELL-IN'){
				if((row.inicio_sellout < row.inicio_sellin) || (row.inicio_sellout > row.termino_sellin) || (row.inicio_sellout > row.termino_sellout) || (row.inicio_sellout === "" || row.inicio_sellout === null)){
					erroresSellin++;
					try{
						document.getElementById("IngresoPromocionDropDownDate_"+idCompuesto+"_sellin").classList.add("inputError");
						document.getElementById("IngresoPromocionDropDownDate_"+idCompuesto+"_sellout").classList.add("inputError");
					}catch(error){
						console.log("ERROR AL AGREGAR CLASE");
						if(erroresSellin > 0){
							erroresSellin--;
						}
					}
				}else{
					try{
						document.getElementById("IngresoPromocionDropDownDate_"+idCompuesto+"_sellin").classList.remove("inputError");
						document.getElementById("IngresoPromocionDropDownDate_"+idCompuesto+"_sellout").classList.remove("inputError");
					}catch(error){
						console.log("ERROR AL QUITAR CLASE ERROR");
						if(erroresSellin > 0){
							erroresSellin--;
						}
					}
				}
			}
			if((row.termino_sellout < row.inicio_sellout) || (row.termino_sellout === "" || row.termino_sellout === null) || (row.inicio_sellout === "" || row.inicio_selloout === null) 
			|| (row.termino_sellin < row.termino_sellout)){
				erroresSellout++;
				// document.getElementById("IngresoPromocionDropDownDate_"+idCompuesto+"_sellin").classList.add("inputError");
				try{
					document.getElementById("IngresoPromocionDropDownDate_"+idCompuesto+"_sellout").classList.add("inputError");
				}catch(error){
					console.log("ERROR AL AGREGAR CLASE");
					if(erroresSellout > 0){
						erroresSellout--;
					}
				}
			}else{
				try{
					document.getElementById("IngresoPromocionDropDownDate_"+idCompuesto+"_sellout").classList.remove("inputError");
				}catch(error){
					console.log("ERROR AL QUITAR CLASE ERROR");
					if(erroresSellout > 0){
						erroresSellout--;
					}
				}
			}
			if(row.adherencia > 100 || row.adherencia < 0 || row.adherencia === "" || row.adherencia === null){
				erroresAdherencia++;
				try{
					document.getElementById("adherenciaField_"+idCompuesto).classList.add("inputError");
				}catch(error){
					console.log("ERROR AL AGREGAR CLASE");
					if(erroresAdherencia > 0){
						erroresAdherencia--;
					}
				}
			}else{
				try{
					document.getElementById("adherenciaField_"+idCompuesto).classList.remove("inputError");
				}catch(error){
					console.log("ERROR AL QUITAR CLASE ERROR");
					if(erroresAdherencia > 0){
						erroresAdherencia--;
					}
				}
			}
		});
		const mensajesError = [];
		if(erroresDescuentos > 0){ mensajesError.push(erroresDescuentos+ ' Errores en Descuento');}
		if(erroresPrecioPromo > 0){ mensajesError.push(erroresPrecioPromo+ ' Errores en Precio Promo ');}
		if(erroresInvadicional > 0){ mensajesError.push(erroresInvadicional+ ' Errores en Inversion Adicional');}
		if(erroresSellin > 0){ mensajesError.push(erroresSellin+ ' Errores en Sellin');}
		if(erroresSellout > 0){ mensajesError.push(erroresSellout+ ' Errores en Sellout');}
		if(erroresAdherencia > 0){ mensajesError.push(erroresAdherencia+ ' Errores en Adherencia');}
		if(erroresMecanica > 0){ mensajesError.push(erroresMecanica+ ' Errores en Mecanica');}
		if(erroresExhibicion > 0){ mensajesError.push(erroresExhibicion+ ' Errores en Exhibicion');}
		if(erroresActivacion > 0){ mensajesError.push(erroresActivacion+ ' Errores en Activacion');}
		if(erroresPvp > 0){ mensajesError.push(erroresPvp+ ' Errores en PVP');}
		if(promotion.data_rows.length === 0){ mensajesError.push('No hay productos en la promoción');}

		const errorCatalogoVacio = promotion.data_rows.length === 0 ? 1 : 0;

		const erroresTotales = erroresDescuentos + erroresPrecioPromo + erroresInvadicional + erroresSellin + erroresSellout + erroresAdherencia + erroresMecanica + erroresExhibicion + erroresActivacion + erroresPvp + errorCatalogoVacio;
		if(erroresTotales > 0){
			setValidacion(true);
			setTextoValidacion(mensajesError);
		}else{
			setValidacion(false);
			setTextoValidacion([]);
		}
		console.log("ERRORES TOTALES VALIDACION ENCONTRADOS::::: "+erroresTotales);
		return !(erroresTotales > 0);
	}

	return (
		<div className='HomeNew'>
			{ 
				// validacion && <InicioPopValidacion setValidacion={setValidacion} texto={textoValidacion}/>
			}
			{dataDescarga && (<CSVLink
				data={dataDescarga}
				filename={'download.csv'}
				className={"csv-link"}
				ref={csvLinkRef} // Asigna la referencia al CSVLink
			>
				Descargar CSV
			</CSVLink>)}
			{LogoutState && <LogoutPopup setLogoutState={setLogoutState} />}
			{InicioPopState && <InicioPopEliminar 
			setInicioPopState={setInicioPopState} 
			idProducto={idEliminar}
			setIdProductoEliminar={setIdProductoEliminar}
			/>}
			<Header setLogoutState={setLogoutState} />

			{/* header done */}
			{(promotion && simulacionData) && <IngresoPromocionHeader nroSku={
				legendColumns && legendColumns.length
			}
			promotion={promotion}
			setSimulacionProcess={setSimulacionProcess}
			simulacionProcess={simulacionProcess}
			Spinner={Spinner}
			simulacionData={simulacionData}
			viewPage={viewPage}
			/>
			}

			<div
				className={`body-area-ingreso ${simulacionPage ? 'body-area-ingreso-resumen' : 'body-area-ingreso-promocion'}`}
				style={{height: `${HeightState}px`}}
			>
				{( promotion && !simulacionPage) &&
				<LengendColumns tipoPromocion={promotion.data_promocion.tipo_promocion}
				toolTips={promotion.texto_tooltips}/>
				}
	{ // !simulacionPage &&
				<div className={`columns-area-wrapper  ${simulacionPage ? 'hiddenV2' : ''}`} >
					<div
						className='column-track columnTracking'
						style={{// transform: `translateX(-${TransformState}px)`,
						overflowX: "scroll",
						marginRight: "80px",
						scrollbarWidth: "thin",
						scrollbarColor: "transparent transparent gray",
						scrollBehavior: "smooth",
						width: "96%",
						overflowY: "hidden"
					}}
						id={"column-track"}
					>
					

						{(legendColumns && !viewPage) &&
							legendColumns.length > 0 && 
								legendColumns.map(component => component)
						}

						{legendColumns && viewPage &&
						legendColumns.length > 0 && 
						legendColumns.map((component, index) => {
							return React.cloneElement(component, { view: true, key: index });
						})
						}
						
					</div>

					<div className='buttons-wrapper'>
						<button
							className='prev'
							onClick={(e) => {
								
								document.getElementById("column-track").scrollLeft -= 100;
							}}
						>
							<svg
								width='28'
								height='28'
								viewBox='0 0 28 28'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<rect width='28' height='28' rx='4' fill='#DBE7FF' />
								<g clipPath='url(#clip0_655_479170)'>
									<mask
										id='mask0_655_479170'
										maskUnits='userSpaceOnUse'
										x='8'
										y='8'
										width='12'
										height='12'
									>
										<rect x='8' y='8' width='12' height='12' fill='#D9D9D9' />
									</mask>
									<g mask='url(#mask0_655_479170)'>
										<path
											d='M15.375 18.5375L11.1625 14.3375C11.1042 14.2792 11.0625 14.2167 11.0375 14.15C11.0125 14.0833 11 14.0125 11 13.9375C11 13.8625 11.0125 13.7917 11.0375 13.725C11.0625 13.6583 11.1042 13.5958 11.1625 13.5375L15.375 9.325C15.5083 9.19167 15.6708 9.125 15.8625 9.125C16.0542 9.125 16.2167 9.19583 16.35 9.3375C16.4917 9.47083 16.5625 9.63117 16.5625 9.8185C16.5625 10.0062 16.4917 10.1708 16.35 10.3125L12.725 13.9375L16.35 17.5625C16.4833 17.6958 16.55 17.8562 16.55 18.0435C16.55 18.2312 16.4833 18.3958 16.35 18.5375C16.2083 18.6708 16.0438 18.7375 15.8565 18.7375C15.6688 18.7375 15.5083 18.6708 15.375 18.5375Z'
											fill='#124FC9'
										/>
									</g>
								</g>
								<defs>
									<clipPath id='clip0_655_479170'>
										<rect
											width='12'
											height='12'
											fill='white'
											transform='translate(8 8)'
										/>
									</clipPath>
								</defs>
							</svg>
						</button>
						<button
							className='forward'
							onClick={(e) => {
								
								document.getElementById("column-track").scrollLeft += 100;
								
							}}
						>
							<svg
								width='28'
								height='28'
								viewBox='0 0 28 28'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<rect width='28' height='28' rx='4' fill='#DBE7FF' />
								<g clipPath='url(#clip0_655_479176)'>
									<mask
										id='mask0_655_479176'
										maskUnits='userSpaceOnUse'
										x='8'
										y='8'
										width='12'
										height='12'
									>
										<rect x='8' y='8' width='12' height='12' fill='#D9D9D9' />
									</mask>
									<g mask='url(#mask0_655_479176)'>
										<path
											d='M11.5249 18.6C11.3915 18.4583 11.3249 18.2937 11.3249 18.106C11.3249 17.9187 11.3915 17.7542 11.5249 17.6125L15.1374 14L11.5124 10.375C11.379 10.2417 11.3124 10.0792 11.3124 9.8875C11.3124 9.69583 11.3832 9.52917 11.5249 9.3875C11.6582 9.25417 11.8207 9.1875 12.0124 9.1875C12.204 9.1875 12.3707 9.25417 12.5124 9.3875L16.7124 13.6C16.7707 13.6583 16.8124 13.7208 16.8374 13.7875C16.8624 13.8542 16.8749 13.925 16.8749 14C16.8749 14.075 16.8624 14.1458 16.8374 14.2125C16.8124 14.2792 16.7707 14.3417 16.7124 14.4L12.4999 18.6125C12.3665 18.7458 12.206 18.8125 12.0184 18.8125C11.831 18.8125 11.6665 18.7417 11.5249 18.6Z'
											fill='#124FC9'
										/>
									</g>
								</g>
								<defs>
									<clipPath id='clip0_655_479176'>
										<rect
											width='12'
											height='12'
											fill='white'
											transform='translate(8 8)'
										/>
									</clipPath>
								</defs>
							</svg>
						</button>
					</div>
				</div>
		}
		{(simulacionPage && simulacionData) &&
			(	!simulacionProcess ?
					<Simulacion simulacionData={simulacionData}/> : <><h5
					><br></br>Cargando Simulación ...<Spinner/>
					</h5></>
			)
		}

				{( promotion && !viewPage ) &&	
				<LegendColumnRight HeightStateRight={HeightStateRight} 
				setLegendColumns={setLegendColumns}
				legendColumns={legendColumns}
				promotion={promotion}
				setIdProductoEliminar={setIdProductoEliminar}
				setAccionGuardar={setAccionGuardar}
				movimientos={promotion.movimientos}
				setInicioPopStateEliminar={setInicioPopState}
				setIdEliminar={setIdEliminar}
				textoValidacion={textoValidacion}
				aprobarMovimiento={aprobarMovimiento}
				validacion={validacion}
				setSimulacionPage={setSimulacionPage}
				toggleSimulacionPage={toggleSimulacionPage}
				simulacionPage={simulacionPage}
				csvLinkRef={csvLinkRef}
				/>
				}
			</div>
		</div>
	)
}
