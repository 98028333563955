import Cerrar from '../assets/img/Cerrar.svg'
export const CatalogosConsolidadosPopup = ({setCatalogosConsolidadosPopup}) => {
	return (
		<div
			className='popupWrapper  CatalogosConsolidadosPopup'
			id='popupCatalog'
			style={{zIndex: 99999}}
		>
			<div className='popup SaveCatalog ExportPop PromotionalVariable PromotionalVariableNew'>
				<div className='close_icon'>
					<img
						src={Cerrar}
						alt=''
						onClick={(e) => {
							setCatalogosConsolidadosPopup(false)
						}}
					/>
				</div>
				<div className='topexport'>
					<h1 style={{marginLeft: 0}}>Catálogos Consolidados</h1>
				</div>
				<div className='table_wrapper'>
					<table>
						<thead>
							<tr>
								<th>
									Nombre
									<svg
										width='5'
										height='8'
										viewBox='0 0 5 8'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M0.411409 2.65947L2.18172 0.88916C2.22756 0.843327 2.27763 0.810441 2.33194 0.790504C2.38648 0.770337 2.4424 0.760254 2.49969 0.760254C2.55698 0.760254 2.6129 0.770337 2.66744 0.790504C2.72175 0.810441 2.77183 0.843327 2.81766 0.88916L4.58797 2.65947C4.7312 2.8027 4.7642 2.96598 4.68697 3.14932C4.60951 3.33265 4.47052 3.42432 4.27 3.42432H0.729378C0.528857 3.42432 0.389982 3.33265 0.312753 3.14932C0.235295 2.96598 0.26818 2.8027 0.411409 2.65947Z'
											fill='#878787'
										/>
										<path
											d='M0.411409 5.34072C0.26818 5.19749 0.235295 5.03421 0.312753 4.85088C0.389982 4.66755 0.528857 4.57588 0.729378 4.57588H4.27C4.47052 4.57588 4.60951 4.66755 4.68697 4.85088C4.7642 5.03421 4.7312 5.19749 4.58797 5.34072L2.81766 7.11103C2.77183 7.15687 2.72175 7.18975 2.66744 7.20969C2.6129 7.22986 2.55698 7.23994 2.49969 7.23994C2.4424 7.23994 2.38648 7.22986 2.33194 7.20969C2.27763 7.18975 2.22756 7.15687 2.18172 7.11103L0.411409 5.34072Z'
											fill='#878787'
										/>
									</svg>
								</th>
								<th>Línea</th>
								<th>Oficinas</th>
								<th>
									Actualización
									<svg
										width='5'
										height='8'
										viewBox='0 0 5 8'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M0.244417 2.65947L2.01473 0.88916C2.06056 0.843327 2.11064 0.810441 2.16495 0.790504C2.21949 0.770337 2.27541 0.760254 2.3327 0.760254C2.38999 0.760254 2.44591 0.770337 2.50045 0.790504C2.55476 0.810441 2.60483 0.843327 2.65067 0.88916L4.42098 2.65947C4.56421 2.8027 4.59721 2.96598 4.51998 3.14932C4.44252 3.33265 4.30353 3.42432 4.10301 3.42432H0.562386C0.361865 3.42432 0.22299 3.33265 0.145761 3.14932C0.0683025 2.96598 0.101188 2.8027 0.244417 2.65947Z'
											fill='#878787'
										/>
										<path
											d='M0.244417 5.34072C0.101188 5.19749 0.0683024 5.03421 0.145761 4.85088C0.22299 4.66755 0.361865 4.57588 0.562386 4.57588H4.10301C4.30353 4.57588 4.44252 4.66755 4.51998 4.85088C4.59721 5.03421 4.56421 5.19749 4.42098 5.34072L2.65067 7.11103C2.60483 7.15687 2.55476 7.18975 2.50045 7.20969C2.44591 7.22986 2.38999 7.23994 2.3327 7.23994C2.27541 7.23994 2.21949 7.22986 2.16495 7.20969C2.11064 7.18975 2.06056 7.15687 2.01473 7.11103L0.244417 5.34072Z'
											fill='#878787'
										/>
									</svg>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
							<tr>
								<td>Nacional Abril 2022</td>
								<td>Lácteos</td>
								<td>Nacional</td>
								<td>09/12/2022, 17:45</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}
