import React, {useState} from 'react'
import PromoBooster from '../assets/img/PromoBooster.svg'
import {Link, useNavigate} from 'react-router-dom'
import lineBg from '..//assets/img/line_bg.svg'
export const PasswordChangeSuccess = () => {
	const navigate = useNavigate()
	const handleLogin = (e) => {
		e.preventDefault()

		navigate('/')
	}

	return (
		<div className='Login ResetPassword EmailSent PasswordChangeSuccess'>
			<img src={lineBg} alt='' style={{opacity: 0.4}} />
			<form action=''>
				<svg
					width='49'
					height='48'
					viewBox='0 0 49 48'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask
						id='mask0_2711_474765'
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='49'
						height='48'
					>
						<rect x='0.5' width='48' height='48' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_2711_474765)'>
						<path
							d='M44.9002 19.1C45.1002 19.9 45.2669 20.7083 45.4002 21.525C45.5335 22.3417 45.6002 23.1667 45.6002 24C45.6002 26.9333 45.0585 29.7 43.9752 32.3C42.8919 34.9 41.4002 37.1417 39.5002 39.025C37.6002 40.9083 35.3669 42.3833 32.8002 43.45C30.2335 44.5167 27.4669 45.05 24.5002 45.05C21.5669 45.05 18.8002 44.5167 16.2002 43.45C13.6002 42.3833 11.3585 40.9083 9.4752 39.025C7.59186 37.1417 6.11686 34.9 5.0502 32.3C3.98353 29.7 3.4502 26.9333 3.4502 24C3.4502 21.0333 3.98353 18.2667 5.0502 15.7C6.11686 13.1333 7.59186 10.9 9.4752 9C11.3585 7.1 13.6002 5.60834 16.2002 4.525C18.8002 3.44167 21.5669 2.9 24.5002 2.9C26.3669 2.9 28.1585 3.16667 29.8752 3.7C31.5919 4.23333 33.2169 4.9 34.7502 5.7C35.3169 5.93333 35.6835 6.38333 35.8502 7.05C36.0169 7.71667 35.9335 8.33333 35.6002 8.9C35.2669 9.36667 34.8085 9.68334 34.2252 9.85C33.6419 10.0167 33.1002 10 32.6002 9.8C31.4335 9.06667 30.1669 8.5 28.8002 8.1C27.4335 7.7 26.0002 7.5 24.5002 7.5C19.8335 7.5 15.9169 9.075 12.7502 12.225C9.58353 15.375 8.0002 19.3 8.0002 24C8.0002 28.7 9.58353 32.625 12.7502 35.775C15.9169 38.925 19.8335 40.5 24.5002 40.5C29.2669 40.5 33.2002 38.9083 36.3002 35.725C39.4002 32.5417 40.9502 28.6 40.9502 23.9C40.9502 23.3 40.9085 22.7083 40.8252 22.125C40.7419 21.5417 40.6335 21 40.5002 20.5C40.4335 19.6667 40.5669 19.0083 40.9002 18.525C41.2335 18.0417 41.6502 17.7 42.1502 17.5C42.6502 17.3667 43.2002 17.4417 43.8002 17.725C44.4002 18.0083 44.7669 18.4667 44.9002 19.1ZM19.9002 32.1L14.3002 26.45C13.8669 26.0833 13.6502 25.5917 13.6502 24.975C13.6502 24.3583 13.8835 23.8167 14.3502 23.35C14.7835 22.95 15.3085 22.75 15.9252 22.75C16.5419 22.75 17.0835 22.95 17.5502 23.35L21.4502 27.35L40.8502 7.9C41.2835 7.5 41.8085 7.29167 42.4252 7.275C43.0419 7.25833 43.5835 7.46667 44.0502 7.9C44.4835 8.4 44.7002 8.95 44.7002 9.55C44.7002 10.15 44.4835 10.6833 44.0502 11.15L23.0502 32.1C22.6169 32.5667 22.0752 32.8 21.4252 32.8C20.7752 32.8 20.2669 32.5667 19.9002 32.1Z'
							fill='url(#paint0_linear_2711_474765)'
						/>
					</g>
					<defs>
						<linearGradient
							id='paint0_linear_2711_474765'
							x1='5.15898'
							y1='2.90001'
							x2='55.0308'
							y2='6.03412'
							gradientUnits='userSpaceOnUse'
						>
							<stop stopColor='#FFAC23' />
							<stop offset='1' stopColor='#FF5334' />
						</linearGradient>
					</defs>
				</svg>

				<h1>Tu contraseña ha sido cambiada con éxito</h1>
				<p className='firstp'>
					Ya puedes volver a <b>PROMO</b>BOOSTER con la nueva contraseña
				</p>

				<div className='button_wrapper button_wrapper_new'>
					<button onClick={handleLogin}>Ir a PROMOBOOSTER</button>
				</div>
			</form>
			<img src={PromoBooster} className='logo_one' alt='' />
		</div>
	)
}
