import {useState} from 'react'

export const CadenaDropdown = ({
	chains,
	handleChainSelected,
	chainSelected,
}) => {
	const [active, setActive] = useState(false)
	const [bgCover, setbgCover] = useState(false)

	return (
		<>
			{bgCover && (
				<div
					className='bg-cover'
					onClick={(e) => {
						setbgCover(false)
						setActive(false)
					}}
				></div>
			)}
			<div
				className={`ProdDropdown CadenaDropdown CadenaDropdownWidth ${
					active && 'active'
				}`}
			>
				<div
					className={`head ${active && 'active'} ${
						chainSelected && 'amountIn'
					}`}
					onClick={(e) => {
						setActive(!active)
						setbgCover(!bgCover)
					}}
				>
					{chainSelected ? (
						<div className='head-wrapper'>
							<p>{chainSelected?.nombre_cadena}</p>
						</div>
					) : (
						<div className='p-wrapper'>
							<p>Filtrar por Cadena</p>
						</div>
					)}

					<svg
						width='12'
						height='12'
						viewBox='0 0 12 12'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<mask
							id='mask0_449_122826'
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='12'
							height='12'
						>
							<rect
								y='12'
								width='12'
								height='12'
								transform='rotate(-90 0 12)'
								fill='#D9D9D9'
							/>
						</mask>
						<g mask='url(#mask0_449_122826)'>
							<path
								d='M5.2501 3.45142L7.5501 5.75142C7.60843 5.80975 7.6501 5.87225 7.6751 5.93892C7.7001 6.00558 7.7126 6.07642 7.7126 6.15142C7.7126 6.22642 7.7001 6.29725 7.6751 6.36392C7.6501 6.43058 7.60843 6.49308 7.5501 6.55142L5.2501 8.85142C5.14176 8.95975 5.00843 9.01392 4.8501 9.01392C4.69176 9.01392 4.55843 8.95975 4.4501 8.85142C4.34176 8.74308 4.2876 8.60975 4.2876 8.45142C4.2876 8.29308 4.34176 8.15975 4.4501 8.05142L6.3501 6.15142L4.4501 4.25142C4.34176 4.14308 4.2876 4.00975 4.2876 3.85142C4.2876 3.69308 4.34176 3.55975 4.4501 3.45142C4.55843 3.34308 4.69176 3.28892 4.8501 3.28892C5.00843 3.28892 5.14176 3.34308 5.2501 3.45142Z'
								fill='#3676F5'
							/>
						</g>
					</svg>
				</div>
				<ul
					className='dropdown'
					style={active ? {display: 'block'} : {display: 'none'}}
				>
					{chains &&
						chains?.map((EachContent, key) => (
							<li key={key}>
								<input
									type='radio'
									name='cadena'
									id={EachContent.id_cadena}
									onChange={(e) => {
										setActive(!active)
										setbgCover(!bgCover)
										handleChainSelected(EachContent)
									}}
								/>
								<label htmlFor={EachContent.id_cadena}>
									<p>{EachContent.nombre_cadena}</p>
								</label>
							</li>
						))}
				</ul>
			</div>
		</>
	)
}
