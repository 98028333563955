import React, {useState, useEffect} from 'react'
import errorExplanation from '../../assets/img/error_explanation.svg'
import 'react-calendar/dist/Calendar.css'
import './calendar.css'
import Calendar from 'react-calendar'
export const CatalogoDropdownDate = ({valueState, activeState, setValue, id,
	setValueDropDownInit,setValueDropDownEnd, tipoPromocion
	, setActive
}) => {
	const [dropdown, setDropdown] = useState(false)
	const [value, onChange] = useState(new Date())
	const [dropdownValue, setdropdownValue] = useState('')
	const [response, setresponse] = useState(null)
	const [count, setCount] = useState(0)

	const getCorrectDate = (date) => {
		const correctDay = date.split('/')
		let correctMonth = ''
		switch (correctDay[1]) {
			case 'Jan':
				correctMonth = '01'
				break;
			case 'Feb':
				correctMonth = '02'
				break;
			case 'Mar':
				correctMonth = '03'
				break;
			case 'Apr':
				correctMonth = '04'
				break;
			case 'May':
				correctMonth = '05'
				break;
			case 'Jun':
				correctMonth = '06'
				break;
			case 'Jul':
				correctMonth = '07'
				break;
			case 'Aug':
				correctMonth = '08'
				break;
			case 'Sep':
				correctMonth = '09'
				break;
			case 'Oct':
				correctMonth = '10'
				break;
			case 'Nov':
				correctMonth = '11'
				break;
			case 'Dec':
				correctMonth = '12'
				break;
			default:
				break;
		}
		return `${correctDay[0]}/${correctMonth}/${correctDay[2]}`
	}

	useEffect(() => {
		if (count === 2) {
			if (dropdownValue === '') {
				setresponse('error')
			} else {
				setresponse('success')
			}
		}

		if (count < 2) {
			setCount(count + 1)
		}
	}, [dropdownValue, dropdown])

	useEffect(() => {
		if (activeState !== valueState && dropdown === true) {
			setDropdown(false)
		}
	}, [activeState])

	// const tileDisabled = ({ view }) => view !== 'month'; // Desactiva los días en las vistas de año y década

	return (
		<div className='CatálogoDropdown CatálogoDropdownDate CatálogoDropdownDateNew calendar-container'>
			<Calendar

				// showNavigation={true}
				// showNeighboringMonth={false}
				minDetail="day"
				maxDetail="month"
				onChange={(e) => {
					onChange(e)

					setDropdown(false)

					const FirstStart = String(e[0])
					const SecondStart = String(e[1])

					let FirstcorrectDay = `${FirstStart.split(' ')[2]}/${
						FirstStart.split(' ')[1]
					}/${FirstStart.split(' ')[3]}`

					let SecondcorrectDay = `${SecondStart.split(' ')[2]}/${
						SecondStart.split(' ')[1]
					}/${SecondStart.split(' ')[3]}`

					FirstcorrectDay = getCorrectDate(FirstcorrectDay)
					SecondcorrectDay = getCorrectDate(SecondcorrectDay)
					setdropdownValue(`${FirstcorrectDay} ${SecondcorrectDay}`)
                    setValue(`${FirstcorrectDay} ${SecondcorrectDay}`)
					document.getElementById('inicio_'+id).value = FirstcorrectDay
					document.getElementById('termino_'+id).value = SecondcorrectDay

					const date1 = new Date(FirstcorrectDay.split('/').reverse().join('/'));
					const date2 = new Date(SecondcorrectDay.split('/').reverse().join('/'));
					if (date1 > date2) {
						alert(`${FirstcorrectDay} es mayor que ${SecondcorrectDay}`);
					}
					setValueDropDownInit(FirstcorrectDay)
					setValueDropDownEnd(SecondcorrectDay)
					// document.getElementById('inicio_'+id).value = FirstcorrectDay;
					// document.getElementById('termino_'+id).value = SecondcorrectDay;
					const tipoPromocionField = id.split("_")[2];
					const idRest = id.split("_")[0]+"_"+id.split("_")[1];
					if(tipoPromocion === 'SELL-IN'){
						const initSellout = document.getElementById('inicio_'+idRest+'_sellout').value;
						const endSellout = document.getElementById('termino_'+idRest+'_sellout').value;
						const initSellin = document.getElementById('inicio_'+idRest+'_sellin').value;
						const endSellin = document.getElementById('termino_'+idRest+'_sellin').value;
						const date1 = new Date(initSellin.split('/').reverse().join('/'));
						const date2 = new Date(endSellin.split('/').reverse().join('/'));
						const date3 = new Date(initSellout.split('/').reverse().join('/'));
						const date4 = new Date(endSellout.split('/').reverse().join('/'));
						if (date3 < date1) {
							alert(`Fecha de inicio Sell-in debe ser anterior o igual a fecha de inicio Sell-out`);
						}
						if (date4 > date2) {
							alert(`Fecha de término sell-in debe ser posterior o igual a fecha de término sell-out`);
						}
					}
					/*
					if (tipoPromocion === "sellout") {
						console.log("pass");
						/*
						const initSellin = document.getElementById('inicio_'+idRest+'_sellin').value;
						const endSellin = document.getElementById('termino_'+idRest+'_sellin').value;
						const date3 = new Date(initSellin.split('/').reverse().join('/'));
						const date4 = new Date(endSellin.split('/').reverse().join('/'));
						if (date3 > date1) {
							alert(`Fecha de inicio Sell-in debe ser anterior o igual a fecha de inicio Sell-out`);
						}
						if (date4 < date2) {
							alert(`Fecha de término sell-in debe ser posterior o igual a fecha de término sell-out`);
						}
						*/ /*
					}else{
						const initSellout = FirstcorrectDay;
						const endSellout = SecondcorrectDay;
						const initSellin = document.getElementById('inicio_'+idRest+'_sellin').value;
						const endSellin = document.getElementById('termino_'+idRest+'_sellin').value;
						const date1 = new Date(initSellin.split('/').reverse().join('/'));
						const date2 = new Date(endSellin.split('/').reverse().join('/'));
						const date3 = new Date(initSellout.split('/').reverse().join('/'));
						const date4 = new Date(endSellout.split('/').reverse().join('/'));
						if (date3 < date1) {
							alert(`Fecha de inicio Sell-in debe ser anterior o igual a fecha de inicio Sell-out`);
						}
						if (date4 > date2) {
							alert(`Fecha de término sell-in debe ser posterior o igual a fecha de término sell-out`);
						}
					}
					*/
					setActive(false)
				}}
				// value={value}
				selectRange={true}
				formatShortWeekday={(locale, value) =>
					['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'][value.getDay()]
				}
			/>
		</div>
	)
}
