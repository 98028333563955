import '../../assets/css/chat.css'
import logoChatBot from '../../assets/img/logoChatBot.png'


export const ChatBot = () => {

	return (
		<>
		        <div>
					<img src={logoChatBot} alt="Chat" className="chat-icon" 
					onClick={(e) => {
						window.location.pathname = '/PromoGPT';
					}}
					/>
				</div>
		</>
	)
}
