import './AlertError.css'
import PropTypes from 'prop-types'
import AlertErrorSvg from '../../assets/img/AlertError'

const AlertError = () => {
	return (
		<div className={` error-details`}>
			<AlertErrorSvg />

			<p>Los datos ingresados son incorrectos</p>
		</div>
	)
}

AlertError.propTypes = {}

export default AlertError
