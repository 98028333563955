import React, {useState} from 'react'
import Table from 'react-bootstrap/Table'
import Flecha from '../assets/img/Flecha.svg'
import Off from '../assets/img/Off.svg'
import orangeDown from '../assets/img/orange_down.svg'
import filterNew from '../assets/img/filter_new.svg'
import dropdown from '../assets/img/dropdown.svg'
import forwardArrow from '../assets/img/forwardArrow.svg'
import {PromotionalVariableNew} from './PromotionalVariableNew'
import {VienesasRowGrayNew} from './VienesasRowGrayNew'
import {VienesasRowActiveNew} from './VienesasRowActiveNew'
export const VienesasTableNew = ({setCodigoPop}) => {
	const [FirstFilter, setFirstFilter] = useState('Todas')
	const [SecondFilter, setSecondFilter] = useState('Marca')
	const [SecondDropdown, setSecondDropdown] = useState(false)
	const [FirstDropdown, setFirstDropdown] = useState(false)
	const [PromotionalVariableState, setPromotionalVariable] = useState(false)

	return (
		<div className='CataLogoResumenTable VienesasTable'>
			{PromotionalVariableState && (
				<PromotionalVariableNew
					setPromotionalVariable={setPromotionalVariable}
				/>
			)}

			<Table>
				<thead>
					<tr>
						<th className='fimila fimiladropdown ' rowSpan={2}>
							<div className='dropdown dropdown_new'>
								<div
									className='head'
									onClick={(e) => {
										setFirstDropdown(!FirstDropdown)
									}}
								>
									<img src={filterNew} alt='' />
									<p>{FirstFilter}</p>
									<img src={dropdown} alt='' />
								</div>
								{FirstDropdown && (
									<ul>
										<li>
											<input type='radio' name='second_filter' id='Todas1' />
											<label
												htmlFor='Todas1'
												onClick={(e) => {
													setFirstFilter('Todas')
												}}
											></label>
											<p>Todas</p>
										</li>
										<li>
											<input type='radio' name='second_filter' id='Veggie1' />
											<label
												htmlFor='Veggie1'
												onClick={(e) => {
													setFirstFilter('Mr. Veggie')
												}}
											></label>
											<p>Mr. Veggie </p>
										</li>

										<li>
											<input type='radio' name='second_filter' id='Receta1' />
											<label
												htmlFor='Receta1'
												onClick={(e) => {
													setFirstFilter('Receta ...')
												}}
											></label>
											<p>
												Receta Del Abuelo <span>(3)</span>
											</p>
										</li>
										<li>
											<input type='radio' name='second_filter' id='PF1' />
											<label
												htmlFor='PF1'
												onClick={(e) => {
													setFirstFilter('PF')
												}}
											></label>
											<p>PF</p>
										</li>
									</ul>
								)}
							</div>
						</th>
						<th className='fimila border_head_gray fimiladropdown' rowSpan={2}>
							<div
								className='dropdown dropdown_new'
								style={{paddingLeft: 5, paddingRight: 5}}
							>
								<div
									className='head'
									onClick={(e) => {
										setSecondDropdown(!SecondDropdown)
									}}
								>
									<img src={filterNew} alt='' />
									<p>{SecondFilter}</p>
									<img src={dropdown} alt='' />
								</div>
								{SecondDropdown && (
									<ul>
										<li>
											<input type='radio' name='second_filter' id='Todas' />
											<label
												htmlFor='Todas'
												onClick={(e) => {
													setSecondFilter('Todas')
												}}
											></label>
											<p>Todas</p>
										</li>
										<li>
											<input type='radio' name='second_filter' id='Veggie' />
											<label
												htmlFor='Veggie'
												onClick={(e) => {
													setSecondFilter('Mr. Veggie')
												}}
											></label>
											<p>Mr. Veggie </p>
										</li>
										<li>
											<input type='radio' name='second_filter' id='Marca' />
											<label
												htmlFor='Marca'
												onClick={(e) => {
													setSecondFilter('Marca')
												}}
											></label>
											<p>Marca</p>
										</li>
										<li>
											<input type='radio' name='second_filter' id='Receta' />
											<label
												htmlFor='Receta'
												onClick={(e) => {
													setSecondFilter('Receta ...')
												}}
											></label>
											<p>
												Receta Del Abuelo <span>(3)</span>{' '}
											</p>
										</li>
										<li>
											<input type='radio' name='second_filter' id='PF' />
											<label
												htmlFor='PF'
												onClick={(e) => {
													setSecondFilter('PF')
												}}
											></label>
											<p>PF</p>
										</li>
										<li className='disabled'>
											<input
												type='radio'
												name='second_filter'
												id='Descartadas'
											/>
											<label htmlFor='Descartadas'></label>
											<p>
												Descartadas <span>(0)</span>
											</p>
										</li>
									</ul>
								)}
							</div>
						</th>
						{/*
            <th className="fimila border_head_gray" rowSpan={2}>
              <div className="dropdown">
                <div className="head">
                  <img src={filterNew} alt="" />
                  <p>Todas</p>
                  <img src={dropdown} alt="" />
                </div>
              </div>
            </th> */}
						<th colSpan={3} className='border_head_gray'>
							<div className='display_flex orange'>
								<img src={orangeDown} alt='' className='mr-5' />
								<p>Volumen</p>
							</div>
						</th>
						<th colSpan={3} className='border_head_gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>Precio</p>
							</div>
						</th>
						<th colSpan={3} className='border_head_gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>Resultado MM</p>
							</div>
						</th>

						<th colSpan={3} className='border_head_gray'>
							<div className='display_flex blue'>
								<p>Beneficio</p>
							</div>
						</th>

						<th>
							<div className='display_flex blue'>
								<p>Mg.Cad.</p>
							</div>
						</th>
					</tr>

					<tr className='bottom_row_head'>
						<th>
							<div className='head_box'>
								<h1>Act.</h1>
								<p>63,0 Ton.</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Opt.</h1>
								<p>62,5 Ton.</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Prop.</h1>
								<p>62,4 Ton.</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Act.</h1>
								<p>$1.782</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Opt</h1>

								<p>$1.766</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Prop.</h1>
								<p>$1.822</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Act.</h1>
								<p className='orange'>$-5,2</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Opt</h1>
								<p className='orange'>$-6,0</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Prop.</h1>
								<p className='orange'>$-2,5</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Prop. - Act.</h1>
								<p>$2.698.969</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Prop. - Opt.</h1>
								<p>$3.494.070</p>
							</div>
						</th>
						<th></th>
						<th style={{width: '80px'}}>
							<div
								className='head_box wrap_input'
								style={{transform: 'translateX(5px)'}}
							>
								<input type='text' value='24,0 %' />
							</div>
						</th>
					</tr>
				</thead>
			</Table>
			<Table>
				<thead style={{background: '#E8E8E8', height: 30}}>
					<tr>
						<td>
							<div className='display_flex gray'>
								<p>Rec.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Código</p>
								{/* <img src={Off} alt="" /> */}
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Descripción</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>SubFamilia</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>Marca</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>Ofic.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p> Actv.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>Vol. Act.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Vol. Opt.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Vol. Prop.</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>V. Prop.+OI</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$ Act.</p>
								<img src={Off} alt='' />
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>$ Opt.</p>
								<img src={Off} alt='' />
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>Prop.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>$ Opt/Act.</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>Mg. Act.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Mg. Opt.</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>Mg. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>RO Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Uplift</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>RO Incr.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>PVP Sug.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p> Var. Pro.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p> OI</p>
							</div>
						</td>
					</tr>
				</thead>
				<tbody className='img_saperate_wrapper'>
					<tr
						style={{height: 20}}
						id='active_row_button_table'
						onClick={(e) => {
							if (
								document
									.querySelector('#active_row_button_table')
									.classList.contains('rotate_img')
							) {
								document
									.querySelector('#active_row_button_table')
									.classList.remove('rotate_img')
							} else {
								document
									.querySelector('#active_row_button_table')
									.classList.add('rotate_img')
							}
							document
								.querySelectorAll('.VienesasRowActive')
								.forEach((Each) => {
									if (Each.classList.contains('active')) {
										Each.classList.remove('active')
									} else {
										Each.classList.add('active')
									}
								})
						}}
					>
						<td colSpan={24}>
							<div
								className='display_flex familiap  gray blue_bg special_p'
								style={{justifyContent: 'flex-start', paddingLeft: 20}}
							>
								<p>Productos con Activación</p>
								<div
									className='display_flex  center_justify gray blue_bg special_p'
									style={{justifyContent: 'flex-start', paddingLeft: 25}}
								>
									<img src={forwardArrow} alt='' />
								</div>
							</div>
						</td>
					</tr>
					<VienesasRowActiveNew
						setCodigoPop={setCodigoPop}
						setPromotionalVariable={setPromotionalVariable}
						name='V2000'
					/>
					<VienesasRowActiveNew
						setCodigoPop={setCodigoPop}
						setPromotionalVariable={setPromotionalVariable}
						name='CVR1'
					/>
					<VienesasRowActiveNew
						setCodigoPop={setCodigoPop}
						setPromotionalVariable={setPromotionalVariable}
						name='6J000'
					/>
					<tr
						style={{height: 20}}
						id='not_active_row_button_table'
						onClick={(e) => {
							if (
								document
									.querySelector('#active_row_button_table')
									.classList.contains('rotate_img')
							) {
								document
									.querySelector('#active_row_button_table')
									.classList.remove('rotate_img')
							} else {
								document
									.querySelector('#active_row_button_table')
									.classList.add('rotate_img')
							}
							document.querySelectorAll('.VienesasRowGray').forEach((Each) => {
								if (Each.classList.contains('active')) {
									Each.classList.remove('active')
								} else {
									Each.classList.add('active')
								}
							})
						}}
					>
						<td colSpan={24}>
							<div
								className='display_flex familiap   gray blue_bg special_p'
								style={{justifyContent: 'flex-start', paddingLeft: 20}}
							>
								<p>Productos sin Activación</p>
								<div
									className='display_flex  center_justify gray blue_bg special_p'
									style={{justifyContent: 'flex-start', paddingLeft: 25}}
								>
									<img src={forwardArrow} alt='' />
								</div>
							</div>
						</td>
						{/* <td colSpan={19}></td> */}
					</tr>
					<VienesasRowGrayNew
						setCodigoPop={setCodigoPop}
						name='6J000'
						setPromotionalVariable={setPromotionalVariable}
					/>
					<VienesasRowGrayNew
						setCodigoPop={setCodigoPop}
						name='6J000'
						setPromotionalVariable={setPromotionalVariable}
					/>
					<VienesasRowGrayNew
						setCodigoPop={setCodigoPop}
						name='6J000'
						setPromotionalVariable={setPromotionalVariable}
					/>
					<VienesasRowGrayNew
						setCodigoPop={setCodigoPop}
						name='6J000'
						setPromotionalVariable={setPromotionalVariable}
					/>
				</tbody>
			</Table>
		</div>
	)
}
