import iconoCata from '../assets/img/IconoColor.svg'
export const CatalogSaved = ({setActive}) => {
	return (
		<div
			className='popupWrapper'
			id='popupCatalog'
			style={{position: 'fixed', zIndex: 9999}}
			onClick={(e) => {
				if (e.target.id === 'popupCatalog') {
					setActive(false)
				}
			}}
		>
			<div className='popup SaveCatalog'>
				<img src={iconoCata} alt='' />
				<h1>El Catálogo se ha guardado exitosamente</h1>
				<p>
					Puedes seguir editandolo o volver al inicio de{' '}
					<span>PROMOBOOSTER</span>
				</p>
				<div className='buttons_wrapper'>
					<button
						onClick={(e) => {
							setActive(false)
						}}
					>
						Volver al Inicio
					</button>
					<button
						onClick={(e) => {
							setActive(false)
						}}
					>
						Seguir Editando
					</button>
				</div>
			</div>
		</div>
	)
}
