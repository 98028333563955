import React from 'react'

export const InicioPop3 = ({setInicioPopState}) => {
	return (
		<>
			<span
				className='black_bg popup'
				onClick={(e) => {
					setInicioPopState(null)
				}}
			></span>
			<div className='LogoutPopupNew InicioPop InicioPop3'>
				<svg
					width='44'
					height='44'
					viewBox='0 0 44 44'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect width='44' height='44' rx='22' fill='#F0F4FC' />
					<mask
						id='mask0_311_429811'
						maskUnits='userSpaceOnUse'
						x='6'
						y='6'
						width='32'
						height='32'
					>
						<rect x='6' y='6' width='32' height='32' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_311_429811)'>
						<path
							d='M12.7658 34.2667C11.9213 34.2667 11.2049 33.9725 10.6164 33.3841C10.0271 32.7947 9.73242 32.0778 9.73242 31.2334V12.7667C9.73242 11.9223 10.0271 11.2054 10.6164 10.6161C11.2049 10.0276 11.9213 9.7334 12.7658 9.7334H27.5324C27.9324 9.7334 28.316 9.81118 28.6831 9.96673C29.0493 10.1223 29.3769 10.3334 29.6658 10.6001L33.3991 14.3334C33.6658 14.6223 33.8769 14.9498 34.0324 15.3161C34.188 15.6832 34.2658 16.0667 34.2658 16.4667V31.2334C34.2658 32.0778 33.9715 32.7947 33.3831 33.3841C32.7938 33.9725 32.0769 34.2667 31.2324 34.2667H12.7658ZM31.2324 16.5001L27.4991 12.7667H12.7658V31.2334H31.2324V16.5001ZM21.9991 29.9001C23.1102 29.9001 24.0546 29.5112 24.8324 28.7334C25.6102 27.9556 25.9991 27.0112 25.9991 25.9001C25.9991 24.789 25.6102 23.8445 24.8324 23.0667C24.0546 22.289 23.1102 21.9001 21.9991 21.9001C20.888 21.9001 19.9435 22.289 19.1658 23.0667C18.388 23.8445 17.9991 24.789 17.9991 25.9001C17.9991 27.0112 18.388 27.9556 19.1658 28.7334C19.9435 29.5112 20.888 29.9001 21.9991 29.9001ZM15.5991 19.4334H24.5658C25.0102 19.4334 25.3769 19.289 25.6658 19.0001C25.9546 18.7112 26.0991 18.3445 26.0991 17.9001V15.6001C26.0991 15.1778 25.9546 14.8223 25.6658 14.5334C25.3769 14.2445 25.0102 14.1001 24.5658 14.1001H15.5991C15.1769 14.1001 14.8213 14.2445 14.5324 14.5334C14.2435 14.8223 14.0991 15.1778 14.0991 15.6001V17.9001C14.0991 18.3445 14.2435 18.7112 14.5324 19.0001C14.8213 19.289 15.1769 19.4334 15.5991 19.4334Z'
							fill='#3676F5'
						/>
					</g>
				</svg>

				<h1>Catálogo Guardado</h1>
				<p>
					El Catálogo ha sido guardado exitosamente. Ya puedes volver al inicio.
				</p>
				<div className='button_wrapper'>
					<button
						onClick={(e) => {
							setInicioPopState(null)
						}}
					>
						Ir al Inicio
					</button>
					<button style={{display: 'none'}} className='canceler'>
						Ir al Inicio
					</button>
				</div>

				<svg
					onClick={(e) => setInicioPopState(false)}
					width='10'
					height='10'
					viewBox='0 0 10 10'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M4.99994 6.06666L1.79993 9.26666C1.65549 9.41111 1.47771 9.48333 1.2666 9.48333C1.05549 9.48333 0.877713 9.41111 0.733268 9.26666C0.588824 9.12222 0.516602 8.94444 0.516602 8.73333C0.516602 8.52222 0.588824 8.34444 0.733268 8.2L3.93327 5L0.733268 1.8C0.588824 1.65555 0.516602 1.47777 0.516602 1.26666C0.516602 1.05555 0.588824 0.877774 0.733268 0.733329C0.877713 0.588885 1.05549 0.516663 1.2666 0.516663C1.47771 0.516663 1.65549 0.588885 1.79993 0.733329L4.99994 3.93333L8.19993 0.733329C8.34438 0.588885 8.52216 0.516663 8.73327 0.516663C8.94438 0.516663 9.12216 0.588885 9.2666 0.733329C9.41105 0.877774 9.48327 1.05555 9.48327 1.26666C9.48327 1.47777 9.41105 1.65555 9.2666 1.8L6.0666 5L9.2666 8.2C9.41105 8.34444 9.48327 8.52222 9.48327 8.73333C9.48327 8.94444 9.41105 9.12222 9.2666 9.26666C9.12216 9.41111 8.94438 9.48333 8.73327 9.48333C8.52216 9.48333 8.34438 9.41111 8.19993 9.26666L4.99994 6.06666Z'
						fill='#ADADAD'
					/>
				</svg>
			</div>
		</>
	)
}
