import {useState} from 'react'
import {ToolTipCustom} from './ToolTipCustom'

import Recomendacion2 from '../assets/img/Recomendacion2.svg'
import Si from '../assets/img/Si.svg'
import orangeDown from '../assets/img/orange_down.svg'
import arrowLightBlue from '../assets/img/arrow_light_blue.svg'
import OIAct from '../assets/img/OI-Act.svg'

export const VienesasRowActiveNew = ({
	setCodigoPop,
	setPromotionalVariable,
	name,
}) => {
	const [MouseDownState, setMouseDownState] = useState(null)
	return (
		<tr className='VienesasRowActive'>
			<td>
				<div className='display_flex gray '>
					<img src={Si} alt='' />
					<img src={Recomendacion2} alt='' />
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<a
						href='#'
						onClick={(e) => {
							e.preventDefault()
							setCodigoPop(true)
						}}
					>
						{name}
					</a>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>
						Salchichas Sureñas <br /> PF (6x1Kg)
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>Sub Fam.</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>Marca</p>
					<img src={arrowLightBlue} alt='' />
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>15 Of.</p>
				</div>
			</td>
			<td>
				<div className='display_flex   green'>
					<p style={{color: '#36A7F5'}}>12/15</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>23,7 Ton.</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p style={{color: '#13ACAA'}}>23,7 Ton.</p>
				</div>
			</td>

			<td>
				<div className='display_flex gray'>
					<p>23,7 Ton.</p>
				</div>
			</td>

			<td>
				<div className='display_flex gray'>
					<p>23,7 Ton.</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p style={{color: '#13ACAA'}}> $1.766</p>
				</div>
			</td>

			<td>
				<div
					className='display_flex  center_justify gray wrap_input cursor_row_active'
					onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')

						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						if (e.button !== 0) {
							return false
						}
						const MouseDownTimeOut = setTimeout(() => {
							e.target.querySelector('.ToolTipCustom').classList.add('show')
						}, [500])

						setMouseDownState(MouseDownTimeOut)
					}}
				>
					<ToolTipCustom value='$1.240' />

					<input type='text' value='$ 1.766' readOnly />
				</div>
			</td>
			<td>
				<div className='display_flex  center_justify gray wrap_input'>
					<input type='text' value='% 0,0' />
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>-0,0%</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>$1.766</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>$1.766</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>$1.766</p>
				</div>
			</td>
			<td>
				<div
					className='display_flex orange cursor_row_active'
					onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')

						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						if (e.button !== 0) {
							return false
						}
						const MouseDownTimeOut = setTimeout(() => {
							e.target.querySelector('.ToolTipCustom').classList.add('show')
						}, [500])

						setMouseDownState(MouseDownTimeOut)
					}}
				>
					<ToolTipCustom value='$1.240' />
					<img src={orangeDown} alt='' className='mr-5' />
					<p>4,9%</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>11,0 Ton.</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>$2,5</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>$2.766</p>
				</div>
			</td>
			<td>
				<div
					className='display_flex editable_icon  gray'
					onClick={(e) => {
						setPromotionalVariable(true)
					}}
				>
					<p>0/6</p>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 24 24'
						fill='currentColor'
						className='w-6 h-6'
					>
						<path d='M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z' />
					</svg>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<img src={OIAct} alt='' />
				</div>
			</td>
		</tr>
	)
}
