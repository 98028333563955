import React from 'react'
import bookmark from '../assets/img/bookmark.svg'
import edit from '../assets/img/edit.svg'
import calender from '../assets/img/calender.svg'
import shop from '../assets/img/shop.svg'
import Jumbo from '../assets/img/Jumbo.svg'
import Cerrar from '../assets/img/Cerrar.svg'
import errorExplanation from '../assets/img/error_explanation.svg'

export const Oferta = ({setActive}) => {
	return (
		<div className='popupWrapper OfertaWrapper'>
			<div className='popup'>
				<div className='close_icon' onClick={(e) => setActive(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<h1>
					<img src={bookmark} alt='' />
					<span>
						<span>PROMO</span>BOOSTER:
					</span>
					Oferta
				</h1>
				<div className='oferta_buttons'>
					<button>Crear Oferta nueva</button>
					<button>Crear a partir de Oferta existente</button>
				</div>

				<div className='box_border'>
					<div className='input_wrapper'>
						<label htmlFor=''>
							<img src={edit} alt='' />
							Nombre de Oferta
						</label>
						<input
							type='text'
							placeholder='Nombre de Oferta'
							maxLength={25}
							onBlur={(e) => {
								if (e.target.value !== '') {
									e.target.classList.remove('error_oferto')
									e.target.classList.add('success_oferto')
								} else {
									e.target.classList.add('error_oferto')
									e.target.classList.remove('success_oferto')
								}
							}}
						/>
						<img src={errorExplanation} alt='' />
					</div>
				</div>

				<div className='box_border' style={{marginTop: 20}}>
					<div className='input_wrapper'>
						<label htmlFor=''>
							<img src={calender} alt='' />
							Fechas
						</label>
					</div>

					<div className='input_container'>
						<div className='input_wrapper'>
							<label htmlFor=''>Sell-In</label>
							<input type='text' placeholder='01/04/2022 al 31/04/2022' />
						</div>

						<div className='input_wrapper'>
							<label htmlFor=''>Sell-Out</label>
							<input type='text' placeholder='01/04/2022 al 31/04/2022' />
						</div>

						<div className='input_wrapper'>
							<label htmlFor=''>Mes</label>
							<input type='text' placeholder='Selecciona un mes' />
						</div>
					</div>
				</div>

				<div className='box_border' style={{marginTop: 20}}>
					<div className='input_container'>
						<div className='input_wrapper'>
							<label htmlFor=''>
								<img src={shop} alt='' />
								Cadena
							</label>
							<input type='text' placeholder='Nombre de Oferta' />
						</div>

						<div className='right_range'>
							<div className='left_side'>
								<p>ROI</p>
								<div className='value_wrapper'>
									<p>0</p>
									<p>0</p>
								</div>
							</div>
							<div className='right_side'>
								<p>Volumen</p>
								<div className='value_wrapper'>
									<p>0</p>
									<p>0</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='badge_long'>
					<div className='box_inner'>
						<h1>Canal</h1>
						<p>Moderno</p>
					</div>
					<div className='box_inner'>
						<h1>Sell-In</h1>
						<p>01/04/22 al 31/04/22</p>
					</div>
					<div className='box_inner'>
						<h1>Sell-Out</h1>
						<p>01/04/22 al 31/04/22</p>
					</div>
					<div className='box_inner'>
						<h1>Cadena</h1>
						<img src={Jumbo} alt='' />
					</div>
				</div>

				<div className='bottom_buttton'>
					<button>Volver atrás</button>
					<button>Continuar</button>
				</div>
			</div>
		</div>
	)
}
