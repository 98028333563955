import React from 'react'
import Table from 'react-bootstrap/Table'
import Jumbo from '../assets/img/promorec.svg'
export const NombreTableNew = () => {
	return (
		<div className='NombreTable NombreTableNew'>
			<Table>
				<tbody>
					<tr>
						<td>
							<p>Nombre</p>
							<h1>Jumbo Abril 2022</h1>
						</td>
						<td>
							<p>Cadena</p>
							<img src={Jumbo} alt='' />
						</td>
						<td>
							<p>Fecha</p>
							<h1>Abril 2022</h1>
						</td>
						<td>
							<p>Fase</p>
							<h1 className='badge_green'>PROPUESTA INICIAL</h1>
						</td>
						<td>
							<p>Estado</p>
							<h1 className='badge_blue'>EN EDICIÓN</h1>
						</td>
					</tr>
				</tbody>
			</Table>

			<Table>
				<tbody>
					<td>
						<p>Sell-In</p>

						<p>
							In. <b>01/01/2022</b> <br />
							Te. <b>01/02/2022</b>
						</p>
					</td>
					<td>
						<p>Sell-Out</p>

						<p>
							In. <b>01/01/2022</b> <br />
							Te. <b>01/02/2022</b>
						</p>
					</td>
					<td>
						<p>Número SKU</p>

						<p>
							<b>87</b> (89)
						</p>
					</td>

					<td style={{opacity: 0}}>
						<p>Número Act.</p>

						<p>
							<b>87</b> (89)
						</p>
					</td>

					<td style={{opacity: 0}}>
						<p>Volumen Act.</p>

						<p>
							<b>87</b> (89)
						</p>
					</td>
				</tbody>
			</Table>
		</div>
	)
}
