import React, {useEffect, useState} from 'react'

import blackGreenFull from '../assets/img/Full_Negro+Verde.svg'
import Full from '../assets/img/Full.svg'
import Demand from '../assets/img/Demand.svg'
import questionMark from '../assets/img/questionmark.svg'
import '../assets/css/productSelection.css'
import {ProductSelectionCard} from './ProductSelectionCard'
import {useNavigate} from 'react-router-dom'
import {AnimationLoaderCircle} from '../componentsRef'
export const ProductSelection = () => {
	const [activeState, setactiveState] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		setTimeout(() => {
			setactiveState(true)
		}, 400)
	}, [])

	return (
		<div className='ProductSelectionContainer'>
			<AnimationLoaderCircle />

			<div className={`ProductSelectionWrapper ${activeState && 'active'}`}>
				<div className='cards_wrapper'>
					<ProductSelectionCard
						img={blackGreenFull}
						heading='Optimización de Precios'
						para='Maecenas ligula erat, finibus bibendum commodo et, hendrerit ultrices dolor. Mauris metus arcu, mattis non quam sed, tristique rhoncus lacus. Donec tincidunt ligula in ornare vulputate.'
						button={{
							text: 'MAKER',
							span: 'PRICE',
						}}
					/>
					<ProductSelectionCard
						img={Full}
						heading='Optimización de Promociones'
						para='Maecenas ligula erat, finibus bibendum commodo et, hendrerit ultrices dolor. Mauris metus arcu, mattis non quam sed, tristique rhoncus lacus. Donec tincidunt ligula in ornare vulputate.'
						button={{
							text: 'BOOSTER',
							span: 'PROMO',
						}}
					/>
					<ProductSelectionCard
						img={Demand}
						heading='Predicción de Demanda'
						para='Maecenas ligula erat, finibus bibendum commodo et, hendrerit ultrices dolor. Mauris metus arcu, mattis non quam sed, tristique rhoncus lacus. Donec tincidunt ligula in ornare vulputate.'
						button={{
							text: 'CONNECT',
							span: 'DEMAND',
						}}
					/>
				</div>

				{/* <div className="long_card">
          <img src={question_mark} alt="" />
          <div className="presentation">
            <h1>¿Necesitas Ayuda?</h1>
            <p>
              Ingresa a nuestro sistema de Soporte de Tickets y te contactaremos
              a la brevedad.
            </p>
          </div>
          <button>Ir al Soporte</button>
        </div> */}

				<button
					className='go_through_button'
					onClick={(e) => {
						navigate('/LoaderNew')
					}}
				>
					Cerrar Sesión
					<svg
						width='16'
						height='16'
						viewBox='0 0 16 16'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<mask
							id='mask0_459_152934'
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='16'
							height='16'
						>
							<rect width='16' height='16' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_459_152934)'>
							<path
								d='M6.02917 10.9833C5.88472 10.8166 5.8125 10.6304 5.8125 10.4246C5.8125 10.2193 5.88472 10.0444 6.02917 9.89997L7.1625 8.76664H2.5625C2.35139 8.76664 2.17361 8.69153 2.02917 8.5413C1.88472 8.39153 1.8125 8.21108 1.8125 7.99997C1.8125 7.78886 1.88472 7.60842 2.02917 7.45864C2.17361 7.30842 2.35139 7.2333 2.5625 7.2333H7.1625L6.02917 6.09997C5.87361 5.94442 5.79583 5.76375 5.79583 5.55797C5.79583 5.35264 5.87361 5.17219 6.02917 5.01664C6.1625 4.87219 6.33472 4.79997 6.54583 4.79997C6.75694 4.79997 6.93472 4.87219 7.07917 5.01664L9.52917 7.46664C9.60694 7.54442 9.6625 7.62775 9.69583 7.71664C9.72917 7.80553 9.74583 7.89997 9.74583 7.99997C9.74583 8.09997 9.72917 8.19442 9.69583 8.2833C9.6625 8.37219 9.60694 8.45553 9.52917 8.5333L7.0625 11C6.91806 11.1444 6.74294 11.2111 6.53717 11.2C6.33183 11.1889 6.1625 11.1166 6.02917 10.9833ZM8.72917 14.1333C8.50694 14.1333 8.32361 14.0611 8.17917 13.9166C8.03472 13.7722 7.9625 13.5944 7.9625 13.3833C7.9625 13.1611 8.03472 12.9777 8.17917 12.8333C8.32361 12.6889 8.50694 12.6166 8.72917 12.6166H12.6625V3.3833H8.72917C8.50694 3.3833 8.32361 3.31108 8.17917 3.16664C8.03472 3.02219 7.9625 2.83886 7.9625 2.61664C7.9625 2.40553 8.03472 2.22775 8.17917 2.0833C8.32361 1.93886 8.50694 1.86664 8.72917 1.86664H12.6625C13.0847 1.86664 13.4432 2.01375 13.7378 2.30797C14.0321 2.60264 14.1792 2.96108 14.1792 3.3833V12.6166C14.1792 13.0389 14.0321 13.3973 13.7378 13.692C13.4432 13.9862 13.0847 14.1333 12.6625 14.1333H8.72917Z'
								fill='#949494'
							/>
						</g>
					</svg>
				</button>
			</div>
		</div>
	)
}
