import {useState, useEffect} from 'react'
import bookmark from '../assets/img/bookmark.svg'
import AvatarPop from '../assets/img/AvatarPop.svg'
import Actividad from '../assets/img/Actividad.svg'
import Badge from 'react-bootstrap/Badge'
import Resumen from '../assets/img/Icono Resumen.svg'
import Cerrar from '../assets/img/Cerrar.svg'
import Avatargirl from '../assets/img/Avatargirl.svg'
import {EliminarShow} from './EliminarShow'
import {Mover} from './Mover'
export const JumboPopNew = ({setActive, Activityactive}) => {
	const [color, setColor] = useState('')
	const [eliminarShow, seteliminarShow] = useState(false)
	const [moverShow, setmoverShow] = useState(false)
	useEffect(() => {
		let Percentage = Activityactive.percentage.split('%')[0]
		Percentage = Number(Percentage)

		console.log(Percentage)
		console.log(Activityactive.percentage)
		if (Percentage < 25) {
			setColor('rgb(255, 83, 52)')
		} else if (Percentage < 50) {
			setColor('rgb(255, 172, 36)')
		} else {
			setColor('#36a7f5')
		}
	}, [])

	return (
		<div className='popupWrapper OfertaWrapper TottusPop TottusPopNew'>
			<div className='popup'>
				{eliminarShow && <EliminarShow seteliminarShow={seteliminarShow} />}
				{moverShow && <Mover setmoverShow={setmoverShow} />}

				<div className='close_icon' onClick={(e) => setActive(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<div className='left_side'>
					<h1>
						<img src={bookmark} alt='' />
						Nombre de Tarjeta
					</h1>
					<div className='first_left'>
						<div className='left_box'>
							<h1>Fase</h1>
							<p>Propuesta Inicial</p>
						</div>
						<div className='left_box'>
							<h1>Estado</h1>
							<p>En Edición</p>
						</div>
						<div className='left_box'>
							<h1>Cadena</h1>
							<img src={Activityactive.img} alt='' />
						</div>
					</div>
					<h1 style={{marginTop: 30}}>
						<img src={Resumen} alt='' />
						Resumen
					</h1>
					<div
						className='badge_long'
						style={{gridTemplateColumns: '.7fr .7fr 1fr 1fr .7fr'}}
					>
						<div className='box_inner'>
							<h1>Canal</h1>
							<p>Moderno</p>
						</div>
						<div className='box_inner'>
							<h1>Modalidad</h1>
							<p>Catálogo</p>
						</div>
						<div className='box_inner'>
							<h1>Sell-In</h1>
							<p>01/01/2022 01/02/2022</p>
						</div>
						<div className='box_inner'>
							<h1>Sell-Out</h1>
							<p>01/01/2022 01/02/2022</p>
						</div>
						<div className='box_inner'>
							<h1>Fecha</h1>
							<p>Abril 2022</p>
						</div>

						<div className='box_inner'>
							<h1>Beneficio Opt.</h1>
							<p>$12.131.464</p>
						</div>
						<div className='box_inner'>
							<h1>Beneficio Prop.</h1>
							<p>$160.302.951</p>
						</div>
						<div className='box_inner'>
							<h1>Res. Op. Opt.</h1>
							<p>$1.036.373.566</p>
						</div>
						<div className='box_inner'>
							<h1>Res. Op. Prop.</h1>
							<p>$888.202.079</p>
						</div>
						<div className='box_inner'>
							<h1>Actv.</h1>
							<p>
								<Badge className='badgeboot' style={{background: color}}>
									{Activityactive.percentage}
								</Badge>
							</p>
						</div>
					</div>

					<h1 style={{marginTop: 20}}>
						<img src={Actividad} alt='' />
						Actividad
					</h1>

					<div className='profile_box_wrapper'>
						<div className='profile_box'>
							<img src={Avatargirl} alt='' />
							<div className='presentation'>
								<input type='text' placeholder='Escribe un comentario...' />
							</div>
						</div>

						<div className='profile_box responder'>
							<img src={Avatargirl} alt='' />
							<div className='presentation'>
								<div className='top_area'>
									<h1>María M.</h1>
									<p>hace 10 mínutos</p>
								</div>
								<input type='text' placeholder='Saquemos el Jamón Ahumado' />
								<button>Responder</button>
							</div>
						</div>

						<div className='profile_box'>
							<img src={AvatarPop} alt='' />
							<div className='presentation'>
								<div className='top_area'>
									<h1>Juan Martínez</h1>
									<p>ha movido esta promoción al estado</p>
									<span>EN EDICIÓN</span>
								</div>
								<p>31 / 01 / 2022 a las 14:25</p>
							</div>
						</div>
						<div className='profile_box'>
							<img src={AvatarPop} alt='' />
							<div className='presentation'>
								<div className='top_area'>
									<h1>Pedro Perez</h1>
									<p>ha movido esta promoción al estado</p>
								</div>
								<p>31 / 01 / 2022 a las 14:25</p>
							</div>
						</div>
						<div className='profile_box'>
							<img src={AvatarPop} alt='' />
							<div className='presentation'>
								<div className='top_area'>
									<h1>María M.</h1>
									<p>ha movido esta promoción al estado</p>
								</div>
								<p>31 / 01 / 2022 a las 14:25</p>
							</div>
						</div>
					</div>
				</div>
				<div className='right_side'>
					<div className='buttons_right_side'>
						<h1>Promoción</h1>
						<button className='active'>Editar</button>
						<button className='active'>Visualizar</button>
					</div>
					<div className='buttons_right_side'>
						<h1>Fase y Estado</h1>
						<button
							className='active'
							onClick={(e) => {
								setmoverShow(true)
							}}
						>
							Mover
						</button>
					</div>
					<div className='buttons_right_side'>
						<h1>Acciones</h1>
						<button
							className='active'
							onClick={(e) => {
								seteliminarShow(true)
							}}
						>
							Eliminar
						</button>
						<button className='active'>Duplicar</button>
					</div>
				</div>
			</div>
		</div>
	)
}
