import PromoApi from '../utils/config/apiPromobooster'
import {envVariables} from '../utils/envVariables/envVariables'

export const signIn = async ({email, password}) => {
	return PromoApi.post(`${envVariables.baseUrl}/user/login`, {
		email,
		password,
	})
}

// Mock response

const response = {
	rol_and_permissions: {
		approve_promotions_and_changes: true,
		create_promotions: true,
		rol: 'Test User',
		update_promotions: true,
		view_promotions: true,
	},
	token:
		'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MTUsImVtYWlsIjoibWFyY29zdmlsbGFiYXNhQHByaWNlbWFrZXIuaW8iLCJyb2wiOjUsImV4cCI6MTY4MjAzNjE0OCwiaWF0IjoxNjgxOTkyOTQ4fQ.cPmY1ssL-fIyT544x7mAon3XH-9TDTjWLwpjyRiv4TI',
	user_data: {
		email: 'marcosvillabasa@pricemaker.io',
		id: 15,
		last_name: 'Villabasa',
		name_user: 'Marcos',
		notification: 1,
		photo_url:
			'https://media-exp1.licdn.com/dms/image/C5603AQGB5cga1NQM-A/profile-displayphoto-shrink_800_800/0/1654535617790?e=1666224000&v=beta&t=Fl3sVVqQY9KzCHuloy0V3H_VKYgqwEvPk7C65DdO6rg',
	},
}

export class SignInMock {
	async signIn() {
		return await Promise.resolve(response)
	}
}
