import React from 'react'

export const ToolTipCustomList = ({list}) => {
	return (
		<div className='ToolTipCustomList ToolTipCustom'>
			<ul>
				{list.map((EachList) => (
					<li key={EachList}>{EachList}</li>
				))}
			</ul>

			<svg
				width='17'
				height='8'
				viewBox='0 0 17 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<g clipPath='url(#clip0_2539_118367)'>
					<rect
						width='12'
						height='12'
						rx='2'
						transform='rotate(-45)'
						fill='#36A7F5'
					/>
				</g>
				<defs>
					<clipPath id='clip0_2539_118367'>
						<rect width='17' height='8' fill='white' />
					</clipPath>
				</defs>
			</svg>
		</div>
	)
}
