import {useState, useEffect} from 'react'

export const FilterHomeNew = ({catalogos, setFiltroCadena, id}) => {
	
	const [Head, setHead] = useState('Filtrar Catálogos')
	const [total, setTotal] = useState(null)
	const [Length, setLength] = useState(false)
	const [active, setActive] = useState(false)
	const cantidadPorNombreCadena = {};

	if(catalogos){
		

		catalogos.forEach((element) => {
			const nombreCadena = element.nombre_cadena;

			if (cantidadPorNombreCadena[nombreCadena]) {
				cantidadPorNombreCadena[nombreCadena]++;
			} else {
				cantidadPorNombreCadena[nombreCadena] = 1;
			}
		});

		// Mostrar la cantidad por nombre_cadena
		for (const nombreCadena in cantidadPorNombreCadena) {
			console.log(`Nombre Cadena: ${nombreCadena}, Cantidad: ${cantidadPorNombreCadena[nombreCadena]}`);
		}
	}	

	const handleDocumentClick = (event) => {
		const targetClass = event.target.classList;
		const targetId = event.target.id;
	  
		if (
		  !targetClass.contains(id) &&
		  targetId !== id &&
		  !event.target.closest(`.${id}`)
		) {
		  setActive(false);
		}
	  };

	  useEffect(() => {
		document.addEventListener('mousedown', handleDocumentClick);
		return () => {
		  document.removeEventListener('mousedown', handleDocumentClick);
		};
	  }, []);
	
	return (
		<div className={'FilterHomeNew '+id} >
			<div
				className={id+` head ${active && 'head_active'}`}
				onClick={(e) => {
					setActive(!active)
				}}
				id={id}
			>
				<svg
					width='16'
					height='17'
					viewBox='0 0 16 17'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask
						id='mask0_579_306889'
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='16'
						height='17'
					>
						<rect y='0.497437' width='16' height='16' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_579_306889)'>
						<path
							d='M7.33307 13.9641C7.11085 13.9641 6.92196 13.8863 6.76641 13.7308C6.61085 13.5752 6.53307 13.3863 6.53307 13.1641V9.2141L2.69974 4.3141C2.48863 4.04743 2.4553 3.76676 2.59974 3.4721C2.74418 3.17787 2.98863 3.03076 3.33307 3.03076H12.6664C13.0108 3.03076 13.2553 3.17787 13.3997 3.4721C13.5442 3.76676 13.5108 4.04743 13.2997 4.3141L9.46641 9.2141V13.2141C9.46641 13.4252 9.39418 13.603 9.24974 13.7474C9.10529 13.8919 8.92752 13.9641 8.71641 13.9641H7.33307ZM7.99974 8.6141L11.1997 4.54743H4.79974L7.99974 8.6141Z'
							fill='#3676F5'
						/>
					</g>
				</svg>

				<p style={Length ? {fontWeight: 600} : {}}>{Head}</p>
				
				
				<span>{total !== null ? total : catalogos && catalogos.length}</span>

				<svg
					width='12'
					height='13'
					viewBox='0 0 12 13'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask
						id='mask0_579_307008'
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='12'
						height='13'
					>
						<rect
							y='12.4974'
							width='12'
							height='12'
							transform='rotate(-90 0 12.4974)'
							fill='#D9D9D9'
						/>
					</mask>
					<g mask='url(#mask0_579_307008)'>
						<path
							d='M5.25004 3.94885L7.55004 6.24885C7.60837 6.30719 7.65004 6.36969 7.67504 6.43635C7.70004 6.50302 7.71254 6.57385 7.71254 6.64885C7.71254 6.72385 7.70004 6.79469 7.67504 6.86135C7.65004 6.92802 7.60837 6.99052 7.55004 7.04885L5.25004 9.34885C5.1417 9.45719 5.00837 9.51135 4.85004 9.51135C4.6917 9.51135 4.55837 9.45719 4.45004 9.34885C4.3417 9.24052 4.28754 9.10719 4.28754 8.94885C4.28754 8.79052 4.3417 8.65719 4.45004 8.54885L6.35004 6.64885L4.45004 4.74885C4.3417 4.64052 4.28754 4.50719 4.28754 4.34885C4.28754 4.19052 4.3417 4.05719 4.45004 3.94885C4.55837 3.84052 4.6917 3.78635 4.85004 3.78635C5.00837 3.78635 5.1417 3.84052 5.25004 3.94885Z'
							fill='#3676F5'
						/>
					</g>
				</svg>
			</div>
			{active && (
				<ul style={{backgroundColor: "#fff",
				height: 'auto',
				maxHeight: '450px',
				overflowX: 'hidden',
				overflowY: 'auto'}} className={id}>
					<li>Cadena</li>

					<li
					key={"todos_"+catalogos.length}
					onClick={(e) => {
						setHead('Filtrar Catálogos')
						setActive(false)
						setLength(true)
						setFiltroCadena(null)
						setTotal(null)
					}}
					className={id}
					><a href='#'>Todos <span>{catalogos.length}</span></a></li>
					{catalogos && Object.keys(cantidadPorNombreCadena)
						.sort((a, b) => a.localeCompare(b))
						.map((nombreCadena) => (
						<li
							key={nombreCadena}
							onClick={(e) => {
								setHead(nombreCadena)
								setActive(false)
								setLength(true)
								setFiltroCadena(nombreCadena.toLocaleUpperCase())
								setTotal(cantidadPorNombreCadena[nombreCadena])
							}}
						>
							<a href='#'>
								{nombreCadena}
								<span>{cantidadPorNombreCadena[nombreCadena]}</span>
							</a>
						</li>
					))}
				</ul>
			)}
		</div>
	)
}
