import React, {useState} from 'react'
import blueTick from '../../assets/img/tick-blue.svg'
import download from '../../assets/img/download-blue.svg'
import close from '../../assets/img/close-blue.svg'

export const ExportProductSidebar = () => {
	const [steps, setSteps] = useState(1)
	return (
		<div className='AddProductSdebar'>
			<div className='topbar'>
				<h1>Exportar</h1>
			</div>

			<div className='form-wrapper-area MoverProductSidebar ExportProductSidebar'>
				{steps === 1 && (
					<div className='first'>
						<h1>
							Selecciona el formato en el que deseas exportar <br />
							el Catálogo:
						</h1>
						<div className='box '>
							<input type='checkbox' name='' id='PDF' />
							<label htmlFor='PDF'>
								<svg
									width='11'
									height='8'
									viewBox='0 0 11 8'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M3.78918 8C3.68417 8 3.58499 7.98257 3.49165 7.94771C3.3983 7.91285 3.31079 7.85476 3.22912 7.77342L0.218775 4.7756C0.0670908 4.62455 -0.00571732 4.43561 0.00035004 4.2088C0.00595068 3.98245 0.0845929 3.79375 0.236277 3.6427C0.387961 3.49165 0.577682 3.41612 0.805441 3.41612C1.03273 3.41612 1.21639 3.49165 1.3564 3.6427L3.78918 6.06536L9.65235 0.226579C9.79236 0.0755264 9.97905 0 10.2124 0C10.4458 0 10.6383 0.0755264 10.79 0.226579C10.93 0.377632 11 0.566333 11 0.79268C11 1.01949 10.93 1.20842 10.79 1.35948L4.34924 7.77342C4.26757 7.85476 4.18006 7.91285 4.08671 7.94771C3.99337 7.98257 3.89419 8 3.78918 8Z'
										fill='white'
									/>
								</svg>
							</label>
							<div className='presentation'>
								<h1>PDF</h1>
								<p>Ideal para presentaciones. Este formato no es editable.</p>
							</div>
						</div>
						<div className='box'>
							<input type='checkbox' name='' id='CSV' />
							<label htmlFor='CSV'>
								<svg
									width='11'
									height='8'
									viewBox='0 0 11 8'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M3.78918 8C3.68417 8 3.58499 7.98257 3.49165 7.94771C3.3983 7.91285 3.31079 7.85476 3.22912 7.77342L0.218775 4.7756C0.0670908 4.62455 -0.00571732 4.43561 0.00035004 4.2088C0.00595068 3.98245 0.0845929 3.79375 0.236277 3.6427C0.387961 3.49165 0.577682 3.41612 0.805441 3.41612C1.03273 3.41612 1.21639 3.49165 1.3564 3.6427L3.78918 6.06536L9.65235 0.226579C9.79236 0.0755264 9.97905 0 10.2124 0C10.4458 0 10.6383 0.0755264 10.79 0.226579C10.93 0.377632 11 0.566333 11 0.79268C11 1.01949 10.93 1.20842 10.79 1.35948L4.34924 7.77342C4.26757 7.85476 4.18006 7.91285 4.08671 7.94771C3.99337 7.98257 3.89419 8 3.78918 8Z'
										fill='white'
									/>
								</svg>
							</label>
							<div className='presentation'>
								<h1>CSV</h1>
								<p>
									Ideal para editar fuera de la plataforma. Este formato es
									editable.
								</p>
							</div>
						</div>
						<div className='buttons-wrapper' style={{marginTop: 50}}>
							<button>Cancelar</button>
							<button
								onClick={(e) => {
									setSteps(2)
									setTimeout(() => {
										setSteps(3)
									}, 1300)
								}}
							>
								Exportar
							</button>
						</div>
					</div>
				)}
				{steps === 2 && (
					<div className='first third-step'>
						<h1>Generando archivos</h1>

						<div className='upload-box'>
							<small>Lorem Ipsum Dolor.pdf</small>
							<h1>Descargando</h1>
							<div className='row-upload-box'>
								<img src={download} alt='' />
								<div className='line'>
									<div className='inner_line'></div>
								</div>
								<img src={close} alt='' />
							</div>
							<span>En Proceso</span>
							<span>525KB • 80% downloaded</span>
						</div>
						<div className='upload-box'>
							<small>Lorem Ipsum Dolor.pdf</small>
							<h1>Descargando</h1>
							<div className='row-upload-box'>
								<img src={download} alt='' />
								<div className='line'>
									<div className='inner_line'></div>
								</div>
								<img src={close} alt='' />
							</div>
							<span>En Proceso</span>
							<span>525KB • 80% downloaded</span>
						</div>
						<div
							className='buttons-wrapper'
							style={{gridTemplateColumns: '1fr', marginTop: 50}}
						>
							<button>Cancelar Descarga</button>
						</div>
					</div>
				)}
				{steps === 3 && (
					<div className='first third-step'>
						<h1>Archivos Generados</h1>
						<p>Elige donde quieres guardarlos</p>
						<div className='box '>
							<img src={blueTick} alt='' />
							<div className='presentation'>
								<h1>Lorem Ipsum Dolor.pdf</h1>
								<p>Descargado</p>
							</div>
						</div>
						<div className='box '>
							<img src={blueTick} alt='' />
							<div className='presentation'>
								<h1>Lorem Ipsum Dolor.pdf</h1>
								<p>Descargado</p>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className='bottom-area'>
				<button className='button-cancel'>Cerrar</button>
			</div>
		</div>
	)
}
