import React from "react";

export const LengendColumns = ({tipoPromocion, toolTips}) => {
  return (
    <div className="LengendColumns">
      <p className="heading-column">Producto</p>
      <button className="button-box">
        Código
        <div className="tooltip">
          <h1>Código</h1>
          <p>
            {toolTips.codigo}
          </p>
        </div>
      </button>
      <button className="button-box">
        Descripción
        <div className="tooltip">
          <h1>Descripción</h1>
          <p>
            {toolTips.descripcion}
          </p>
        </div>
      </button>
      <button className="button-box">
        Precio Lista
        <div className="tooltip">
          <h1>Precio Lista</h1>
          <p>
            {toolTips.precio_lista}
          </p>
        </div>
      </button>
      <p className="heading-column">Promoción</p>
      
      <button className="button-box">
        { tipoPromocion && tipoPromocion==='SELL-IN' ? "Precio Promoción" : "PVP Fleje"}
        <div className="tooltip">
          <h1>{tipoPromocion && tipoPromocion==='SELL-IN' ? "Precio Promoción" : "Precio Fleje"}</h1>
          <p>
            {toolTips.precio_promocion}
          </p>
        </div>
      </button>
      <button className="button-box">
        % Descuento
        <div className="tooltip">
          <h1>% Descuento</h1>
          <p>
            {toolTips.porcentaje_descuento}
          </p>
        </div>
      </button>
      <p className="heading-column">Var. Promocionales</p>
      <button className="button-box">
        Mecánica
        <div className="tooltip">
          <h1>Mecánica</h1>
          <p>
            {toolTips.mecanica}
          </p>
        </div>
      </button>
      <button className="button-box">
        Adherencia
        <div className="tooltip">
          <h1>Adherencia</h1>
          <p>
            {toolTips.adherencia}
          </p>
        </div>
      </button>
      <button className="button-box">
        Exhibición
        <div className="tooltip">
          <h1>Exhibición</h1>
          <p>
            {toolTips.exhibicion}
          </p>
        </div>
      </button>
      <button className="button-box">
        Activación
        <div className="tooltip">
          <h1>Activación</h1>
          <p>
            {toolTips.activacion}
          </p>
        </div>
      </button>
      <button className="button-box">
        Inv. Adicional
        <div className="tooltip">
          <h1>Inv. Adicional</h1>
          <p>
            {toolTips.inv_adicional}
          </p>
        </div>
      </button>
      <p className="heading-column">PVP</p>
      <button className="button-box">
        Margen Cliente
        <div className="tooltip">
          <h1>Margen Cliente</h1>
          <p>
            {toolTips.margen_cliente}
          </p>
        </div>
      </button>
      <button className="button-box">
      { tipoPromocion && tipoPromocion==='SELL-IN' ? "PVP Promoción" : "PVP Promoción"}
        <div className="tooltip">
          <h1>{ tipoPromocion && tipoPromocion==='SELL-IN' ? "PVP Promoción" : "PVP Promoción"}</h1>
          <p>
            {toolTips.pvp}
          </p>
        </div>
      </button>
      <p className="heading-column">Fechas</p>
      {(tipoPromocion && tipoPromocion==='SELL-IN') &&
      <button className="button-box">
        Sell-In
        <div className="tooltip">
          <h1> Sell-In</h1>
          <p>
            {toolTips.sell_in}
          </p>
        </div>
      </button>
      }
      <button className="button-box">
        Sell-Out
        <div className="tooltip">
          <h1>Sell-Out</h1>
          <p>
            {toolTips.sell_out}
          </p>
        </div>
      </button>
    </div>
  );
};
