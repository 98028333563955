import React from 'react'

export const AnimationLoaderCircle = ({activeCircle}) => {
	return (
		<div className={`AnimationLoaderCircle ${activeCircle && 'start'}`}>
			<div className='AnimationLoaderCircleEach'></div>
			<div className='AnimationLoaderCircleEach'></div>
			<div className='AnimationLoaderCircleEach'></div>
			<div className='AnimationLoaderCircleEach'></div>
		</div>
	)
}
