import {useState} from 'react'
import Table from 'react-bootstrap/Table'
import Flecha from '../assets/img/Flecha.svg'
import Off from '../assets/img/Off.svg'
import orangeDown from '../assets/img/orange_down.svg'
import {ToolTipCustomList} from './ToolTipCustomList'

export const CataLogoResumenTableCopy = () => {
	const [MouseDownState, setMouseDownState] = useState(null)
	return (
		<div className='CataLogoResumenTable CataLogoResumenTableNew'>
			<Table>
				<thead style={{height: `40 !important`}}>
					<tr>
						<th className='fimila border-right-gray' rowSpan={2}>
							Línea
						</th>
						<th colSpan={3} className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>Volumen</p>
							</div>
						</th>
						<th colSpan={4} className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>Cumplimiento Meta</p>
							</div>
						</th>
						<th colSpan={3} className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>Precio</p>
							</div>
						</th>
						<th colSpan={3} className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>Resultado MM</p>
							</div>
						</th>

						<th colSpan={2} className='border-right-gray'>
							<div className='display_flex blue'>
								<p>Beneficio</p>
							</div>
						</th>

						<th colSpan={2}>
							<div className='display_flex blue'>
								<p>Res. Campaña</p>
							</div>
						</th>
					</tr>

					<tr>
						<td>
							<div className='display_flex gray'>
								<p>Act.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Opt.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Prop.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>Meta.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Act.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Opt.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Prop.</p>
								<img src={Off} alt='' />
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>Act.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Opt.</p>
								<img src={Off} alt='' />
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>Prop.</p>
								<img src={Off} alt='' />
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>Act.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Opt.</p>
								<img src={Off} alt='' />
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>Prop.</p>
								<img src={Off} alt='' />
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>Opt. - Act.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Prop. - Act.</p>
								<img src={Off} alt='' />
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>N° Act.</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Imp</p>
								<img src={Off} alt='' />
							</div>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className='border-right-gray'>
							<div className='display_flex gray'>
								<a href='#'>Cecinas</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>109,6 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>108,3 Ton.</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex green '>
								<img src={Flecha} alt='' className='mr-5' />
								<p>33,1 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>117,0 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>95,3%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>94,2%</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$2.611</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>$2.590</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$7,5</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>$5,3</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>$9,4</p>
							</div>
						</td>
						<td>
							<div className='display_flex orange'>
								<p>-$2.191.293</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex  gray'>
								<p>$1.906.656</p>
							</div>
						</td>

						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList list={['Feb. 3', 'Ene. 4', 'Dic. 3']} />
								<img src={Flecha} alt='' className='mr-5' />
								<p>$4</p>
							</div>
						</td>
						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList
									list={['Feb. 40.0%', 'Ene. 45.2%', 'Dic. 30.9']}
								/>
								<img src={Flecha} alt='' className='mr-5' />
								<p>40,0%</p>
							</div>
						</td>
					</tr>

					<tr>
						<td className='border-right-gray'>
							<div className='display_flex gray'>
								<a href='#'>Lácteos</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>109,6 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>108,3 Ton.</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex green '>
								<img src={Flecha} alt='' className='mr-5' />
								<p>33,1 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>117,0 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>95,3%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>94,2%</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>100,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$2.611</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>$2.590</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$7,5</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>$5,3</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>$9,4</p>
							</div>
						</td>
						<td>
							<div className='display_flex orange'>
								<p>-$2.191.293</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex  gray'>
								<p>$1.906.656</p>
							</div>
						</td>

						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList list={['Feb. 3', 'Ene. 4', 'Dic. 3']} />
								<img src={Flecha} alt='' className='mr-5' />
								<p>$4</p>
							</div>
						</td>
						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList
									list={['Feb. 40.0%', 'Ene. 45.2%', 'Dic. 30.9']}
								/>
								<img src={Flecha} alt='' className='mr-5' />
								<p>40,0%</p>
							</div>
						</td>
					</tr>
					<tr>
						<td className='border-right-gray'>
							<div className='display_flex gray'>
								<a href='#'>Platos Prep.</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>109,6 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>108,3 Ton.</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex green '>
								<img src={Flecha} alt='' className='mr-5' />
								<p>33,1 Mil U.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>117,0 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>95,3%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>94,2%</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex orange'>
								<img src={orangeDown} alt='' className='mr-5' />
								<p>88,2%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$2.611</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>$2.590</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$7,5</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>$5,3</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>$9,4</p>
							</div>
						</td>
						<td>
							<div className='display_flex orange'>
								<p>-$2.191.293</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex  gray'>
								<p>$1.906.656</p>
							</div>
						</td>

						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList list={['Feb. 3', 'Ene. 4', 'Dic. 3']} />
								<img src={Flecha} alt='' className='mr-5' />
								<p>$4</p>
							</div>
						</td>
						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList
									list={['Feb. 40.0%', 'Ene. 45.2%', 'Dic. 30.9']}
								/>
								<img src={Flecha} alt='' className='mr-5' />
								<p>40,0%</p>
							</div>
						</td>
					</tr>
					<tr>
						<td className='border-right-gray'>
							<div className='display_flex gray'>
								<a href='#'>Elaborados</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>109,6 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>108,3 Ton.</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex green '>
								<img src={Flecha} alt='' className='mr-5' />
								<p>33,1 Mil U.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>117,0 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>95,3%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>94,2%</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$2.611</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>$2.590</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$7,5</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>$5,3</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>$9,4</p>
							</div>
						</td>
						<td>
							<div className='display_flex orange'>
								<p>-$2.191.293</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex  gray'>
								<p>$1.906.656</p>
							</div>
						</td>

						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList list={['Feb. 3', 'Ene. 4', 'Dic. 3']} />
								<img src={Flecha} alt='' className='mr-5' />
								<p>$4</p>
							</div>
						</td>
						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList
									list={['Feb. 40.0%', 'Ene. 45.2%', 'Dic. 30.9']}
								/>
								<img src={Flecha} alt='' className='mr-5' />
								<p>40,0%</p>
							</div>
						</td>
					</tr>
					<tr>
						<td className='border-right-gray'>
							<div className='display_flex gray'>
								<a href='#'>Pizzas</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>109,6 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>108,3 Ton.</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex green '>
								<img src={Flecha} alt='' className='mr-5' />
								<p>33,1 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>117,0 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>95,3%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>94,2%</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex orange'>
								<img src={orangeDown} alt='' className='mr-5' />
								<p>38,2%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$2.611</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>$2.590</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$7,5</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>$5,3</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>$9,4</p>
							</div>
						</td>
						<td>
							<div className='display_flex orange'>
								<p>-$2.191.293</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex  gray'>
								<p>$1.906.656</p>
							</div>
						</td>

						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList list={['Feb. 3', 'Ene. 4', 'Dic. 3']} />
								<img src={Flecha} alt='' className='mr-5' />
								<p>$4</p>
							</div>
						</td>
						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList
									list={['Feb. 40.0%', 'Ene. 45.2%', 'Dic. 30.9']}
								/>
								<img src={Flecha} alt='' className='mr-5' />
								<p>40,0%</p>
							</div>
						</td>
					</tr>
					<tr>
						<td className='border-right-gray'>
							<div className='display_flex gray'>
								<a href='#'>Proyectos</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>109,6 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>108,3 Ton.</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex green '>
								<img src={Flecha} alt='' className='mr-5' />
								<p>33,1 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>117,0 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>95,3%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>94,2%</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$2.611</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>$2.590</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$7,5</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>$5,3</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>$9,4</p>
							</div>
						</td>
						<td>
							<div className='display_flex orange'>
								<p>-$2.191.293</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex  gray'>
								<p>$1.906.656</p>
							</div>
						</td>

						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList list={['Feb. 3', 'Ene. 4', 'Dic. 3']} />
								<img src={Flecha} alt='' className='mr-5' />
								<p>$4</p>
							</div>
						</td>
						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList
									list={['Feb. 40.0%', 'Ene. 45.2%', 'Dic. 30.9']}
								/>
								<img src={Flecha} alt='' className='mr-5' />
								<p>40,0%</p>
							</div>
						</td>
					</tr>
					<tr>
						<td className='border-right-gray'>
							<div className='display_flex gray'>
								<a href='#'>Vegetales</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>109,6 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>108,3 Ton.</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex green '>
								<img src={Flecha} alt='' className='mr-5' />
								<p>33,1 Mil U.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>117,0 Ton.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>95,3%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>94,2%</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$2.611</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>$2.590</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>101,7%</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$7,5</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>$5,3</p>
							</div>
						</td>

						<td className='border-right-gray'>
							<div className='display_flex green'>
								<img src={Flecha} alt='' className='mr-5' />
								<p>$9,4</p>
							</div>
						</td>
						<td>
							<div className='display_flex orange'>
								<p>-$2.191.293</p>
							</div>
						</td>
						<td className='border-right-gray'>
							<div className='display_flex  gray'>
								<p>$1.906.656</p>
							</div>
						</td>

						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList list={['Feb. 3', 'Ene. 4', 'Dic. 3']} />
								<img src={Flecha} alt='' className='mr-5' />
								<p>$4</p>
							</div>
						</td>
						<td>
							<div
								className='display_flex green'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button !== 0) {
										return false
									}
									const MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								<ToolTipCustomList
									list={['Feb. 40.0%', 'Ene. 45.2%', 'Dic. 30.9']}
								/>
								<img src={Flecha} alt='' className='mr-5' />
								<p>40,0%</p>
							</div>
						</td>
					</tr>
				</tbody>
			</Table>
		</div>
	)
}
