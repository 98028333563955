import React, {useState} from 'react'
import tickGreen from '../../assets/img/tick-green-box.svg'
import refresh from '../../assets/img/refresh.svg'
import roundArrow from '../../assets/img/round-arrow.svg'
import loaderImg from '../../assets/img/loader_img.png'
import arrowGray from '../../assets/img/arrow-gray.svg'

export const MoverProductSidebar = () => {
	const [steps, setSteps] = useState(1)
	return (
		<div className='AddProductSdebar'>
			<div className='topbar'>
				<h1>Mover</h1>
			</div>

			<div className='form-wrapper-area MoverProductSidebar'>
				{steps === 1 && (
					<div className='first'>
						<h1>Selecciona que quieres hacer con el Catálogo:</h1>
						<div
							className='box green-box'
							onClick={(e) => {
								setSteps(2)
							}}
						>
							<img src={tickGreen} alt='' />
							<div className='presentation'>
								<h1>Aprobar Catálogo</h1>
								<p>
									Si apruebas el catálogo este avanzará automáticamente a la
									siguiente Fase y los demás usuarios serán notificados.
								</p>
							</div>
						</div>
						<div
							className='box refresh-box'
							onClick={(e) => {
								setSteps(2)
							}}
						>
							<img src={refresh} alt='' />
							<div className='presentation'>
								<h1>Solicitar Cambios</h1>
								<p>
									Si Solicitas cambios el Catálogo volverá a fases anteriores
									para que otros usuarios también puedan aplicar cambios.
								</p>
							</div>
						</div>
					</div>
				)}
				{steps === 2 && (
					<div className='second'>
						<h1>El Catálogo se moverá a:</h1>
						<p>Fase</p>
						<span className='badge'>En Ejecución</span>
						<div className='form-area-inner'>
							<label htmlFor=''>Deja un Comentario (opcional)</label>
							<textarea
								placeholder='Lorem Ipsum Dolor'
								cols='30'
								rows='10'
							></textarea>
							<div className='buttons-wrapper'>
								<button
									onClick={(e) => {
										setSteps(1)
									}}
								>
									Cancelar
								</button>
								<button
									onClick={(e) => {
										setSteps(3)
										setTimeout(() => {
											setSteps(4)
										}, 2000)
									}}
								>
									Confirmar
								</button>
							</div>
						</div>
					</div>
				)}

				{steps === 3 && (
					<div className='third'>
						<img src={roundArrow} alt='' />
						<h1>Moviendo Catálogo</h1>
						<div className='LoaderNew customize'>
							<img src={loaderImg} alt='' />
							<svg
								width='25'
								height='30'
								viewBox='0 0 25 30'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M0 0V29.4H7.476V21.126H12.81C20.328 21.126 24.654 17.136 24.654 10.248C24.654 3.696 20.328 0 12.81 0H0ZM7.476 5.88H12.432C15.708 5.88 17.598 7.434 17.598 10.5C17.598 13.608 15.708 15.246 12.432 15.246H7.476V5.88Z'
									fill='#2E2E2E'
								/>
							</svg>
							<svg
								width='6'
								height='6'
								viewBox='0 0 6 6'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<circle
									cx='3'
									cy='3'
									r='3'
									fill='url(#paint0_linear_459_158955)'
								/>
								<defs>
									<linearGradient
										id='paint0_linear_459_158955'
										x1='0.243243'
										y1='1.16798e-06'
										x2='7.34243'
										y2='0.446138'
										gradientUnits='userSpaceOnUse'
									>
										<stop stopColor='#FFAC23' />
										<stop offset='1' stopColor='#FF5334' />
									</linearGradient>
								</defs>
							</svg>
						</div>
						<button>Cancelar</button>
					</div>
				)}
				{steps === 4 && (
					<div className='third fourth'>
						<img src={roundArrow} alt='' />
						<h1>Catálogo Movido</h1>
						<p className='warning'>
							El Catálogo ha sido movido a la Fase: En Edición. Puedes volver al
							inicio o seguir editandolo.
						</p>
						<div className='buttons-wrapper'>
							<button>Seguir Editando</button>
							<button
								onClick={(e) => {
									setSteps(5)
								}}
							>
								Ir al Inicio
							</button>
						</div>
					</div>
				)}
				{steps === 5 && (
					<div className='third fourth'>
						<img src={arrowGray} alt='' />
						<h1>
							No tienes permisos para <br /> Mover este Catálogo
						</h1>
						<p className='warning'>
							El Catálogo se encuentra en una fase restringida para la mayoría
							de los usuarios. Solo un Administrador puede Moverlo. La promoción
							ha sido movida exitosamente
						</p>
						<div className='buttons-wrapper'>
							<button>Seguir Editando</button>
							<button>Ir al Inicio</button>
						</div>
					</div>
				)}
			</div>

			<div className='bottom-area'>
				<button className='button-cancel'>Cerrar</button>
			</div>
		</div>
	)
}
