import React, {useState} from 'react'
import Nombre from '../assets/img/NombreNew.svg'

import share from '../assets/img/share.svg'
import Cerrar from '../assets/img/Cerrar.svg'

export const Popup = ({setActive, setOfertaNewactive = null}) => {
	const [selected, setSelected] = useState(false)
	return (
		<div className='popupWrapper'>
			<div className='popup promocien'>
				<div className='close_icon' onClick={(e) => setActive(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<img src={Nombre} alt='' style={{marginBottom: 10}} />
				<p style={{marginBottom: 20}}>
					Selecciona el canal y modalidad para crear la promoción
				</p>
				<b>
					<img src={share} alt='' />
					Canal
				</b>

				<div className='checkbox_container checkbox_container_Moderno_Tradicional checkbox_container_border'>
					<div className='left_side'>
						<label htmlFor='ModernoCheckbox'>
							<input
								type='radio'
								id='ModernoCheckbox'
								name='popradio'
								onChange={(e) => {
									setSelected(true)
								}}
							/>
							<label htmlFor='ModernoCheckbox'></label>
							<h1>Moderno</h1>
						</label>
						{/* <p>El canal Moderno contiene las cadenas nacionales y regionales</p> */}
					</div>
					<div className='right_side'>
						<label htmlFor='Tradicional' className='Tradicional'>
							<input
								type='radio'
								id='Tradicional'
								name='popradio'
								onChange={(e) => {
									setSelected(true)
								}}
							/>
							<label htmlFor='Tradicional'></label>
							<h1>Tradicional & Food Service</h1>
						</label>
						{/* <p>El canal Tradicional incluye tradicional y foodservice</p> */}
					</div>
				</div>

				<div className='checkbox_container'>
					<div className='left_side'>
						<button disabled={!selected}>Catálogos</button>
						<p>Creación de Catálogo con resultados de Optimización</p>
					</div>
					<div className='right_side'>
						<button
							disabled={!selected}
							onClick={(e) => {
								setOfertaNewactive(true)
								setActive(false)
							}}
						>
							Ofertas
						</button>
						<p>Creación de Ofertas con carga Manual</p>
					</div>
				</div>
				<div className='button_wrapper'>
					<a href='#' className='volver_link'>
						Volver al Inicio
					</a>
				</div>
			</div>
		</div>
	)
}
