export const ProductList = [
    {
        "cadena": "Acuenta",
        "canal": 15,
        "codigo_producto": "1029",
        "descripcion": "Producto 1",
        "precio": 672.0
    },
    {
        "cadena": "Ekono",
        "canal": 15,
        "codigo_producto": "1029",
        "descripcion": "Producto 1",
        "precio": 672.0
    },
    {
        "cadena": "Jumbo",
        "canal": 15,
        "codigo_producto": "1029",
        "descripcion": "Producto 1",
        "precio": 672.0
    },
    {
        "cadena": "Tottus",
        "canal": 15,
        "codigo_producto": "1029",
        "descripcion": "Producto 1",
        "precio": 672.0
    },
    {
        "cadena": "Tottus",
        "canal": 15,
        "codigo_producto": "1030",
        "descripcion": "Producto 1",
        "precio": 672.0
    },
    {
        "cadena": "Tottus",
        "canal": 15,
        "codigo_producto": "1034",
        "descripcion": "Producto 1",
        "precio": 672.0
    }
]