import {useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import Alert from '../componentsRef/Alert/Alert'
import AlertError from '../componentsRef/AlertError/AlertError'
import {AuthContext} from '../context'
import {NewInputModuleLogin} from './NewInputModuleLogin'

import Banner1Login from '../assets/img/Banner1Login.png'
import Banner2Login from '../assets/img/Banner2Login.png'
import FullNegroVerde from '../assets/img/Full_Negro+Verde.svg'
import '../assets/css/LoginNew.css'

const validationSchema = Yup.object({
	email: Yup.string()
		// .email('Ingresa con tu email y contraseña')
		// .required('Ingresa con tu email y contraseña'),
		,
	password: Yup.string().required('La contraseña es requerida').min(1),
})

export const LoginNew = () => {
	const {login, authState} = useContext(AuthContext)
	const [activeScreen, setActiveScreen] = useState(false)
	const navigate = useNavigate()
	const [isInputFocused, setInputFocused] = useState(false)

	const handleInputFocus = () => {
		setInputFocused(!isInputFocused)
	}

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema,
		onSubmit: async (values, {setSubmitting}) => {
			const {email, password} = values
			try {
				await login(email, password)
				if (authState.logged || (localStorage.getItem('token') !== '' && localStorage.getItem('token') !== null)) {
					window.location.pathname = '/HomeNew';
					navigate('/HomeNew')
				}

				// window.location.pathname = '/HomeNew';
			} catch (error) {
				console.log(error)
			}
		},
	}, [authState])

	useEffect(() => {
		setTimeout(() => {
			setActiveScreen(true)
		}, 200)
	}, [])

	return (
		<div className={`LoginNew LoginNewPure ${activeScreen && 'login_active'}`}>
			<img src={Banner1Login} alt='' className='Banner1Login' />
			<img src={Banner2Login} alt='' className='Banner2Login' />
			<form noValidate onSubmit={formik.handleSubmit}>
				<div className='top_box'>
					<img src={FullNegroVerde} alt='' />
				</div>
				<div className='body_area'>
					<p>Iniciar Sesión</p>
					<div className='input_wrapper'>
						<div>
							{/* <label htmlFor='email'>Correo electrónico</label> */}
							<NewInputModuleLogin
								type='email'
								id='email'
								name='email'
								placeholder='Ingresa tu Correo'
								value={formik.values.email}
								onChange={formik.handleChange}
								// onBlur={formik.handleBlur}
								// error={formik.errors.email}
								onFocus={handleInputFocus}
							/>
						</div>

						<svg
							width='12'
							className='login-icons'
							height='10'
							viewBox='0 0 12 10'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M1 9.43333C0.711111 9.43333 0.472222 9.33889 0.283333 9.15C0.0944444 8.96111 0 8.72222 0 8.43333V1.5C0 1.21111 0.0944444 0.972222 0.283333 0.783333C0.472222 0.594444 0.711111 0.5 1 0.5H10.6C10.8889 0.5 11.1278 0.594444 11.3167 0.783333C11.5056 0.972222 11.6 1.21111 11.6 1.5V8.43333C11.6 8.72222 11.5056 8.96111 11.3167 9.15C11.1278 9.33889 10.8889 9.43333 10.6 9.43333H1ZM11.1333 1.33333L6.08333 4.68333C6.03889 4.70556 5.99444 4.72489 5.95 4.74133C5.90556 4.75822 5.85556 4.76667 5.8 4.76667C5.74444 4.76667 5.69444 4.75822 5.65 4.74133C5.60556 4.72489 5.56111 4.70556 5.51667 4.68333L0.466667 1.33333V8.43333C0.466667 8.58889 0.516667 8.71667 0.616667 8.81667C0.716667 8.91667 0.844445 8.96667 1 8.96667H10.6C10.7556 8.96667 10.8833 8.91667 10.9833 8.81667C11.0833 8.71667 11.1333 8.58889 11.1333 8.43333V1.33333ZM5.8 4.3L10.8667 0.966667H0.733333L5.8 4.3ZM0.466667 1.33333V1.46667V1.058V1.08333V1.04133V1.46667V1.33333V8.96667V8.43333V1.33333Z'
								fill='#ADADAD'
							/>
						</svg>
					</div>

					<div className='input_wrapper' style={{marginTop: 0}}>
						{/* <label htmlFor='Contraseña'>Contraseña</label> */}
						<NewInputModuleLogin
							type='password'
							id='password'
							name='password'
							placeholder='Ingresa tu Contraseña'
							value={formik.values.password}
							onChange={formik.handleChange}
							// onBlur={formik.handleBlur}
							// error={formik.errors.password}
							onFocus={handleInputFocus}
						/>

						<svg
							className='login-icons'
							width='10'
							height='12'
							viewBox='0 0 10 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M1.53333 11.8C1.25555 11.8 1.01955 11.7027 0.825325 11.508C0.630658 11.3138 0.533325 11.0778 0.533325 10.8V5.19999C0.533325 4.92222 0.630658 4.68599 0.825325 4.49133C1.01955 4.29711 1.25555 4.19999 1.53333 4.19999H2.53333V2.86666C2.53333 2.17777 2.77221 1.59444 3.24999 1.11666C3.72777 0.638883 4.3111 0.399994 4.99999 0.399994C5.68888 0.399994 6.27221 0.638883 6.74999 1.11666C7.22777 1.59444 7.46666 2.17777 7.46666 2.86666V4.19999H8.46666C8.74444 4.19999 8.98044 4.29711 9.17466 4.49133C9.36932 4.68599 9.46666 4.92222 9.46666 5.19999V10.8C9.46666 11.0778 9.36932 11.3138 9.17466 11.508C8.98044 11.7027 8.74444 11.8 8.46666 11.8H1.53333ZM1.53333 11.3333H8.46666C8.62221 11.3333 8.74999 11.2833 8.84999 11.1833C8.94999 11.0833 8.99999 10.9556 8.99999 10.8V5.19999C8.99999 5.04444 8.94999 4.91666 8.84999 4.81666C8.74999 4.71666 8.62221 4.66666 8.46666 4.66666H1.53333C1.37777 4.66666 1.24999 4.71666 1.14999 4.81666C1.04999 4.91666 0.999992 5.04444 0.999992 5.19999V10.8C0.999992 10.9556 1.04999 11.0833 1.14999 11.1833C1.24999 11.2833 1.37777 11.3333 1.53333 11.3333ZM4.99999 8.89999C5.25555 8.89999 5.46955 8.81399 5.64199 8.64199C5.81399 8.46955 5.89999 8.25555 5.89999 7.99999C5.89999 7.74444 5.81399 7.53044 5.64199 7.35799C5.46955 7.18599 5.25555 7.09999 4.99999 7.09999C4.74444 7.09999 4.53066 7.18599 4.35866 7.35799C4.18621 7.53044 4.09999 7.74444 4.09999 7.99999C4.09999 8.25555 4.18621 8.46955 4.35866 8.64199C4.53066 8.81399 4.74444 8.89999 4.99999 8.89999ZM2.99999 4.19999H6.99999V2.86666C6.99999 2.31111 6.80555 1.83888 6.41666 1.44999C6.02777 1.06111 5.55555 0.866661 4.99999 0.866661C4.44444 0.866661 3.97221 1.06111 3.58332 1.44999C3.19444 1.83888 2.99999 2.31111 2.99999 2.86666V4.19999Z'
								fill='#ADADAD'
							/>
						</svg>
					</div>

					<label className='login-new-checkbox' htmlFor='login-new'>
						<br></br>
					</label>
	
{ /*
					{(formik.touched.email && formik.errors.email) ||
					(formik.touched.password && formik.errors.password) ? (
						<Alert />
					) : null}
					*/ }

					

					<div className='input_wrapper button_wrapper' style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						textAlign: 'center',
					}}>
						<button type='submit' disabled={formik.isSubmitting}>
							Entrar
							<svg
								width='17'
								height='16'
								viewBox='0 0 17 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<mask
									id='mask0_459_64902'
									maskUnits='userSpaceOnUse'
									x='2'
									y='2'
									width='13'
									height='12'
								>
									<rect x='2.5' y='2' width='12' height='12' fill='#D9D9D9' />
								</mask>
								<g mask='url(#mask0_459_64902)'>
									<path
										d='M6.02451 12.6C5.89118 12.4583 5.82451 12.2937 5.82451 12.106C5.82451 11.9187 5.89118 11.7542 6.02451 11.6125L9.63701 8L6.01201 4.375C5.87868 4.24167 5.81201 4.07917 5.81201 3.8875C5.81201 3.69583 5.88285 3.52917 6.02451 3.3875C6.15785 3.25417 6.32034 3.1875 6.51201 3.1875C6.70368 3.1875 6.87034 3.25417 7.01201 3.3875L11.212 7.6C11.2703 7.65833 11.312 7.72083 11.337 7.7875C11.362 7.85417 11.3745 7.925 11.3745 8C11.3745 8.075 11.362 8.14583 11.337 8.2125C11.312 8.27917 11.2703 8.34167 11.212 8.4L6.99951 12.6125C6.86618 12.7458 6.70568 12.8125 6.51801 12.8125C6.33068 12.8125 6.16618 12.7417 6.02451 12.6Z'
										fill='white'
									/>
								</g>
							</svg>
						</button>
						{/* <Link to='/ForgetPasswordNew'>Olvidé mi contraseña</Link> */}
					</div>
					<div >
						{authState.errorMessage ? <AlertError /> : null}
					</div>
				</div>
			</form>
		</div>
	)
}
