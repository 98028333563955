import {useNavigate} from 'react-router-dom'

export const Notienespermisos = ({setNotienespermisosState}) => {
	const navigate = useNavigate()
	return (
		<>
			<span className='black_bg'></span>
			<div className='LogoutPopupNew Notienespermisos'>
				<svg
					width='40'
					height='40'
					viewBox='0 0 40 40'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect width='40' height='40' rx='20' fill='#FFF6E8' />
					<g clipPath='url(#clip0_835_169217)'>
						<mask
							id='mask0_835_169217'
							maskUnits='userSpaceOnUse'
							x='4'
							y='4'
							width='32'
							height='32'
						>
							<rect x='4' y='4' width='32' height='32' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_835_169217)'>
							<path
								d='M7.73349 32.1332C7.13349 32.1332 6.69438 31.8835 6.41616 31.3839C6.13882 30.8835 6.1446 30.3777 6.43349 29.8666L18.6668 8.66657C18.9557 8.15546 19.4002 7.8999 20.0002 7.8999C20.6002 7.8999 21.0446 8.15546 21.3335 8.66657L33.5668 29.8666C33.8557 30.3777 33.8615 30.8835 33.5842 31.3839C33.3059 31.8835 32.8668 32.1332 32.2668 32.1332H7.73349ZM20.0002 17.4332C19.6224 17.4332 19.3059 17.5608 19.0508 17.8159C18.7948 18.0719 18.6668 18.3888 18.6668 18.7666V22.6666C18.6668 23.0443 18.7948 23.3608 19.0508 23.6159C19.3059 23.8719 19.6224 23.9999 20.0002 23.9999C20.3779 23.9999 20.6948 23.8719 20.9508 23.6159C21.2059 23.3608 21.3335 23.0443 21.3335 22.6666V18.7666C21.3335 18.3888 21.2059 18.0719 20.9508 17.8159C20.6948 17.5608 20.3779 17.4332 20.0002 17.4332ZM20.0002 27.9666C20.3779 27.9666 20.7002 27.8332 20.9668 27.5666C21.2335 27.2999 21.3668 26.9777 21.3668 26.5999C21.3668 26.2221 21.2335 25.8999 20.9668 25.6332C20.7002 25.3666 20.3779 25.2332 20.0002 25.2332C19.6224 25.2332 19.3002 25.3666 19.0335 25.6332C18.7668 25.8999 18.6335 26.2221 18.6335 26.5999C18.6335 26.9777 18.7668 27.2999 19.0335 27.5666C19.3002 27.8332 19.6224 27.9666 20.0002 27.9666ZM10.1335 29.2332H29.8668L20.0002 12.2332L10.1335 29.2332Z'
								fill='#FFAC23'
							/>
						</g>
					</g>
					<defs>
						<clipPath id='clip0_835_169217'>
							<rect
								width='32'
								height='32'
								fill='white'
								transform='translate(4 4)'
							/>
						</clipPath>
					</defs>
				</svg>

				<h1>No tienes permisos para editar este Catálogo</h1>
				<div className='button_wrapper'>
					<button
						onClick={(e) => {
							setNotienespermisosState(false)
						}}
					>
						Aceptar
					</button>
				</div>
				<svg
					width='16'
					height='16'
					onClick={(e) => setNotienespermisosState(false)}
					viewBox='0 0 16 16'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask
						id='mask0_652_310344'
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='16'
						height='16'
					>
						<rect width='16' height='16' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_652_310344)'>
						<path
							d='M7.99994 9.06666L4.79993 12.2667C4.65549 12.4111 4.47771 12.4833 4.2666 12.4833C4.05549 12.4833 3.87771 12.4111 3.73327 12.2667C3.58882 12.1222 3.5166 11.9444 3.5166 11.7333C3.5166 11.5222 3.58882 11.3444 3.73327 11.2L6.93327 8L3.73327 4.8C3.58882 4.65555 3.5166 4.47777 3.5166 4.26666C3.5166 4.05555 3.58882 3.87777 3.73327 3.73333C3.87771 3.58888 4.05549 3.51666 4.2666 3.51666C4.47771 3.51666 4.65549 3.58888 4.79993 3.73333L7.99994 6.93333L11.1999 3.73333C11.3444 3.58888 11.5222 3.51666 11.7333 3.51666C11.9444 3.51666 12.1222 3.58888 12.2666 3.73333C12.411 3.87777 12.4833 4.05555 12.4833 4.26666C12.4833 4.47777 12.411 4.65555 12.2666 4.8L9.0666 8L12.2666 11.2C12.411 11.3444 12.4833 11.5222 12.4833 11.7333C12.4833 11.9444 12.411 12.1222 12.2666 12.2667C12.1222 12.4111 11.9444 12.4833 11.7333 12.4833C11.5222 12.4833 11.3444 12.4111 11.1999 12.2667L7.99994 9.06666Z'
							fill='#ADADAD'
						/>
					</g>
				</svg>
			</div>
		</>
	)
}
