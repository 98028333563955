import React, {useState} from 'react'
import PromoBooster from '../assets/img/PromoBooster.svg'
import {Link, useNavigate} from 'react-router-dom'
import lineBg from '..//assets/img/line_bg.svg'
export const ResetPassword = () => {
	const navigate = useNavigate()

	const [EmailState, setEmailState] = useState(null)
	const [EmailError, setEmail] = useState(null)

	const handleLogin = (e) => {
		e.preventDefault()
		const ValidateBool = HandleEmail(e, EmailState)
		if (ValidateBool) {
			navigate('/EmailSent')
		}
	}

	const HandleEmail = (e, value) => {
		// eslint-disable-next-line no-useless-escape
		const email = /^([a-zA-Z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
		console.log(value)
		console.log(email.test(value))
		if (email.test(value)) {
			setEmail(true)
			return true
		} else {
			setEmail(false)
			return false
		}
	}

	return (
		<div className='Login ResetPassword'>
			<img src={lineBg} alt='' style={{opacity: 0.4}} />
			<form action=''>
				<h1>Recupera tu Contraseña</h1>
				<p>Ingresa el correo electrónico con el que te registraste </p>
				<div
					className={`input_wrapper ${
						EmailError === true
							? 'input_active'
							: EmailError === false && 'passerror'
					} `}
				>
					<input
						type='text'
						placeholder='pedroperez@gmail.com'
						value={EmailState}
						onChange={(e) => {
							setEmailState(e.target.value)
						}}
						onFocus={(e) => {
							setEmail(true)
						}}
						onBlur={(e) => {
							HandleEmail(e, e.target.value)
						}}
					/>
				</div>
				{EmailError === false && (
					<p className='error_code'>El usuario/contrasena es invalido</p>
				)}
				<Link className='no_email'>No recuerdo mi correo electrónico</Link>
				<div className='button_wrapper button_wrapper_new'>
					<button onClick={handleLogin}>Aceptar</button>

					<Link>Cancelar</Link>
				</div>
			</form>
			<img src={PromoBooster} className='logo_one' alt='' />
		</div>
	)
}
