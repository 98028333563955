import React, {useEffect, useState} from 'react'

export const ActivateSidebar = () => {
	const [Height, setHeight] = useState(0)
	const HandleResizeWork = () => {
		const WindowHeight = window.innerHeight - 480
		setHeight(WindowHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', HandleResizeWork)
		HandleResizeWork()
	}, [])
	return (
		<div className='AddProductSdebar'>
			<div className='topbar'>
				<h1>Actividad</h1>
			</div>

			<div className='comment-wrapper-active-sidebar'>
				<div className='comment-area comment-area-active-sidebar'>
					<div className='text_box false'>
						<textarea
							cols='30'
							rows='10'
							placeholder='Escribe un comentario...'
						></textarea>
						<div className='bottom_area'>
							<button>Comentar</button>
						</div>
					</div>
				</div>

				<div className='comments_area' style={{height: `${Height}px`}}>
					<div className='box edition'>
						<div className='top_area_box'>
							<div className='avatar'>
								<p>MC</p>
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										fill='#DBE7FF'
									></rect>
									<mask
										id='mask0_173_915850'
										maskUnits='userSpaceOnUse'
										x='3'
										y='3'
										width='10'
										height='10'
									>
										<rect
											x='3'
											y='3'
											width='10'
											height='10'
											fill='#D9D9D9'
										></rect>
									</mask>
									<g mask='url(#mask0_173_915850)'>
										<path
											d='M6.39596 10.1145C6.27096 9.98256 6.20846 9.82811 6.20846 9.65117C6.20846 9.47395 6.27096 9.32284 6.39596 9.19784L6.93762 8.65617H4.40637C4.22582 8.65617 4.07137 8.59186 3.94304 8.46325C3.81443 8.33492 3.75012 8.18047 3.75012 7.99992C3.75012 7.81936 3.81443 7.66478 3.94304 7.53617C4.07137 7.40784 4.22582 7.34367 4.40637 7.34367H6.93762L6.38554 6.79159C6.26054 6.66659 6.19984 6.51547 6.20346 6.33825C6.20679 6.16131 6.27096 6.01034 6.39596 5.88534C6.52096 5.76034 6.67373 5.69784 6.85429 5.69784C7.03484 5.69784 7.18762 5.76034 7.31262 5.88534L8.97929 7.54159C9.04179 7.60409 9.08693 7.6752 9.11471 7.75492C9.14248 7.83492 9.15637 7.91659 9.15637 7.99992C9.15637 8.08325 9.14248 8.16478 9.11471 8.2445C9.08693 8.3245 9.04179 8.39575 8.97929 8.45825L7.30221 10.1249C7.17721 10.2499 7.02623 10.3108 6.84929 10.3074C6.67207 10.3038 6.52096 10.2395 6.39596 10.1145ZM8.57304 12.1041C8.39248 12.1041 8.23804 12.0399 8.10971 11.9116C7.98109 11.783 7.91679 11.6284 7.91679 11.4478C7.91679 11.2673 7.98109 11.1128 8.10971 10.9845C8.23804 10.8559 8.39248 10.7916 8.57304 10.7916H10.9376V5.20825H8.57304C8.39248 5.20825 8.23804 5.14409 8.10971 5.01575C7.98109 4.88714 7.91679 4.73256 7.91679 4.552C7.91679 4.37145 7.98109 4.21686 8.10971 4.08825C8.23804 3.95992 8.39248 3.89575 8.57304 3.89575H10.9376C11.3057 3.89575 11.6165 4.02242 11.8701 4.27575C12.1235 4.52936 12.2501 4.8402 12.2501 5.20825V10.7916C12.2501 11.1596 12.1235 11.4705 11.8701 11.7241C11.6165 11.9774 11.3057 12.1041 10.9376 12.1041H8.57304Z'
											fill='#3676F5'
										></path>
									</g>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										stroke='white'
									></rect>
								</svg>
							</div>
							<div className='presentation'>
								<h1>Mario Carcamo</h1>
								<p>movió la promoción a</p>
								<span>En Edición</span>
								<p>hace 2 días</p>
							</div>
						</div>
					</div>
					<div className='box edition'>
						<div className='top_area_box'>
							<div className='avatar'>
								<p>MC</p>
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										fill='#DBE7FF'
									></rect>
									<mask
										id='mask0_173_915850'
										maskUnits='userSpaceOnUse'
										x='3'
										y='3'
										width='10'
										height='10'
									>
										<rect
											x='3'
											y='3'
											width='10'
											height='10'
											fill='#D9D9D9'
										></rect>
									</mask>
									<g mask='url(#mask0_173_915850)'>
										<path
											d='M6.39596 10.1145C6.27096 9.98256 6.20846 9.82811 6.20846 9.65117C6.20846 9.47395 6.27096 9.32284 6.39596 9.19784L6.93762 8.65617H4.40637C4.22582 8.65617 4.07137 8.59186 3.94304 8.46325C3.81443 8.33492 3.75012 8.18047 3.75012 7.99992C3.75012 7.81936 3.81443 7.66478 3.94304 7.53617C4.07137 7.40784 4.22582 7.34367 4.40637 7.34367H6.93762L6.38554 6.79159C6.26054 6.66659 6.19984 6.51547 6.20346 6.33825C6.20679 6.16131 6.27096 6.01034 6.39596 5.88534C6.52096 5.76034 6.67373 5.69784 6.85429 5.69784C7.03484 5.69784 7.18762 5.76034 7.31262 5.88534L8.97929 7.54159C9.04179 7.60409 9.08693 7.6752 9.11471 7.75492C9.14248 7.83492 9.15637 7.91659 9.15637 7.99992C9.15637 8.08325 9.14248 8.16478 9.11471 8.2445C9.08693 8.3245 9.04179 8.39575 8.97929 8.45825L7.30221 10.1249C7.17721 10.2499 7.02623 10.3108 6.84929 10.3074C6.67207 10.3038 6.52096 10.2395 6.39596 10.1145ZM8.57304 12.1041C8.39248 12.1041 8.23804 12.0399 8.10971 11.9116C7.98109 11.783 7.91679 11.6284 7.91679 11.4478C7.91679 11.2673 7.98109 11.1128 8.10971 10.9845C8.23804 10.8559 8.39248 10.7916 8.57304 10.7916H10.9376V5.20825H8.57304C8.39248 5.20825 8.23804 5.14409 8.10971 5.01575C7.98109 4.88714 7.91679 4.73256 7.91679 4.552C7.91679 4.37145 7.98109 4.21686 8.10971 4.08825C8.23804 3.95992 8.39248 3.89575 8.57304 3.89575H10.9376C11.3057 3.89575 11.6165 4.02242 11.8701 4.27575C12.1235 4.52936 12.2501 4.8402 12.2501 5.20825V10.7916C12.2501 11.1596 12.1235 11.4705 11.8701 11.7241C11.6165 11.9774 11.3057 12.1041 10.9376 12.1041H8.57304Z'
											fill='#3676F5'
										></path>
									</g>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										stroke='white'
									></rect>
								</svg>
							</div>
							<div className='presentation'>
								<h1>Mario Carcamo</h1>
								<p>movió la promoción a</p>
								<span>En Edición</span>
								<p>hace 2 días</p>
							</div>
						</div>
					</div>
					<div className='box edition'>
						<div className='top_area_box'>
							<div className='avatar'>
								<p>MC</p>
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										fill='#DBE7FF'
									></rect>
									<mask
										id='mask0_173_915850'
										maskUnits='userSpaceOnUse'
										x='3'
										y='3'
										width='10'
										height='10'
									>
										<rect
											x='3'
											y='3'
											width='10'
											height='10'
											fill='#D9D9D9'
										></rect>
									</mask>
									<g mask='url(#mask0_173_915850)'>
										<path
											d='M6.39596 10.1145C6.27096 9.98256 6.20846 9.82811 6.20846 9.65117C6.20846 9.47395 6.27096 9.32284 6.39596 9.19784L6.93762 8.65617H4.40637C4.22582 8.65617 4.07137 8.59186 3.94304 8.46325C3.81443 8.33492 3.75012 8.18047 3.75012 7.99992C3.75012 7.81936 3.81443 7.66478 3.94304 7.53617C4.07137 7.40784 4.22582 7.34367 4.40637 7.34367H6.93762L6.38554 6.79159C6.26054 6.66659 6.19984 6.51547 6.20346 6.33825C6.20679 6.16131 6.27096 6.01034 6.39596 5.88534C6.52096 5.76034 6.67373 5.69784 6.85429 5.69784C7.03484 5.69784 7.18762 5.76034 7.31262 5.88534L8.97929 7.54159C9.04179 7.60409 9.08693 7.6752 9.11471 7.75492C9.14248 7.83492 9.15637 7.91659 9.15637 7.99992C9.15637 8.08325 9.14248 8.16478 9.11471 8.2445C9.08693 8.3245 9.04179 8.39575 8.97929 8.45825L7.30221 10.1249C7.17721 10.2499 7.02623 10.3108 6.84929 10.3074C6.67207 10.3038 6.52096 10.2395 6.39596 10.1145ZM8.57304 12.1041C8.39248 12.1041 8.23804 12.0399 8.10971 11.9116C7.98109 11.783 7.91679 11.6284 7.91679 11.4478C7.91679 11.2673 7.98109 11.1128 8.10971 10.9845C8.23804 10.8559 8.39248 10.7916 8.57304 10.7916H10.9376V5.20825H8.57304C8.39248 5.20825 8.23804 5.14409 8.10971 5.01575C7.98109 4.88714 7.91679 4.73256 7.91679 4.552C7.91679 4.37145 7.98109 4.21686 8.10971 4.08825C8.23804 3.95992 8.39248 3.89575 8.57304 3.89575H10.9376C11.3057 3.89575 11.6165 4.02242 11.8701 4.27575C12.1235 4.52936 12.2501 4.8402 12.2501 5.20825V10.7916C12.2501 11.1596 12.1235 11.4705 11.8701 11.7241C11.6165 11.9774 11.3057 12.1041 10.9376 12.1041H8.57304Z'
											fill='#3676F5'
										></path>
									</g>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										stroke='white'
									></rect>
								</svg>
							</div>
							<div className='presentation'>
								<h1>Mario Carcamo</h1>
								<p>movió la promoción a</p>
								<span>En Edición</span>
								<p>hace 2 días</p>
							</div>
						</div>
					</div>
					<div className='box edition'>
						<div className='top_area_box'>
							<div className='avatar'>
								<p>MC</p>
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										fill='#DBE7FF'
									></rect>
									<mask
										id='mask0_173_915850'
										maskUnits='userSpaceOnUse'
										x='3'
										y='3'
										width='10'
										height='10'
									>
										<rect
											x='3'
											y='3'
											width='10'
											height='10'
											fill='#D9D9D9'
										></rect>
									</mask>
									<g mask='url(#mask0_173_915850)'>
										<path
											d='M6.39596 10.1145C6.27096 9.98256 6.20846 9.82811 6.20846 9.65117C6.20846 9.47395 6.27096 9.32284 6.39596 9.19784L6.93762 8.65617H4.40637C4.22582 8.65617 4.07137 8.59186 3.94304 8.46325C3.81443 8.33492 3.75012 8.18047 3.75012 7.99992C3.75012 7.81936 3.81443 7.66478 3.94304 7.53617C4.07137 7.40784 4.22582 7.34367 4.40637 7.34367H6.93762L6.38554 6.79159C6.26054 6.66659 6.19984 6.51547 6.20346 6.33825C6.20679 6.16131 6.27096 6.01034 6.39596 5.88534C6.52096 5.76034 6.67373 5.69784 6.85429 5.69784C7.03484 5.69784 7.18762 5.76034 7.31262 5.88534L8.97929 7.54159C9.04179 7.60409 9.08693 7.6752 9.11471 7.75492C9.14248 7.83492 9.15637 7.91659 9.15637 7.99992C9.15637 8.08325 9.14248 8.16478 9.11471 8.2445C9.08693 8.3245 9.04179 8.39575 8.97929 8.45825L7.30221 10.1249C7.17721 10.2499 7.02623 10.3108 6.84929 10.3074C6.67207 10.3038 6.52096 10.2395 6.39596 10.1145ZM8.57304 12.1041C8.39248 12.1041 8.23804 12.0399 8.10971 11.9116C7.98109 11.783 7.91679 11.6284 7.91679 11.4478C7.91679 11.2673 7.98109 11.1128 8.10971 10.9845C8.23804 10.8559 8.39248 10.7916 8.57304 10.7916H10.9376V5.20825H8.57304C8.39248 5.20825 8.23804 5.14409 8.10971 5.01575C7.98109 4.88714 7.91679 4.73256 7.91679 4.552C7.91679 4.37145 7.98109 4.21686 8.10971 4.08825C8.23804 3.95992 8.39248 3.89575 8.57304 3.89575H10.9376C11.3057 3.89575 11.6165 4.02242 11.8701 4.27575C12.1235 4.52936 12.2501 4.8402 12.2501 5.20825V10.7916C12.2501 11.1596 12.1235 11.4705 11.8701 11.7241C11.6165 11.9774 11.3057 12.1041 10.9376 12.1041H8.57304Z'
											fill='#3676F5'
										></path>
									</g>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										stroke='white'
									></rect>
								</svg>
							</div>
							<div className='presentation'>
								<h1>Mario Carcamo</h1>
								<p>movió la promoción a</p>
								<span>En Edición</span>
								<p>hace 2 días</p>
							</div>
						</div>
					</div>
					<div className='box edition'>
						<div className='top_area_box'>
							<div className='avatar'>
								<p>Aa</p>
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										fill='#BDE9E8'
									></rect>
									<mask
										id='mask0_173_915941'
										maskUnits='userSpaceOnUse'
										x='3'
										y='3'
										width='10'
										height='10'
									>
										<rect
											x='3'
											y='3'
											width='10'
											height='10'
											fill='#D9D9D9'
										></rect>
									</mask>
									<g mask='url(#mask0_173_915941)'>
										<path
											d='M3.75012 11.1042V4.69792C3.75012 4.43403 3.84207 4.21 4.02596 4.02583C4.21012 3.84194 4.43415 3.75 4.69804 3.75H11.3022C11.5661 3.75 11.7901 3.84194 11.9743 4.02583C12.1582 4.21 12.2501 4.43403 12.2501 4.69792V9.63542C12.2501 9.89931 12.1582 10.1233 11.9743 10.3075C11.7901 10.4914 11.5661 10.5833 11.3022 10.5833H5.41679L4.55221 11.4479C4.40637 11.5938 4.23623 11.6268 4.04179 11.5471C3.84734 11.4671 3.75012 11.3194 3.75012 11.1042Z'
											fill='#29BAB8'
										></path>
									</g>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										stroke='white'
									></rect>
								</svg>
							</div>
							<div className='presentation'>
								<h1>Ana Armas</h1>
								<p>hace 15min</p>
							</div>
						</div>
						<p>Por favor saquemos estos productos</p>
					</div>
					<div className='box edition'>
						<div className='top_area_box'>
							<div className='avatar'>
								<p>MC</p>
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										fill='#DBE7FF'
									></rect>
									<mask
										id='mask0_173_915850'
										maskUnits='userSpaceOnUse'
										x='3'
										y='3'
										width='10'
										height='10'
									>
										<rect
											x='3'
											y='3'
											width='10'
											height='10'
											fill='#D9D9D9'
										></rect>
									</mask>
									<g mask='url(#mask0_173_915850)'>
										<path
											d='M6.39596 10.1145C6.27096 9.98256 6.20846 9.82811 6.20846 9.65117C6.20846 9.47395 6.27096 9.32284 6.39596 9.19784L6.93762 8.65617H4.40637C4.22582 8.65617 4.07137 8.59186 3.94304 8.46325C3.81443 8.33492 3.75012 8.18047 3.75012 7.99992C3.75012 7.81936 3.81443 7.66478 3.94304 7.53617C4.07137 7.40784 4.22582 7.34367 4.40637 7.34367H6.93762L6.38554 6.79159C6.26054 6.66659 6.19984 6.51547 6.20346 6.33825C6.20679 6.16131 6.27096 6.01034 6.39596 5.88534C6.52096 5.76034 6.67373 5.69784 6.85429 5.69784C7.03484 5.69784 7.18762 5.76034 7.31262 5.88534L8.97929 7.54159C9.04179 7.60409 9.08693 7.6752 9.11471 7.75492C9.14248 7.83492 9.15637 7.91659 9.15637 7.99992C9.15637 8.08325 9.14248 8.16478 9.11471 8.2445C9.08693 8.3245 9.04179 8.39575 8.97929 8.45825L7.30221 10.1249C7.17721 10.2499 7.02623 10.3108 6.84929 10.3074C6.67207 10.3038 6.52096 10.2395 6.39596 10.1145ZM8.57304 12.1041C8.39248 12.1041 8.23804 12.0399 8.10971 11.9116C7.98109 11.783 7.91679 11.6284 7.91679 11.4478C7.91679 11.2673 7.98109 11.1128 8.10971 10.9845C8.23804 10.8559 8.39248 10.7916 8.57304 10.7916H10.9376V5.20825H8.57304C8.39248 5.20825 8.23804 5.14409 8.10971 5.01575C7.98109 4.88714 7.91679 4.73256 7.91679 4.552C7.91679 4.37145 7.98109 4.21686 8.10971 4.08825C8.23804 3.95992 8.39248 3.89575 8.57304 3.89575H10.9376C11.3057 3.89575 11.6165 4.02242 11.8701 4.27575C12.1235 4.52936 12.2501 4.8402 12.2501 5.20825V10.7916C12.2501 11.1596 12.1235 11.4705 11.8701 11.7241C11.6165 11.9774 11.3057 12.1041 10.9376 12.1041H8.57304Z'
											fill='#3676F5'
										></path>
									</g>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										stroke='white'
									></rect>
								</svg>
							</div>
							<div className='presentation'>
								<h1>Mario Carcamo</h1>
								<p>movió la promoción a</p>
								<span>En Edición</span>
								<p>hace 2 días</p>
							</div>
						</div>
					</div>

					<div className='box edition'>
						<div className='top_area_box'>
							<div className='avatar'>
								<p>Aa</p>
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										fill='#BDE9E8'
									></rect>
									<mask
										id='mask0_173_915941'
										maskUnits='userSpaceOnUse'
										x='3'
										y='3'
										width='10'
										height='10'
									>
										<rect
											x='3'
											y='3'
											width='10'
											height='10'
											fill='#D9D9D9'
										></rect>
									</mask>
									<g mask='url(#mask0_173_915941)'>
										<path
											d='M3.75012 11.1042V4.69792C3.75012 4.43403 3.84207 4.21 4.02596 4.02583C4.21012 3.84194 4.43415 3.75 4.69804 3.75H11.3022C11.5661 3.75 11.7901 3.84194 11.9743 4.02583C12.1582 4.21 12.2501 4.43403 12.2501 4.69792V9.63542C12.2501 9.89931 12.1582 10.1233 11.9743 10.3075C11.7901 10.4914 11.5661 10.5833 11.3022 10.5833H5.41679L4.55221 11.4479C4.40637 11.5938 4.23623 11.6268 4.04179 11.5471C3.84734 11.4671 3.75012 11.3194 3.75012 11.1042Z'
											fill='#29BAB8'
										></path>
									</g>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										stroke='white'
									></rect>
								</svg>
							</div>
							<div className='presentation'>
								<h1>Ana Armas</h1>
								<p>hace 15min</p>
							</div>
						</div>
						<p>Por favor saquemos estos productos</p>
					</div>

					<div className='box edition'>
						<div className='top_area_box'>
							<div className='avatar'>
								<p>Aa</p>
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										fill='#BDE9E8'
									></rect>
									<mask
										id='mask0_173_915941'
										maskUnits='userSpaceOnUse'
										x='3'
										y='3'
										width='10'
										height='10'
									>
										<rect
											x='3'
											y='3'
											width='10'
											height='10'
											fill='#D9D9D9'
										></rect>
									</mask>
									<g mask='url(#mask0_173_915941)'>
										<path
											d='M3.75012 11.1042V4.69792C3.75012 4.43403 3.84207 4.21 4.02596 4.02583C4.21012 3.84194 4.43415 3.75 4.69804 3.75H11.3022C11.5661 3.75 11.7901 3.84194 11.9743 4.02583C12.1582 4.21 12.2501 4.43403 12.2501 4.69792V9.63542C12.2501 9.89931 12.1582 10.1233 11.9743 10.3075C11.7901 10.4914 11.5661 10.5833 11.3022 10.5833H5.41679L4.55221 11.4479C4.40637 11.5938 4.23623 11.6268 4.04179 11.5471C3.84734 11.4671 3.75012 11.3194 3.75012 11.1042Z'
											fill='#29BAB8'
										></path>
									</g>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										stroke='white'
									></rect>
								</svg>
							</div>
							<div className='presentation'>
								<h1>Ana Armas</h1>
								<p>hace 15min</p>
							</div>
						</div>
						<p>Por favor saquemos estos productos</p>
					</div>
					<div className='box edition'>
						<div className='top_area_box'>
							<div className='avatar'>
								<p>Aa</p>
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										fill='#BDE9E8'
									></rect>
									<mask
										id='mask0_173_915941'
										maskUnits='userSpaceOnUse'
										x='3'
										y='3'
										width='10'
										height='10'
									>
										<rect
											x='3'
											y='3'
											width='10'
											height='10'
											fill='#D9D9D9'
										></rect>
									</mask>
									<g mask='url(#mask0_173_915941)'>
										<path
											d='M3.75012 11.1042V4.69792C3.75012 4.43403 3.84207 4.21 4.02596 4.02583C4.21012 3.84194 4.43415 3.75 4.69804 3.75H11.3022C11.5661 3.75 11.7901 3.84194 11.9743 4.02583C12.1582 4.21 12.2501 4.43403 12.2501 4.69792V9.63542C12.2501 9.89931 12.1582 10.1233 11.9743 10.3075C11.7901 10.4914 11.5661 10.5833 11.3022 10.5833H5.41679L4.55221 11.4479C4.40637 11.5938 4.23623 11.6268 4.04179 11.5471C3.84734 11.4671 3.75012 11.3194 3.75012 11.1042Z'
											fill='#29BAB8'
										></path>
									</g>
									<rect
										x='0.5'
										y='0.5'
										width='15'
										height='15'
										rx='7.5'
										stroke='white'
									></rect>
								</svg>
							</div>
							<div className='presentation'>
								<h1>Ana Armas</h1>
								<p>hace 15min</p>
							</div>
						</div>
						<p>Por favor saquemos estos productos</p>
					</div>
				</div>
			</div>

			<div className='bottom-area'>
				<button className='button-cancel'>Cerrar</button>
			</div>
		</div>
	)
}
