import React, {useEffect, useState} from 'react'
import Banner1Login from '../assets/img/Banner1Login.png'
import Banner2Login from '../assets/img/Banner2Login.png'
import blackGreenFull from '../assets/img/Full_Negro+Verde.svg'
import '../assets/css/LoginNew.css'
import {useNavigate} from 'react-router-dom'
export const EmailCheck = () => {
	const [activeScreen, setActiveScreen] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		setTimeout(() => {
			setActiveScreen(true)
		}, 200)
	}, [])
	return (
		<div
			className={`LoginNew ForgetPasswordNew  EmailCheck ${
				activeScreen && 'EmailCheckActive'
			}`}
		>
			<img src={Banner1Login} alt='' className='Banner1Login' />
			<img src={Banner2Login} alt='' className='Banner2Login' />
			<img src={blackGreenFull} alt='' className='Full_NegroVerde' />

			<form action='#'>
				<div className='top_box'>
					<svg
						width='52'
						height='52'
						viewBox='0 0 52 52'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect width='52' height='52' rx='26' fill='#F0F4FC' />
						<mask
							id='mask0_756_241752'
							maskUnits='userSpaceOnUse'
							x='6'
							y='6'
							width='40'
							height='40'
						>
							<rect x='6' y='6' width='40' height='40' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_756_241752)'>
							<path
								d='M12.7917 39.6666C11.7361 39.6666 10.84 39.2988 10.1033 38.5633C9.36778 37.8266 9 36.9305 9 35.8749V16.1249C9 15.0694 9.36778 14.1733 10.1033 13.4366C10.84 12.701 11.7361 12.3333 12.7917 12.3333H39.2083C40.2639 12.3333 41.16 12.701 41.8967 13.4366C42.6322 14.1733 43 15.0694 43 16.1249V35.8749C43 36.9305 42.6322 37.8266 41.8967 38.5633C41.16 39.2988 40.2639 39.6666 39.2083 39.6666H12.7917ZM39.2083 19.7499L27 27.4583C26.8333 27.5694 26.6667 27.6455 26.5 27.6866C26.3333 27.7288 26.1667 27.7499 26 27.7499C25.8333 27.7499 25.6667 27.7288 25.5 27.6866C25.3333 27.6455 25.1667 27.5694 25 27.4583L12.7917 19.7499V35.8749H39.2083V19.7499ZM26 24.4583L39.2083 16.1249H12.7917L26 24.4583ZM12.7917 20.2083V17.5416V17.5833C12.7917 17.2777 12.7917 17.2777 12.7917 17.5833V20.2083Z'
								fill='#3676F5'
							/>
						</g>
					</svg>
				</div>
				<div className='body_area'>
					<p>Revisa tu Email</p>
					<p>
						Recibirás un link para poder reestablecer <br /> tu contraseña
					</p>
					<span>Recuerda revisar tu carpeta de Spam</span>

					<div className='button_wrapper'>
						<button
							onClick={(e) => {
								navigate('/SetPasswordNew')
							}}
						>
							Aceptar
							<svg
								width='17'
								height='16'
								viewBox='0 0 17 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<mask
									id='mask0_459_64902'
									maskUnits='userSpaceOnUse'
									x='2'
									y='2'
									width='13'
									height='12'
								>
									<rect x='2.5' y='2' width='12' height='12' fill='#D9D9D9' />
								</mask>
								<g mask='url(#mask0_459_64902)'>
									<path
										d='M6.02451 12.6C5.89118 12.4583 5.82451 12.2937 5.82451 12.106C5.82451 11.9187 5.89118 11.7542 6.02451 11.6125L9.63701 8L6.01201 4.375C5.87868 4.24167 5.81201 4.07917 5.81201 3.8875C5.81201 3.69583 5.88285 3.52917 6.02451 3.3875C6.15785 3.25417 6.32034 3.1875 6.51201 3.1875C6.70368 3.1875 6.87034 3.25417 7.01201 3.3875L11.212 7.6C11.2703 7.65833 11.312 7.72083 11.337 7.7875C11.362 7.85417 11.3745 7.925 11.3745 8C11.3745 8.075 11.362 8.14583 11.337 8.2125C11.312 8.27917 11.2703 8.34167 11.212 8.4L6.99951 12.6125C6.86618 12.7458 6.70568 12.8125 6.51801 12.8125C6.33068 12.8125 6.16618 12.7417 6.02451 12.6Z'
										fill='white'
									/>
								</g>
							</svg>
						</button>
					</div>
				</div>
			</form>
		</div>
	)
}
