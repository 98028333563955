import React from 'react'
import iconCatalog from '../assets/img/Icono_cata.svg'
export const SaveCatalog = ({setActive, setSaved}) => {
	return (
		<div
			className='popupWrapper'
			id='popupCatalog'
			style={{position: 'fixed', zIndex: 9999}}
			onClick={(e) => {
				if (e.target.id === 'popupCatalog') {
					setActive(false)
				}
			}}
		>
			<div className='popup SaveCatalog'>
				<img src={iconCatalog} alt='' />
				<h1>¿Estás seguro que deseas guardar el Catálogo?</h1>
				<p>Al guardarlo, quedará en el Historial de Versiones</p>
				<div className='buttons_wrapper'>
					<button
						onClick={(e) => {
							setActive(false)
						}}
					>
						Seguir Editando
					</button>
					<button
						onClick={(e) => {
							setActive(false)
							setSaved(true)
						}}
					>
						Guardar Catálogo
					</button>
				</div>
			</div>
		</div>
	)
}
