import {Link} from 'react-router-dom'

export const LinksShow = () => {
	return (
		<div className='LinksShowWrapper'>
			<div className='LinksShow'>
				<h1>All Links</h1>
				<ul>
					<li>
						<Link to='/LoginNew'>Login</Link>
					</li>
					<li>
						<Link to='/ProductSelection'>ProductSelection</Link>
					</li>
					<li>
						<Link to='/ForgetPasswordNew'>ForgetPasswordNew</Link>
					</li>
					<li>
						<Link to='/EmailCheck'>EmailCheck</Link>
					</li>
					<li>
						<Link to='/SetPasswordNew'>SetPasswordNew</Link>
					</li>
					<li>
						<Link to='/PasswordChangeDone'>PasswordChangeDone</Link>
					</li>
					<li>
						<Link to='/IngresoPromocion'>Ingreso Promocion</Link>
					</li>
					<li>
						<Link to='/IngresoPromocionResumen'>Ingreso Promocion Resumen</Link>
					</li>
					<li>
						<Link to='/CatalogSearch'> CatalogSearch</Link>
					</li>

					<li>
						<Link to='/Settings'>Settings</Link>
					</li>
					<li>
						<Link to='/HomeNew'>Home</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}
