import '../../assets/css/chat.css'
import logoChatBot from '../../assets/img/logoChatBot.png'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
	Header,
} from '../../componentsRef'
export const ChatBotPage = () => {
    const [urlBase, setUrlBase] = useState('https://cleaner.pricemaker.io/');
    const [chatSelected, setChatSelected] = useState(null);
    const chatboInfo = localStorage.getItem('user_data');
    console.log("INFO CHAT::: ");
    console.log(chatboInfo);
    const chatboInfoParsed = JSON.parse(chatboInfo);
    console.log("INFO CHAT2::: ");
    console.log(chatboInfoParsed);

    const cadenas = [];

    chatboInfoParsed.promogpt_bots.forEach((element) => {
        cadenas.push(element.customer_name);
    });

    const html1 = `
    <div id="customgpt_chat"></div>
<script src="https://cdn.customgpt.ai/js/embed.js" div_id="customgpt_chat" p_id="17887" p_key="55471285b82de74c88c61318250cb369" width="100%" height="500px"></script>
    `;

    useEffect(() => {
        console.log("HA CAMBIADO EL CHAT");
        switch (chatSelected) {
            case 'JUMBO':
                setUrlBase('https://cleaner.pricemaker.io/pm/chatbot?customer=YYUMV00211');
                break;
            case 'UNIMARC':
                setUrlBase('https://cleaner.pricemaker.io/pm/chatbot?customer=UUN1M4RC222');
                break;
            default:
                setChatSelected(null);
        }
    }, [chatSelected]);
	
    return (
        <>
		<div className='HomeNew'>
			<Header />
			<div className='body_area_home_new'>
				<div className='top_area_header'>
					<div className='box'>
						
						<select className='select'
                        onChange={(e) => {
                            setChatSelected(e.target.value);
                        }}
                        >
                            <option value={null}>Seleccione Agente GPT</option>
                            {cadenas.map((cadena, index) => (
                                <option value={cadena} key={index}
                                
                                >Agente GPT {cadena}</option>
                            ))}
                        </select>
					</div>

                    <div className='box'>
					<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_1239_29292'
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='32'
								height='32'
							>
								<rect x='0' y='0' width='32' height='32' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_1239_29292)'>
								<path
									d='M16.8861 18.6668C17.1195 18.9001 17.4065 19.0168 17.7471 19.0168C18.087 19.0168 18.3834 18.8904 18.6361 18.6376C18.8695 18.4043 18.9861 18.1075 18.9861 17.7474C18.9861 17.3881 18.8695 17.0918 18.6361 16.8584L15.282 13.4751V9.4501C15.282 9.08065 15.1606 8.77926 14.918 8.54593C14.6745 8.3126 14.3681 8.19593 13.9986 8.19593C13.6292 8.19593 13.3231 8.31726 13.0805 8.55993C12.837 8.80338 12.7153 9.10982 12.7153 9.47926V13.9418C12.7153 14.1168 12.7495 14.2867 12.818 14.4516C12.8856 14.6173 12.9875 14.7682 13.1236 14.9043L16.8861 18.6668ZM13.9986 25.9001C12.3459 25.9001 10.795 25.589 9.34597 24.9668C7.89774 24.3445 6.63891 23.4987 5.56947 22.4293C4.50002 21.3598 3.65419 20.101 3.03197 18.6528C2.40974 17.2038 2.09863 15.6529 2.09863 14.0001C2.09863 12.3473 2.40974 10.7964 3.03197 9.34743C3.65419 7.89921 4.50002 6.64038 5.56947 5.57093C6.63891 4.50149 7.89774 3.65565 9.34597 3.03343C10.795 2.41121 12.3459 2.1001 13.9986 2.1001C15.6514 2.1001 17.2023 2.41121 18.6513 3.03343C20.0995 3.65565 21.3584 4.50149 22.4278 5.57093C23.4972 6.64038 24.3431 7.89921 24.9653 9.34743C25.5875 10.7964 25.8986 12.3473 25.8986 14.0001C25.8986 15.6529 25.5875 17.2038 24.9653 18.6528C24.3431 20.101 23.4972 21.3598 22.4278 22.4293C21.3584 23.4987 20.0995 24.3445 18.6513 24.9668C17.2023 25.589 15.6514 25.9001 13.9986 25.9001ZM13.9986 23.2459C16.5653 23.2459 18.7481 22.3468 20.5471 20.5486C22.3454 18.7496 23.2445 16.5668 23.2445 14.0001C23.2445 11.4334 22.3454 9.2506 20.5471 7.4516C18.7481 5.65338 16.5653 4.75426 13.9986 4.75426C11.432 4.75426 9.24952 5.65338 7.4513 7.4516C5.6523 9.2506 4.7528 11.4334 4.7528 14.0001C4.7528 16.5668 5.6523 18.7496 7.4513 20.5486C9.24952 22.3468 11.432 23.2459 13.9986 23.2459Z'
									fill='url(#paint0_linear_552_297425)'
								/>
							</g>
							<defs>
								<linearGradient
									id='paint0_linear_552_297425'
									x1='5.86755'
									y1='6.22919'
									x2='31.9222'
									y2='8.12482'
									gradientUnits='userSpaceOnUse'
								>
									<stop stopColor='#FFAC23' />
									<stop offset='1' stopColor='#FF5334' />
								</linearGradient>
							</defs>
						</svg>

						<p>Planificación Promocional</p>
						<button
						onClick={(e) => {
							window.location.href = "/Dashboard/planificacion"
						}}
						>Ver</button>
					</div>
					<div className='box'>
						<svg
							width='29'
							height='28'
							viewBox='0 0 29 28'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_552_208343'
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='29'
								height='28'
							>
								<rect x='0.333252' width='28' height='28' fill='#D9D9D9' />
							</mask>
							<g mask='ur(#mask0_201_551405)'>
								<path
									d='M11.0542 14.6419L4.90002 20.796C4.64725 21.0488 4.33614 21.1701 3.96669 21.16C3.59725 21.1507 3.28614 21.0196 3.03336 20.7669C2.78058 20.4946 2.64952 20.1835 2.64019 19.8335C2.63008 19.4835 2.76114 19.1724 3.03336 18.9002L10.1209 11.8127C10.3931 11.5405 10.7042 11.4044 11.0542 11.4044C11.4042 11.4044 11.7153 11.5405 11.9875 11.8127L15.7209 15.546L18.9875 11.8419C17.9959 10.6946 16.8436 9.79514 15.5307 9.14336C14.2186 8.49236 12.7653 8.16686 11.1709 8.16686C10.0431 8.16686 8.95419 8.34692 7.90419 8.70703C6.85419 9.06636 5.88197 9.56686 4.98753 10.2085C4.65697 10.4419 4.30191 10.5535 3.92236 10.5434C3.54358 10.534 3.23752 10.3738 3.00419 10.0627C2.75141 9.7127 2.67364 9.34792 2.77086 8.96836C2.86808 8.58958 3.09169 8.27381 3.44169 8.02103C4.55002 7.22381 5.75558 6.60625 7.05836 6.16836C8.36114 5.73125 9.73197 5.5127 11.1709 5.5127C13.0959 5.5127 14.8801 5.89653 16.5235 6.6642C18.1662 7.43264 19.5903 8.49742 20.7959 9.85853L22.9542 7.37936C23.2264 7.0877 23.5523 6.94653 23.9319 6.95586C24.3106 6.96597 24.6361 7.0877 24.9084 7.32103C25.1417 7.57381 25.2681 7.8752 25.2875 8.2252C25.307 8.5752 25.1903 8.88631 24.9375 9.15853L22.4292 11.9877C22.9736 12.8238 23.4259 13.7183 23.786 14.671C24.1454 15.6238 24.4125 16.6349 24.5875 17.7044C24.6653 18.0933 24.5926 18.4479 24.3694 18.7684C24.1454 19.0896 23.8195 19.2502 23.3917 19.2502C22.9639 19.2502 22.638 19.1433 22.414 18.9294C22.1908 18.7155 22.0209 18.3752 21.9042 17.9085C21.7681 17.228 21.5834 16.5618 21.35 15.91C21.1167 15.259 20.8445 14.6516 20.5334 14.0877L16.7709 18.3169C16.5181 18.6085 16.1972 18.759 15.8084 18.7684C15.4195 18.7785 15.0986 18.6474 14.8459 18.3752L11.0542 14.6419Z'
									fill='url(#paint0_linear_552_208343)'
								/>
							</g>
							<defs>
								<linearGradient
									id='paint0_linear_552_208343'
									x1='15.006'
									y1='0.686446'
									x2='33.7812'
									y2='21.9797'
									gradientUnits='userSpaceOnUse'
								>
									<stop stopColor='#FFAC23' />
									<stop offset='1' stopColor='#FF5334' />
								</linearGradient>
							</defs>
						</svg>

						<p>Evaluación Promociones</p>
						<button 
						onClick={(e) => {
							window.location.href = "/Dashboard/evaluacion"
						}}
						>
							Ver
						</button>
					</div>
					<div className='box'>
						
						<svg
							width='33'
							height='32'
							viewBox='0 0 33 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_552_208343'
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='29'
								height='28'
							>
								<rect x='0.333252' width='28' height='28' fill='#D9D9D9' />
							</mask>
							<g mask='ur(#mask0_201_551405)'>
								<path
									d='M8.42113 26.8936C7.69242 26.8799 7.06683 26.6118 6.54435 26.0893C6.02188 25.5668 5.75377 24.9412 5.74002 24.2125L5.74002 9.69325C5.75377 9.34952 5.82609 9.00936 5.95698 8.67278C6.08733 8.33565 6.28312 8.03646 6.54435 7.77523L12.4428 1.87678C12.9653 1.35431 13.5911 1.09307 14.3204 1.09307C15.0486 1.09307 15.6739 1.35431 16.1964 1.87678L22.0948 7.77523C22.356 8.03646 22.5521 8.33537 22.683 8.67196C22.8133 9.00909 22.8854 9.34952 22.8991 9.69325L22.8785 24.2331C22.8785 24.9481 22.6173 25.5668 22.0948 26.0893C21.5723 26.6118 20.9467 26.8799 20.218 26.8936H8.42113ZM8.40051 24.2331L20.1974 24.2331L20.218 9.65201L14.3196 3.75356L8.42113 9.65201L8.40051 24.2331ZM13.0821 9.11578C13.4259 9.45952 13.8384 9.63111 14.3196 9.63056C14.8008 9.63111 15.2133 9.45952 15.557 9.11578C15.9007 8.77205 16.0723 8.35957 16.0718 7.87835C16.0723 7.39712 15.9007 6.98464 15.557 6.64091C15.2133 6.29718 14.8008 6.12559 14.3196 6.12614C13.8384 6.12559 13.4259 6.29718 13.0821 6.64091C12.7384 6.98464 12.5668 7.39712 12.5674 7.87835C12.5668 8.35957 12.7384 8.77205 13.0821 9.11578Z'
									fill='url(#paint0_linear_552_208343)'
								/>
							</g>
							<defs>
								<linearGradient
									id='paint0_linear_552_306124'
									x1='4.16972'
									y1='2.39991'
									x2='36.3527'
									y2='4.42239'
									gradientUnits='userSpaceOnUse'
								>
									<stop stopColor='#FFAC23' />
									<stop offset='1' stopColor='#FF5334' />
								</linearGradient>
							</defs>
						</svg>

						<p>Historial de Promociones</p>
						<button onClick={(e) => {
							window.location.href = "/Dashboard/historial"
						}}>Ver</button>
					</div>
					
					
				</div>

				<div className='body_main_area_new'> 
                {chatSelected && urlBase ? (
                    <iframe
                        width="100%"
                        height="1000"
                        src={`${urlBase}`}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <h3>Debe seleccionar un agente de acuerdo a sus clientes asignados</h3>
                )}
                    
                </div>
				
				
			</div>
			
		</div>
		</>
	)
}