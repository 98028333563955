import React, {useState} from 'react'
import PromoBooster from '../assets/img/PromoBooster.svg'
import {Link, useNavigate} from 'react-router-dom'
import lineBg from '..//assets/img/line_bg.svg'
export const EmailSent = () => {
	const navigate = useNavigate()
	const handleLogin = (e) => {
		e.preventDefault()

		navigate('/ResetPasswordFound')
	}

	return (
		<div className='Login ResetPassword EmailSent'>
			<img src={lineBg} alt='' style={{opacity: 0.4}} />
			<form action=''>
				<svg
					width='48'
					height='48'
					viewBox='0 0 48 48'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask
						id='mask0_2711_474282'
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='48'
						height='48'
					>
						<rect width='48' height='48' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_2711_474282)'>
						<path
							d='M7.49995 41.1C6.26662 41.1 5.19995 40.6417 4.29995 39.725C3.39995 38.8083 2.94995 37.75 2.94995 36.55V11.45C2.94995 10.25 3.39995 9.18333 4.29995 8.25C5.19995 7.31667 6.26662 6.85 7.49995 6.85H40.5C41.7666 6.85 42.8499 7.31667 43.75 8.25C44.65 9.18333 45.0999 10.25 45.0999 11.45V36.55C45.0999 37.75 44.65 38.8083 43.75 39.725C42.8499 40.6417 41.7666 41.1 40.5 41.1H7.49995ZM40.5 15.2L25.25 25.45C24.9833 25.6167 24.7583 25.725 24.575 25.775C24.3916 25.825 24.2 25.85 24 25.85C23.7999 25.85 23.6083 25.825 23.4249 25.775C23.2416 25.725 23.0166 25.6167 22.75 25.45L7.49995 15.2V36.55H40.5V15.2ZM24 22.35L40.25 11.45H7.79995L24 22.35Z'
							fill='url(#paint0_linear_2711_474282)'
						/>
					</g>
					<defs>
						<linearGradient
							id='paint0_linear_2711_474282'
							x1='4.65873'
							y1='6.85001'
							x2='54.4298'
							y2='10.6992'
							gradientUnits='userSpaceOnUse'
						>
							<stop stopColor='#FFAC23' />
							<stop offset='1' stopColor='#FF5334' />
						</linearGradient>
					</defs>
				</svg>

				<h1>Revisa tu correo electrónico</h1>
				<p className='firstp'>
					Recibirás un link para poder reestablecer tu contraseña
				</p>
				<p className='secondp'>(recuerda revisar tu carpeta de SPAM)</p>

				<div className='button_wrapper button_wrapper_new'>
					<button onClick={handleLogin}>Aceptar</button>
				</div>
			</form>
			<img src={PromoBooster} className='logo_one' alt='' />
		</div>
	)
}
