import {envVariables} from '../utils/envVariables/envVariables'

export const savePromotion = async (id, dataJSON) => {

    const sessionToken = localStorage.getItem('token')
    const response = await fetch(`${envVariables.baseUrl}/promotion/update/products/`+id, {
        // mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionToken,
            "Access-Control-Allow-Origin" : "*", 
            "Access-Control-Allow-Credentials" : true
          },
          method: "POST",
          body: JSON.stringify(dataJSON)
        });
        const responseJSON = await response.json();
        // document.getElementById("btn_guardado_save").innerHTML = "Guardar Catálogo";
        console.log("Respuesta Guardado!!!! FINAL FINAL FINAL:::");
        console.log(responseJSON);
        return responseJSON;
    }
