import {useState, useEffect} from 'react'
import explanationError from '../../assets/img/error_explanation.svg'

export const CatalogoDropdown = ({head, list, setVal = null}) => {
	const [dropdown, setDropdown] = useState(false)
	const [dropdownValue, setdropdownValue] = useState('')
	const [response, setresponse] = useState(null)
	const [count, setCount] = useState(0)

	useEffect(() => {
		if (count === 2) {
			if (dropdownValue === '') {
				setresponse('error')
			} else {
				setresponse('success')
			}
		}

		if (count < 2) {
			setCount(count + 1)
		}
	}, [dropdownValue, dropdown])

	return (
		<div className='CatálogoDropdown'>
			<div
				className={`head ${response} ${dropdown && 'success_open'}`}
				onClick={(e) => {
					setDropdown(!dropdown)
				}}
			>
				<p>{dropdownValue === '' ? head : dropdownValue}</p>

				<img src={explanationError} alt='' />

				<svg
					width='10'
					height='5'
					viewBox='0 0 10 5'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M9.35355 0.853554C9.54882 0.658291 9.54882 0.341709 9.35355 0.146447C9.15829 -0.0488152 8.84171 -0.0488153 8.64645 0.146447L9.35355 0.853554ZM5 4.5L4.64645 4.85355C4.84171 5.04882 5.15829 5.04882 5.35355 4.85355L5 4.5ZM1.35355 0.146446C1.15829 -0.0488159 0.84171 -0.048816 0.646448 0.146446C0.451185 0.341708 0.451185 0.658291 0.646448 0.853553L1.35355 0.146446ZM8.64645 0.146447L4.64645 4.14645L5.35355 4.85355L9.35355 0.853554L8.64645 0.146447ZM5.35355 4.14645L1.35355 0.146446L0.646448 0.853553L4.64645 4.85355L5.35355 4.14645Z'
						fill='#E8E8E8'
					/>
				</svg>
			</div>
			{dropdown && (
				<ul>
					<li>2022</li>
					{list.map((EachList) => (
						<li key={EachList}>
							<p
								onClick={(e) => {
									setDropdown(!dropdown)
									setdropdownValue(EachList)
									setVal(EachList)
								}}
							>
								{EachList}
							</p>
						</li>
					))}
				</ul>
			)}
		</div>
	)
}
