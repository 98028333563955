import {useContext} from 'react'
import {AuthContext} from '../context'
import {Navigate} from 'react-router-dom'

export const PublicRoute = ({children}) => {
	const {authState} = useContext(AuthContext)

	// return authState?.logged ? <Navigate to={'/HomeNew'} /> : children
	return children
}
