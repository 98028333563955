import PromoApi from '../utils/config/apiPromobooster'
import {envVariables} from '../utils/envVariables/envVariables'

export const getPromotion = async (id) => {
	const token = localStorage.getItem('token')
	return PromoApi.get(`${envVariables.baseUrl}/promotion/init/`+id, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}).then((response) => {
		// Manejar la respuesta exitosa aquí
		// console.log('Respuesta exitosa:', response.data);
		console.log("conexion exitosa 1");
		return response
	})
	  .catch((error) => {
		// const {setAuthState} = useContext(AuthContext)
		// setAuthState(null)
		if (error.response && error.response.status === 401) {
		  // Manejar el error 401 (No Autorizado) aquí
		  console.error('Error 401: No Autorizado', error);
		  window.location.href = '/';
		  // Puedes redirigir al usuario a la página de inicio de sesión u otras acciones relacionadas con la autenticación aquí.
		} else {
		  // Manejar otros errores de solicitud aquí
		  console.error('Error de solicitud:', error);
		  window.location.href = '/';
		}
		
	  });
}
