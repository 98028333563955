import React, {useState} from 'react'

export const CatalogoDropdown = ({head, list}) => {
	const [active, setActive] = useState(false)
	const [headState, setHead] = useState(null)
	return (
		<div className='CatalogoDropdown'>
			{active && (
				<div
					className='bg-black-long'
					onClick={(e) => {
						setActive(false)
					}}
				></div>
			)}

			<div
				className='head'
				onClick={(e) => {
					setActive(!active)
				}}
			>
				<p className={`${headState != null && 'boldFont'}`}>
					{headState === null ? head : headState}
				</p>
				<svg
					width='12'
					height='12'
					viewBox='0 0 12 12'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask
						id='mask0_410_344163'
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='12'
						height='12'
					>
						<rect
							y='12'
							width='12'
							height='12'
							transform='rotate(-90 0 12)'
							fill='#D9D9D9'
						/>
					</mask>
					<g mask='url(#mask0_410_344163)'>
						<path
							d='M5.2501 3.45166L7.5501 5.75166C7.60843 5.80999 7.6501 5.87249 7.6751 5.93916C7.7001 6.00583 7.7126 6.07666 7.7126 6.15166C7.7126 6.22666 7.7001 6.29749 7.6751 6.36416C7.6501 6.43083 7.60843 6.49333 7.5501 6.55166L5.2501 8.85166C5.14176 8.95999 5.00843 9.01416 4.8501 9.01416C4.69176 9.01416 4.55843 8.95999 4.4501 8.85166C4.34176 8.74333 4.2876 8.60999 4.2876 8.45166C4.2876 8.29333 4.34176 8.15999 4.4501 8.05166L6.3501 6.15166L4.4501 4.25166C4.34176 4.14333 4.2876 4.00999 4.2876 3.85166C4.2876 3.69333 4.34176 3.55999 4.4501 3.45166C4.55843 3.34333 4.69176 3.28916 4.8501 3.28916C5.00843 3.28916 5.14176 3.34333 5.2501 3.45166Z'
							fill='#3676F5'
						/>
					</g>
				</svg>
			</div>
			{active && (
				<div className='dropdown-custom'>
					{list.map((EachList, key) => (
						<p
							onClick={(e) => {
								setHead(EachList)
								setActive(false)
							}}
							key={key}
						>
							{EachList}
						</p>
					))}
				</div>
			)}
		</div>
	)
}
