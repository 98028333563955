import React from 'react'

export const ProductSelectionCard = ({img, heading, para, button}) => {
	return (
		<div className='card'>
			<div className='top'>
				<img src={img} alt='' />
			</div>
			<div className='body_card_product'>
				<h1>{heading}</h1>
				<p>{para}</p>
				<div className='button_wrapper'>
					<button>
						Ir a {button.span}
						{button.text}
						<svg
							width='7'
							height='10'
							viewBox='0 0 7 10'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M1.025 9.6C0.891667 9.45833 0.825 9.29367 0.825 9.106C0.825 8.91867 0.891667 8.75417 1.025 8.6125L4.6375 5L1.0125 1.375C0.879167 1.24167 0.8125 1.07917 0.8125 0.8875C0.8125 0.695833 0.883333 0.529167 1.025 0.3875C1.15833 0.254167 1.32083 0.1875 1.5125 0.1875C1.70417 0.1875 1.87083 0.254167 2.0125 0.3875L6.2125 4.6C6.27083 4.65833 6.3125 4.72083 6.3375 4.7875C6.3625 4.85417 6.375 4.925 6.375 5C6.375 5.075 6.3625 5.14583 6.3375 5.2125C6.3125 5.27917 6.27083 5.34167 6.2125 5.4L2 9.6125C1.86667 9.74583 1.70617 9.8125 1.5185 9.8125C1.33117 9.8125 1.16667 9.74167 1.025 9.6Z'
								fill='white'
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	)
}
