import React, { useState , useEffect} from 'react'
import { useParams } from 'react-router-dom'
import {
	Header,
} from '../../componentsRef'
import {Spinner} from '../../componentsRef/ui'
import {LogoutPopup} from '../LogoutPopup'
import TableauReport from 'tableau-react'



export const Dashboard = () => {
    const [tokenTableau, setTokenTableau] = useState()
	const { pagina } = useParams()
	const [LogoutState, setLogoutState] = useState(false)
    // PF/Promo/AnlisisROI
	let url_ = 'https://tableauserver-azure.pricemaker.io/t/Cleaner/views/';
	switch (pagina) {
		case 'evaluacion':
    		url_ = url_+'atomizado_cleaner/ROI';
			break;
		case 'historial':
			url_ = url_+'historial/atomizado';
			break;
		case 'planificacion':
			url_ = url_+'Planificacion_Promocional/PlanificacionPromocional';
			break;
		default:
			url_ = url_+'atomizado_cleaner/ROI';
			break;
	}
	/*
            parameters_ = {
			ID1: [dataProduct.code_product + '-' + data.cadena_details.name_customer],
		}
    */
	const fetchApi = async (tradicional = false) => {
		const url = 'https://optimusprice.pricemaker.io/api/getChartTrust'
		// https://tableauserver-azure.pricemaker.io/t/Soprole/views/basetest/titi2
		const dtargetSite = 'Cleaner'
		const project = 'atomizado_cleaner'
		const page = 'ROI'
		const response = await fetch(url, {
			method: 'POST',
			body: JSON.stringify({
				token: window.sessionStorage.getItem('token') + '-2chzRY3F3I9@',
				tabs_permission: 2,
				dtarget_site: dtargetSite,
				project: project,
				page: page,
			}),
		})

		console.log(response.status)
		const responseJSON = await response.json()
		setTokenTableau(responseJSON.token_server)
	}

    useEffect(() => {
		fetchApi()
    }, [])

	return (
		<div className='HomeNew'>
			{LogoutState && <LogoutPopup setLogoutState={setLogoutState} />}
            <Header setLogoutState={setLogoutState} />
            {!tokenTableau ? (
								<Spinner />
							) : (
								<TableauReport
									url={url_}
									token={tokenTableau}
									options={{
										width: "100%",
										height: "95vh",
									}}
									// parameters={parameters_}
								/>
							)}
		</div>
	)
}

