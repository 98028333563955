import React, {useState} from 'react'
import {Navbar} from './Navbar'
import '../assets/css/Seguimiento.css'
import {CataLogoResumenFooter} from './CataLogoResumenFooter'
import Table from 'react-bootstrap/Table'
import {Link} from 'react-router-dom'
import {SeguimientoTableLinea} from './SeguimientoTableLinea'
import {TopNavigator} from './TopNavigator'
import {SeguimientoTableLineaNew} from './SeguimientoTableLineaNew'
export const SeguimientoLineaNew = () => {
	const [active, setActive] = useState(false)

	return (
		<div className='CataLogoResumen'>
			<TopNavigator />
			<Navbar no_need_search />
			<div className='top_area top_area_differ' style={{paddingBottom: 14}}>
				<div className='option_table'>
					<Table>
						<tbody>
							<tr>
								<td>
									<Link to='/Seguimiento'>Resumen Línea</Link>
								</td>
								<td>
									<Link to='/CataLogoResumen'>Vienesas</Link>
								</td>
								<td>
									<Link to='/Vienesas'>Jamones</Link>
								</td>

								<td>
									<Link to='/'>Mortadelas</Link>
								</td>
								<td>
									<Link to='/'>Longanizas</Link>
								</td>
								<td>
									<Link to='/'>Pates</Link>
								</td>
								<td>
									<Link to='/'>Madurados</Link>
								</td>
								<td>
									<Link to='/'>Arrollados</Link>
								</td>
								<td>
									<Link to='/SeguimientoLinea' className='active'>
										Seguimiento Línea
									</Link>
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>
			<div className='link_area'>
				<p>Jumbo Abril 2022</p>

				<p>/</p>
				<p>Cecinas</p>
				<p>/</p>
				<p>Seguimiento Línea</p>
			</div>

			<SeguimientoTableLineaNew />

			<CataLogoResumenFooter />
		</div>
	)
}
