import {useContext, useEffect, useState} from 'react'
import moment from 'moment'
import {Header} from '../../componentsRef/Header'
import searchCatalog from '../../assets/img/searchCatalog.svg'
import settingCircle2 from '../../assets/img/background-catalog.svg'
import settingCircle1 from '../../assets/img/background-catalog2.svg'
import {CatalogTable} from '../../components/NewComponents/CatalogTable'
import {CadenaDropdown} from '../../components/NewComponents/CadenaDropdown'
import {FaseDropdown} from '../../components/NewComponents/FaseDropdown'

import {CatalogDate} from '../../components/NewComponents/CatalogDate'
import {NombreDropdown} from '../../components/NewComponents/NombreDropdown'
import {CatalogsContext} from '../../context/catalogs/CatalogsContext'
import {ChainsContext} from '../../context/chains/ChainsContex'

export const CatalogSearch = () => {
	const {catalogsState} = useContext(CatalogsContext)
	const {chainState, allChains} = useContext(ChainsContext)

	const [catalogsFiltered, setCatalogFiltered] = useState([])
	const [allCatalogs, setAllCatalogs] = useState([])
	const [inpValueFilter, setInpValueFilter] = useState('')
	const [selectPhaseSelected, setSelectPhaseSelected] = useState('')
	const [chainSelected, setChainSelected] = useState('')
	const [listCombine, setlistCombine] = useState('')

	const handleInpFilter = (e) => {
		e.preventDefault()
		setInpValueFilter(e.target.value)
	}

	const handleCatalogPhase = (phaseSelected) => {
		setSelectPhaseSelected(phaseSelected?.name)
	}

	const handleChainSelected = (chain) => {
		setChainSelected(chain)
	}
	useEffect(() => {
		setAllCatalogs([
			...catalogsState?.catalogs?.en_edicion,
			...catalogsState?.catalogs?.en_ejecucion,
			...catalogsState?.catalogs?.revision,
			...catalogsState?.catalogs?.negociacion,
		])
		setCatalogFiltered(allCatalogs)
		setInpValueFilter('')
		setSelectPhaseSelected('')
	}, [])

	useEffect(() => {
		;(async () => await allChains())()
	}, [])

	useEffect(() => {
		const dateRegex = /(\d{2}\/[A-Za-z]{3}\/\d{4})/g
		const dateRange = listCombine?.match(dateRegex)

		const filtered = allCatalogs?.filter((catalog) => {
			const startDate =
				listCombine !== '' && moment(dateRange[0], 'DD/MMM/YYYY')
			const endDate = listCombine !== '' && moment(dateRange[1], 'DD/MMM/YYYY')
			const ingresoDate = moment(catalog?.fecha_ingreso, 'DD/MM/YYYY')
			const isInRange =
				listCombine !== '' &&
				ingresoDate.isBetween(startDate, endDate, null, '[]')
			return (
				catalog?.nombre_promocion
					?.replace(/\s+/g, '')
					.toLowerCase()
					.includes(inpValueFilter.replace(/\s+/g, '').toLowerCase()) &&
				catalog?.estado_fase
					?.replace(/\s+/g, '')
					.toLowerCase()
					.includes(selectPhaseSelected?.replace(/\s+/g, '').toLowerCase()) &&
				catalog?.nombre_cadena?.replace(/\s+/g, '').toLowerCase() ===
					chainSelected?.nombre_cadena?.replace(/\s+/g, '').toLowerCase() &&
				isInRange
			)
		})
		setCatalogFiltered(filtered)
	}, [
		inpValueFilter,
		catalogsState?.catalogs,
		selectPhaseSelected,
		chainSelected,
		listCombine,
	])

	return (
		<div className='HomeNew  CatalogSearch'>
			<img src={settingCircle1} className='circle-1-setting' alt='' />
			<img src={settingCircle2} className='circle-2-setting' alt='' />

			<Header />

			<div className='inner-header-setting active'>
				<div>
					<img src={searchCatalog} alt='' />
					<p>Búsqueda de Catálogos</p>
				</div>
				<NombreDropdown
					handleInpFilter={handleInpFilter}
					value={inpValueFilter}
				/>
				<CatalogDate
					setlistCombine={setlistCombine}
					listCombine={listCombine}
				/>
				<CadenaDropdown
					chains={chainState?.chains}
					handleChainSelected={handleChainSelected}
					chainSelected={chainSelected}
				/>
				{/* <ProdDropdown /> */}
				<FaseDropdown handleCatalogPhase={handleCatalogPhase} />
				<a
					href='#'
					className='Limpiar'
					onClick={() => {
						setCatalogFiltered(allCatalogs)
						setInpValueFilter('')
						setSelectPhaseSelected('')
					}}
				>
					Limpiar Filtros
				</a>
			</div>

			<div className='search-table-area'>
				<h1>{`${catalogsFiltered.length} Resultados`}</h1>
				<CatalogTable data={catalogsFiltered} />
			</div>
		</div>
	)
}
