import React from 'react'
import dots from '../assets/img/dots.svg'
export const Usuario = () => {
	return (
		<div className='Usuario'>
			<img
				src='https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
				alt=''
			/>
			<div className='presentation'>
				<div className='left_side'>
					<h1>
						Alonso Perez <p>hace 10 mínutos</p>
					</h1>

					<span>VISUALIZADOR</span>
				</div>
				<img src={dots} alt='' />
			</div>
		</div>
	)
}
