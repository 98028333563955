import {useContext, useEffect, useState} from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Lider from '../../assets/img/lider.svg'
import Paper from '@mui/material/Paper'
import {Link} from 'react-router-dom'

export const CatalogTable = ({data}) => {
	const [Height, setHeight] = useState(0)
	const HandleResizeWork = () => {
		const WindowHeight = window.innerHeight - 320
		setHeight(WindowHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', HandleResizeWork)
		HandleResizeWork()
	}, [])

	return (
		<TableContainer component={Paper} style={{height: Height}}>
			<Table sx={{minWidth: 650}} aria-label='simple table' stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell width={100}>Nombre</TableCell>
						<TableCell>Cadena</TableCell>
						<TableCell>Estado</TableCell>
						<TableCell>Fecha Creación</TableCell>
						<TableCell>Actualización</TableCell>
						<TableCell>ROI</TableCell>
						<TableCell>Uplift</TableCell>
						<TableCell>Baseline</TableCell>
						<TableCell>Mg. Incremental</TableCell>
						<TableCell>N° SKU</TableCell>
					</TableRow>
				</TableHead>
				<TableBody className='table-catalog-search'>
					{data?.map((catalog) => (
						<TableRow key={catalog.id_promo}>
							<TableCell component='th' scope='row' size='small'>
								<Link
									to={`/catalogo/${catalog?.id_promo}`}
									key={catalog.id_promo}
								>
									{catalog.nombre_promocion}
								</Link>
							</TableCell>
							<TableCell component='th' scope='row'>
								<a href='#'>{catalog.nombre_cadena}</a>
							</TableCell>
							<TableCell component='th' scope='row'>
								<div className='image-wrapper'>
									<p> {catalog?.estado_fase}</p>
								</div>
							</TableCell>
							<TableCell component='th' scope='row'>
								<p>{catalog?.fecha_ingreso}</p>
							</TableCell>
							<TableCell component='th' scope='row'>
								<p>{catalog?.fecha_actualizacion}</p>
							</TableCell>
							<TableCell component='th' scope='row'>
								<p>{catalog?.roi}</p>
							</TableCell>
							<TableCell component='th' scope='row'>
								<p>{catalog?.uplift}</p>
							</TableCell>
							<TableCell component='th' scope='row'>
								<p>{catalog?.baseline}</p>
							</TableCell>
							<TableCell component='th' scope='row'>
								<p>{catalog?.margen_incremental}</p>
							</TableCell>
							<TableCell component='th' scope='row'>
								<p>{catalog?.sku_count}</p>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				{/* <TableBody className='table-catalog-search'>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>

					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
				</TableBody> */}
			</Table>
		</TableContainer>
	)
}
