import {Splide, SplideSlide} from '@splidejs/react-splide'
// Default theme
import '@splidejs/react-splide/css'

// or other themes
import '@splidejs/react-splide/css/skyblue'
import '@splidejs/react-splide/css/sea-green'

// or only core styles
import '@splidejs/react-splide/css/core'
import iconnew from '../../assets/img/iconnew.svg'
import iconnewgray from '../../assets/img/iconnewgray.svg'
import styles from './filterdata.module.scss'

export const FilterDataBox = ({
	heading,
	list,
	icon,
	part,
	setActivatePopupBoxState = null,
	catalog,
	estado
}) => {

	return (
		<div
			className={`FilterDataBox ${part && 'gray'}`}
			onClick={(e) => {
				if (setActivatePopupBoxState !== null) {
					setActivatePopupBoxState(true)
				}
			}}
		> 
			{(estado === 'edicion' && icon === 1) && (
				<div className='FilterDataBoxIconWrapper'>
					
					<img
						src={iconnew}
						alt='FilterDataBoxIcon'
						className='FilterDataBoxIcon'
					/>
					<p>Con Cambios</p>
				</div>
			)}

			{(estado === 'ejecucion' && icon === 1) && (
				<div className='FilterDataBoxIconWrapper'>
					
					<img
						src={iconnewgray}
						alt='FilterDataBoxIcon'
						className='FilterDataBoxIcon'
					/>
					<p>Por Ejecutar</p>
				</div>
			)}
			
			<p>{catalog?.fecha_ingreso || 'Sin fecha'}			
			</p>
			
			<h1>{heading}</h1>
			<div className='tags_wrapper'>
				<Splide
					aria-label='My Favorite Images'
					className='splideMain'
					options={{
						pagination: false,
						arrows: false,
						autoWidth: true,
						// perPage: 3,
						gap: 5,
					}}
				>
					{list.map((EachList) => (
						<SplideSlide key={EachList.name}>
							{EachList.name ? (
								<div className='tag'>
									{/* <div className='tag' style={{borderColor: `${EachList.color}`}}> */}
									<p>
										{EachList.name}
										<span>{EachList.percentage}</span>
									</p>
								</div>
							) : (
								<div className='tag'>
									<div style={{maxWidth: '100%'}}>
										<img
											src={EachList.img}
											alt=''
											style={{
												width: '60px',
												height: '100%',
												objectFit: 'cover',
												padding: '10px 0',
											}}
										/>
									</div>
								</div>
							)}
						</SplideSlide>
					))}
				</Splide>
			</div>
		</div>
	)
}
