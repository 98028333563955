import React from 'react'
import jumbo from '../assets/img/Jumbo.svg'
export const TopNavigatorOferta = () => {
	return (
		<div className='TopNavigator TopNavigatorOferta'>
			<nav>
				<li>
					<h1>Oferta</h1>
					<p>Jamones Abril 2022</p>
				</li>
				<li>
					<h1>Fecha Sell-In</h1>
					<p>01/04/22 al 31/04/22</p>
				</li>
				<li>
					<h1>Fecha Sell-Out</h1>
					<p>01/04/22 al 31/04/22</p>
				</li>
				<li style={{borderLeft: '1px solid #ddd'}}>
					<h1>Cadena</h1>
					<img src={jumbo} alt='' />
				</li>
				<li>
					<h1>Canal</h1>
					<p>Moderno</p>
				</li>
				<li>
					<h1>Productos</h1>
					<p>5</p>
				</li>
			</nav>
		</div>
	)
}
