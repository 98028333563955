import axios from 'axios'
import {envVariables} from '../envVariables/envVariables'

const PromoApi = axios.create({
	baseURL: envVariables.baseUrl, // TODO create .env
	timeout: 100000,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	transformResponse: [
		function (data) {
			try {
				if (data) {
					const parsedData = JSON.parse(data)
					return parsedData.items ? parsedData.items : parsedData
				} else {
					return data
				}
			} catch (error) {
				throw Error(
					`[requestClient] Error parsingJSON data - ${JSON.stringify(error)}`
				)
			}
		},
	],
})

export default PromoApi
