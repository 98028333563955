import React from 'react'

export const TopNavigator = () => {
	return (
		<div className='TopNavigator'>
			<nav>
				<li>
					<h1>Catálogo</h1>
					<p>Jumbo Abril 2022</p>
				</li>
				<li>
					<h1>Fecha</h1>
					<p>Abril 2022</p>
				</li>
				<li>
					<h1>Canal</h1>
					<p>Moderno</p>
				</li>
			</nav>
		</div>
	)
}
