import {useEffect, useState} from 'react'

import firstBanner from '../../assets/img/Banner1Login.png'
import secondBanner from '../../assets/img/Banner2Login.png'

const LoginPage = ({children}) => {
	const [loadScreen, setLoadScreen] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setLoadScreen(true)
		}, 200)
	}, [])

	return (
		<div className={`login_page ${loadScreen && 'login_active'}`}>
			<img src={firstBanner} alt='' className='login_page__first-banner' />
			<img src={secondBanner} alt='' className='login_page__second-banner' />
			{children}
		</div>
	)
}

export default LoginPage
