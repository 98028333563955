import {useNavigate} from 'react-router-dom'

export const LogoutPopup = ({setLogoutState}) => {
	const navigate = useNavigate()
	return (
		<>
			<span className='black_bg'></span>
			<div className='LogoutPopupNew'>
				<svg
					width='41'
					height='40'
					viewBox='0 0 41 40'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect x='0.5' width='40' height='40' rx='20' fill='#F0F4FC' />
					<g clipPath='url(#clip0_835_171829)'>
						<mask
							id='mask0_835_171829'
							maskUnits='userSpaceOnUse'
							x='4'
							y='4'
							width='33'
							height='32'
						>
							<rect x='4.5' y='4' width='32' height='32' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_835_171829)'>
							<path
								d='M17.2342 27.6333C17.0342 27.6333 16.8454 27.5999 16.6676 27.5333C16.4898 27.4666 16.3231 27.3555 16.1676 27.1999L10.4342 21.4666C10.1454 21.1777 10.0067 20.8164 10.0182 20.3826C10.0289 19.9497 10.1787 19.5888 10.4676 19.2999C10.7565 19.011 11.1178 18.8666 11.5516 18.8666C11.9845 18.8666 12.3342 19.011 12.6009 19.2999L17.2342 23.9333L28.4009 12.7666C28.6676 12.4777 29.0231 12.3333 29.4676 12.3333C29.912 12.3333 30.2787 12.4777 30.5676 12.7666C30.8342 13.0555 30.9676 13.4164 30.9676 13.8493C30.9676 14.283 30.8342 14.6444 30.5676 14.9333L18.3009 27.1999C18.1454 27.3555 17.9787 27.4666 17.8009 27.5333C17.6231 27.5999 17.4342 27.6333 17.2342 27.6333Z'
								fill='#3676F5'
							/>
						</g>
					</g>
					<defs>
						<clipPath id='clip0_835_171829'>
							<rect x='4.5' y='4' width='32' height='32' rx='16' fill='white' />
						</clipPath>
					</defs>
				</svg>

				<h1>¿Estás segur@ que quieres cerrar sesión?</h1>
				<div className='button_wrapper'>
					<button
						onClick={(e) => {
							navigate('/LoaderNew')
						}}
					>
						Cerrar sesión
					</button>
					<button onClick={(e) => setLogoutState(false)} className='canceler'>
						Cancelar
					</button>
				</div>

				<svg
					onClick={(e) => setLogoutState(false)}
					width='10'
					height='10'
					viewBox='0 0 10 10'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M4.99994 6.06666L1.79993 9.26666C1.65549 9.41111 1.47771 9.48333 1.2666 9.48333C1.05549 9.48333 0.877713 9.41111 0.733268 9.26666C0.588824 9.12222 0.516602 8.94444 0.516602 8.73333C0.516602 8.52222 0.588824 8.34444 0.733268 8.2L3.93327 5L0.733268 1.8C0.588824 1.65555 0.516602 1.47777 0.516602 1.26666C0.516602 1.05555 0.588824 0.877774 0.733268 0.733329C0.877713 0.588885 1.05549 0.516663 1.2666 0.516663C1.47771 0.516663 1.65549 0.588885 1.79993 0.733329L4.99994 3.93333L8.19993 0.733329C8.34438 0.588885 8.52216 0.516663 8.73327 0.516663C8.94438 0.516663 9.12216 0.588885 9.2666 0.733329C9.41105 0.877774 9.48327 1.05555 9.48327 1.26666C9.48327 1.47777 9.41105 1.65555 9.2666 1.8L6.0666 5L9.2666 8.2C9.41105 8.34444 9.48327 8.52222 9.48327 8.73333C9.48327 8.94444 9.41105 9.12222 9.2666 9.26666C9.12216 9.41111 8.94438 9.48333 8.73327 9.48333C8.52216 9.48333 8.34438 9.41111 8.19993 9.26666L4.99994 6.06666Z'
						fill='#ADADAD'
					/>
				</svg>
			</div>
		</>
	)
}
