import React from 'react'
import Cerrar from '../assets/img/Cerrar.svg'

export const RenombrarPopupNew = ({setRenombrar}) => {
	return (
		<div className='popupWrapper OfertaWrapper RenombrarPopupNew CombiarPopup RenombrarPopup'>
			<div className='popup'>
				<div className='close_icon' onClick={(e) => setRenombrar(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<h1>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<mask
							id='mask0_2568_121257'
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='24'
							height='24'
						>
							<rect width='24' height='24' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_2568_121257)'>
							<path
								d='M8.99995 22L13.85 17.15H20C20.6666 17.15 21.2373 17.3874 21.712 17.862C22.1873 18.3374 22.4249 18.9084 22.4249 19.575C22.4249 20.2417 22.1873 20.8127 21.712 21.288C21.2373 21.7627 20.6666 22 20 22H8.99995ZM4.22495 19.35H5.92495L14.375 10.875L12.7 9.20002L4.22495 17.65V19.35ZM19.5499 9.45002L14.1 4.00002L15.45 2.67502C15.95 2.15836 16.5666 1.90002 17.3 1.90002C18.0333 1.90002 18.6666 2.15836 19.2 2.67502L20.9249 4.40002C21.4416 4.91669 21.6959 5.53336 21.688 6.25002C21.6793 6.96669 21.4166 7.57502 20.9 8.07502L19.5499 9.45002ZM2.97495 22C2.57495 22 2.24162 21.8667 1.97495 21.6C1.70828 21.3334 1.57495 21 1.57495 20.6V17.125C1.57495 16.925 1.60828 16.7457 1.67495 16.587C1.74162 16.429 1.84995 16.275 1.99995 16.125L12.675 5.42502L18.125 10.875L7.44995 21.575C7.29995 21.725 7.14595 21.8334 6.98795 21.9C6.82928 21.9667 6.64995 22 6.44995 22H2.97495Z'
								fill='url(#paint0_linear_2568_121257)'
							/>
						</g>
						<defs>
							<linearGradient
								id='paint0_linear_2568_121257'
								x1='2.42022'
								y1='1.90003'
								x2='27.0825'
								y2='3.50772'
								gradientUnits='userSpaceOnUse'
							>
								<stop stopColor='#FFAC23' />
								<stop offset='1' stopColor='#FF5334' />
							</linearGradient>
						</defs>
					</svg>

					<span>Renombrar Catálogo</span>
				</h1>
				<p>Define un nuevo nombre para el Catálogo</p>

				<div style={{marginTop: 20}}>
					<div className='input_container'>
						<input type='text' placeholder='Jumbo Abril 2022 |' />
					</div>
				</div>

				<div className='bottom_buttton'>
					<button onClick={(e) => setRenombrar(false)}>Cancelar</button>
					<button onClick={(e) => setRenombrar(false)}>Aceptar</button>
				</div>
			</div>
		</div>
	)
}
