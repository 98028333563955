
export const numeroaDinero = (numberOrString) => {
    let number;
    
    if (typeof numberOrString === 'number') {
      // Si es un número, lo asignamos directamente
      number = numberOrString;
    } else if (typeof numberOrString === 'string') {
      // Si es una cadena, intentamos convertirla a número
      number = parseInt(numberOrString, 10);
      
      // Validamos que la conversión sea exitosa
      if (isNaN(number)) {
        return "";
        // throw new Error('El valor ingresado no es un número válido.');
      }
    } else {
      return "";
      // throw new Error('El valor ingresado debe ser un número o una cadena numérica.');
    }
  
    // Convertir el número a string y agregar separador de miles
    const formattedNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    // Agregar el símbolo de peso chileno ($) y retornar el resultado
    return `$${formattedNumber}`;
  }

export const dineroaNumero = (currencyString) => {
    // Eliminar el símbolo de peso chileno ($) y los separadores de miles
    const numberString = currencyString.replace(/\$|\.|,/g, '');
  
    // Convertir la cadena resultante a un número entero
    const number = parseInt(numberString, 10);
  
    // Validar que la conversión sea exitosa
    if (isNaN(number)) {
      return 0;
      // throw new Error('El valor ingresado no es un número válido.');
    }
  
    return number;
  }

export const numeroaDescuento = (number) => {
    // Convertir el número a cadena con dos decimales
    number = String(number).replace(/[^-\d.,]/g, '');
    if(number === "") return "";
    const formattedNumber = parseFloat(number).toFixed(2);
  
    // Reemplazar el punto decimal por una coma
    const formattedString = formattedNumber.replace('.', ',');
    
    return formattedString;
  }

export const descuentoaNumero = (numberString) => {
    // Reemplazar la coma decimal por un punto
    numberString = String(numberString);
    const numberWithDot = numberString.replace(',', '.');
  
    // Convertir la cadena resultante a un número
    const number = parseFloat(numberWithDot);
  
    // Validar que la conversión sea exitosa
    if (isNaN(number)) {
      return "";
      // throw new Error('El valor ingresado no es un número válido.');
    }
  
    return number;
  }