import PromoApi from '../utils/config/apiPromobooster'
import {envVariables} from '../utils/envVariables/envVariables'

export const deleteRow = async (id) => {
    const sessionToken = localStorage.getItem('token')
    const response = await fetch(`${envVariables.baseUrl}/promotion/productos/`+id, { // id_promocion_promociones
        // mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionToken,
            "Access-Control-Allow-Origin" : "*", 
            "Access-Control-Allow-Credentials" : true
          },
          method: "DELETE"
        });
        const responseJSON = await response.json();
        // document.getElementById("btn_guardado_save").innerHTML = "Guardar Catálogo";
        console.log("Respuesta DELETE!!!! :::");
        console.log(responseJSON);
        return responseJSON;
    }
