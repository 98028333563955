import iconoCatalog from '../assets/img/Iconoexport.svg'
export const ExportPop = ({setActive}) => {
	return (
		<div
			className='popupWrapper'
			id='popupCatalog'
			onClick={(e) => {
				if (e.target.id === 'popupCatalog') {
					setActive(false)
				}
			}}
		>
			<div className='popup SaveCatalog ExportPop'>
				<div className='topexport'>
					<img src={iconoCatalog} alt='' />
					<h1>Exportar Vista</h1>
				</div>
				<p>Selecciona el formato en el que deseas exportar la vista.</p>
				<div className='select_area'>
					<label htmlFor=''>Formato</label>
					<select name='' id=''>
						<option value=''>Selecciona un formato</option>
						<option value='PDF'>PDF</option>
						<option value='CSV'>CSV</option>
					</select>
				</div>

				<div className='buttons_wrapper'>
					<button>Cancelar</button>
					<button>Exportar</button>
				</div>
			</div>
		</div>
	)
}
