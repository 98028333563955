const AlertErrorSvg = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
		{...props}
	>
		<path
			fill='#C74028'
			d='M3.03324 13.1333C2.84435 13.1333 2.6999 13.05 2.5999 12.8833C2.4999 12.7167 2.4999 12.55 2.5999 12.3833L7.56657 3.81665C7.66657 3.64998 7.81101 3.56665 7.9999 3.56665C8.18879 3.56665 8.33324 3.64998 8.43324 3.81665L13.3999 12.3833C13.4999 12.55 13.4999 12.7167 13.3999 12.8833C13.2999 13.05 13.1555 13.1333 12.9666 13.1333H3.03324ZM7.9999 6.99998C7.93324 6.99998 7.87768 7.02221 7.83324 7.06665C7.78879 7.11109 7.76657 7.16665 7.76657 7.23332V10.1C7.76657 10.1666 7.78879 10.2222 7.83324 10.2666C7.87768 10.3111 7.93324 10.3333 7.9999 10.3333C8.06657 10.3333 8.12212 10.3111 8.16657 10.2666C8.21101 10.2222 8.23324 10.1666 8.23324 10.1V7.23332C8.23324 7.16665 8.21101 7.11109 8.16657 7.06665C8.12212 7.02221 8.06657 6.99998 7.9999 6.99998ZM7.9999 11.6666C8.0999 11.6666 8.18057 11.6333 8.2419 11.5666C8.30279 11.5 8.33324 11.4222 8.33324 11.3333C8.33324 11.2333 8.30279 11.1526 8.2419 11.0913C8.18057 11.0304 8.0999 11 7.9999 11C7.91101 11 7.83324 11.0304 7.76657 11.0913C7.6999 11.1526 7.66657 11.2333 7.66657 11.3333C7.66657 11.4222 7.6999 11.5 7.76657 11.5666C7.83324 11.6333 7.91101 11.6666 7.9999 11.6666ZM2.96657 12.6666H13.0332L7.9999 3.99998L2.96657 12.6666Z'
		/>
	</svg>
)
export default AlertErrorSvg
