import {useEffect, useState} from 'react'
import Banner1Login from '../assets/img/Banner1Login.png'
import Banner2Login from '../assets/img/Banner2Login.png'
import blackGreenFull from '../assets/img/Full_Negro+Verde.svg'
import '../assets/css/LoginNew.css'
import {Link, useNavigate} from 'react-router-dom'
import {NewInputModule} from './NewInputModule'
export const ForgetPasswordNew = () => {
	const [activeScreen, setActiveScreen] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		setTimeout(() => {
			setActiveScreen(true)
		}, 200)
	}, [])
	return (
		<div className={`LoginNew ForgetPasswordNew ${activeScreen && 'active'}`}>
			<img src={Banner1Login} alt='' className='Banner1Login' />
			<img src={Banner2Login} alt='' className='Banner2Login' />
			<img src={blackGreenFull} alt='' className='Full_NegroVerde' />

			<form action='#'>
				<div className='top_box'>
					<svg
						width='40'
						height='40'
						viewBox='0 0 40 40'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect width='40' height='40' rx='20' fill='#F0F7FC' />
						<mask
							id='mask0_10_65211'
							maskUnits='userSpaceOnUse'
							x='4'
							y='4'
							width='32'
							height='32'
						>
							<rect x='4' y='4' width='32' height='32' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_10_65211)'>
							<path
								d='M12.0997 33.5666C11.2553 33.5666 10.5389 33.2724 9.95041 32.684C9.36107 32.0946 9.06641 31.3777 9.06641 30.5333V17.4C9.06641 16.5777 9.36107 15.8666 9.95041 15.2666C10.5389 14.6666 11.2553 14.3666 12.0997 14.3666H13.1997V11.9333C13.1997 10.0444 13.8606 8.43863 15.1824 7.11597C16.5051 5.79419 18.1109 5.1333 19.9997 5.1333C21.8886 5.1333 23.4944 5.79419 24.8171 7.11597C26.1389 8.43863 26.7997 10.0444 26.7997 11.9333V14.3666H27.8997C28.7442 14.3666 29.4611 14.6666 30.0504 15.2666C30.6389 15.8666 30.9331 16.5777 30.9331 17.4V30.5333C30.9331 31.3777 30.6389 32.0946 30.0504 32.684C29.4611 33.2724 28.7442 33.5666 27.8997 33.5666H12.0997ZM12.0997 30.5333H27.8997V17.4H12.0997V30.5333ZM19.9997 26.6333C20.7331 26.6333 21.3611 26.372 21.8837 25.8493C22.4055 25.3275 22.6664 24.7 22.6664 23.9666C22.6664 23.2333 22.4055 22.6053 21.8837 22.0826C21.3611 21.5609 20.7331 21.3 19.9997 21.3C19.2664 21.3 18.6389 21.5609 18.1171 22.0826C17.5944 22.6053 17.3331 23.2333 17.3331 23.9666C17.3331 24.7 17.5944 25.3275 18.1171 25.8493C18.6389 26.372 19.2664 26.6333 19.9997 26.6333ZM16.2331 14.3666H23.7664V11.9333C23.7664 10.8889 23.3997 9.99997 22.6664 9.26663C21.9331 8.5333 21.0442 8.16663 19.9997 8.16663C18.9553 8.16663 18.0664 8.5333 17.3331 9.26663C16.5997 9.99997 16.2331 10.8889 16.2331 11.9333V14.3666Z'
								fill='#3676F5'
							/>
						</g>
					</svg>
				</div>
				<div className='body_area'>
					<p>Recupera tu Contraseña</p>
					<p>Ingresa el email con el que te registraste</p>
					<div className='input_wrapper'>
						<label htmlFor='Email'>Email</label>
						<NewInputModule
							type='email'
							id='Email'
							placeholder='ejemplo@gmail.com'
						/>

						<svg
							width='16'
							height='17'
							viewBox='0 0 16 17'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_459_129084'
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='16'
								height='17'
							>
								<rect y='0.5' width='16' height='16' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_459_129084)'>
								<path
									d='M3.2002 12.9667C2.91131 12.9667 2.67242 12.8722 2.48353 12.6833C2.29464 12.4944 2.2002 12.2555 2.2002 11.9667V5.03333C2.2002 4.74444 2.29464 4.50555 2.48353 4.31666C2.67242 4.12777 2.91131 4.03333 3.2002 4.03333H12.8002C13.0891 4.03333 13.328 4.12777 13.5169 4.31666C13.7058 4.50555 13.8002 4.74444 13.8002 5.03333V11.9667C13.8002 12.2555 13.7058 12.4944 13.5169 12.6833C13.328 12.8722 13.0891 12.9667 12.8002 12.9667H3.2002ZM13.3335 4.86666L8.28353 8.21666C8.23908 8.23888 8.19464 8.25821 8.1502 8.27466C8.10575 8.29155 8.05575 8.29999 8.0002 8.29999C7.94464 8.29999 7.89464 8.29155 7.85019 8.27466C7.80575 8.25821 7.76131 8.23888 7.71686 8.21666L2.66686 4.86666V11.9667C2.66686 12.1222 2.71686 12.25 2.81686 12.35C2.91686 12.45 3.04464 12.5 3.2002 12.5H12.8002C12.9558 12.5 13.0835 12.45 13.1835 12.35C13.2835 12.25 13.3335 12.1222 13.3335 11.9667V4.86666ZM8.0002 7.83332L13.0669 4.49999H2.93353L8.0002 7.83332ZM2.66686 4.86666V4.99999V4.59133V4.61666V4.57466V4.99999V4.86666V12.5V11.9667V4.86666Z'
									fill='#3676F5'
								/>
							</g>
						</svg>
					</div>

					{/* <div className="input_wrapper">
            <Link to="/ForgetPasswordNew">No recuerdo mi email</Link>
          </div> */}

					<div className='button_wrapper'>
						<button
							onClick={(e) => {
								navigate('/EmailCheck')
							}}
						>
							Aceptar
							<svg
								width='17'
								height='16'
								viewBox='0 0 17 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<mask
									id='mask0_459_64902'
									maskUnits='userSpaceOnUse'
									x='2'
									y='2'
									width='13'
									height='12'
								>
									<rect x='2.5' y='2' width='12' height='12' fill='#D9D9D9' />
								</mask>
								<g mask='url(#mask0_459_64902)'>
									<path
										d='M6.02451 12.6C5.89118 12.4583 5.82451 12.2937 5.82451 12.106C5.82451 11.9187 5.89118 11.7542 6.02451 11.6125L9.63701 8L6.01201 4.375C5.87868 4.24167 5.81201 4.07917 5.81201 3.8875C5.81201 3.69583 5.88285 3.52917 6.02451 3.3875C6.15785 3.25417 6.32034 3.1875 6.51201 3.1875C6.70368 3.1875 6.87034 3.25417 7.01201 3.3875L11.212 7.6C11.2703 7.65833 11.312 7.72083 11.337 7.7875C11.362 7.85417 11.3745 7.925 11.3745 8C11.3745 8.075 11.362 8.14583 11.337 8.2125C11.312 8.27917 11.2703 8.34167 11.212 8.4L6.99951 12.6125C6.86618 12.7458 6.70568 12.8125 6.51801 12.8125C6.33068 12.8125 6.16618 12.7417 6.02451 12.6Z'
										fill='white'
									/>
								</g>
							</svg>
						</button>
						<Link to='/LoginNew'>Cancelar</Link>
					</div>
				</div>
			</form>
		</div>
	)
}
