import React, {useState} from 'react'
import Table from 'react-bootstrap/Table'
import filterNew from '../assets/img/filter_new.svg'
import dropdown from '../assets/img/dropdown.svg'
import forwardArrow from '../assets/img/forwardArrow.svg'
import Off from '../assets/img/Off.svg'
import closeSm from '../assets/img/closeSm.svg'
import Recomendacion from '../assets/img/Recomendacion.svg'
import tag from '../assets/img/tag.svg'
import Si from '../assets/img/Si.svg'
import tick from '../assets/img/tick.svg'
import No from '../assets/img/No.svg'
import {InnerActiveSqguimiento} from './InnerActiveSqguimiento'
export const SeguimientoTableLineaNew = () => {
	const [ActiveInner, setActiveInner] = useState('')
	return (
		<div className='SeguimientoTable'>
			<Table>
				<thead>
					<tr>
						<td colSpan={3}>
							<div className='dropdown'>
								<div className='head'>
									<img src={filterNew} alt='' />
									<p>Todas</p>
									<img src={dropdown} alt='' />
								</div>
							</div>
						</td>
						<td colSpan={2} style={{background: '#f8f8f8'}}>
							<div className='dropdown'>
								<div className='head'>
									<img src={filterNew} alt='' />
									<p>Marca</p>
									<img src={dropdown} alt='' />
								</div>
							</div>
						</td>
						<td colSpan={3} style={{background: '#f8f8f8'}}>
							<div className='dropdown'>
								<div className='head'>
									<img src={filterNew} alt='' />
									<p>Recomendación</p>
									<img src={dropdown} alt='' />
								</div>
							</div>
						</td>
						<td colSpan={2}>
							<p>Prop. Inicial</p>
							<span className='propspan'></span>
						</td>
						<td colSpan={2}>
							<p>Prop. Colaborativa</p>
							<div className='percentage yellow_percentage'>
								<span className='percentage_inner'></span>
								<p>100,0% (18/18)</p>
							</div>
						</td>
						<td colSpan={2}>
							<p>Negociación</p>
							<div className='percentage red_percentage'>
								<span className='percentage_inner'></span>
								<p>75,0% (14/18)</p>
							</div>
						</td>
						<td colSpan={2}>
							<p>Ejecución</p>
							<div className='percentage green_percentage'>
								<span className='percentage_inner'></span>
								<p>50,0% (9/18)</p>
							</div>
						</td>
					</tr>
				</thead>
				<tbody className='img_saperate_wrapper'>
					<tr className='special_body_row'>
						<td>
							<div className='display_flex  gray'>
								<p>SubFamilia</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Marca</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Rec</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Código</p>
								<img src={Off} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Descripción.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Oficina</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Pre. Act.</p>
								<img src={Off} alt='' />
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>Pre. Opt.</p>
								<img src={Off} alt='' />
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>
					</tr>
					<tr
						id='familia1'
						onClick={(e) => {
							if (
								document
									.querySelector('#familia1')
									.classList.contains('rotate_img')
							) {
								document
									.querySelector('#familia1')
									.classList.remove('rotate_img')
							} else {
								document.querySelector('#familia1').classList.add('rotate_img')
							}
							document.querySelectorAll('.familia1').forEach((Each) => {
								if (Each.classList.contains('active')) {
									Each.classList.remove('active')
								} else {
									Each.classList.add('active')
								}
							})
						}}
					>
						<td>
							<div className='display_flex familiap  center_justify gray blue_bg special_p'>
								<p>Familia</p>
							</div>
						</td>
						<td colSpan={15}>
							<div
								className='display_flex  center_justify gray blue_bg special_p'
								style={{justifyContent: 'flex-start', paddingLeft: 25}}
							>
								<img src={forwardArrow} alt='' />
							</div>
						</td>
					</tr>
					<tr
						className={`activate familia1 `}
						id={`${ActiveInner === 'first' && 'blue_area'}`}
					>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={Si} alt='' />
								<img src={tag} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<div
									onClick={(e) => {
										setActiveInner('first')
									}}
								>
									<svg
										width='16'
										height='18'
										viewBox='0 0 16 18'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<rect
											x='2'
											y='3'
											width='12'
											height='12'
											rx='6'
											fill='#C1E5FE'
										/>
										<path
											d='M7 5L11 9L7 13'
											stroke='white'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<InnerActiveSqguimiento ActiveInner={ActiveInner} current='first' />
					<tr
						className='activate familia1'
						id={`${ActiveInner === 'second' && 'blue_area'}`}
					>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={Si} alt='' />
								<img src={tag} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<div onClick={(e) => setActiveInner('second')}>
									<svg
										width='16'
										height='18'
										viewBox='0 0 16 18'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<rect
											x='2'
											y='3'
											width='12'
											height='12'
											rx='6'
											fill='#C1E5FE'
										/>
										<path
											d='M7 5L11 9L7 13'
											stroke='white'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<InnerActiveSqguimiento ActiveInner={ActiveInner} current='second' />
					<tr className='activate familia1'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={Si} alt='' />
								<img src={tag} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									onClick={(e) => setActiveInner('third')}
									height='18'
									viewBox='0 0 16 18'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia1'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={No} alt='' />
								<img src={Recomendacion} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									height='18'
									onClick={(e) => setActiveInner('four')}
									viewBox='0 0 16 18'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={closeSm} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={closeSm} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia1'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={No} alt='' />
								<img src={Recomendacion} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									onClick={(e) => setActiveInner('fifth')}
									height='18'
									viewBox='0 0 16 18'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={closeSm} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia1'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={Si} alt='' />
								<img src={tag} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									height='18'
									viewBox='0 0 16 18'
									onClick={(e) => setActiveInner('six')}
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia1'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={No} alt='' />
								<img src={Recomendacion} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									height='18'
									onClick={(e) => setActiveInner('seven')}
									viewBox='0 0 16 18'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={closeSm} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={closeSm} alt='' />
							</div>
						</td>
					</tr>
					<tr
						id='familia2'
						onClick={(e) => {
							if (
								document
									.querySelector('#familia2')
									.classList.contains('rotate_img')
							) {
								document
									.querySelector('#familia2')
									.classList.remove('rotate_img')
							} else {
								document.querySelector('#familia2').classList.add('rotate_img')
							}

							document.querySelectorAll('.familia2').forEach((Each) => {
								if (Each.classList.contains('active')) {
									Each.classList.remove('active')
								} else {
									Each.classList.add('active')
								}
							})
						}}
					>
						<td>
							<div className='display_flex familiap  center_justify gray blue_bg special_p'>
								<p>Jamonse</p>
							</div>
						</td>
						<td colSpan={15}>
							<div
								className='display_flex  center_justify gray blue_bg special_p'
								style={{justifyContent: 'flex-start', paddingLeft: 25}}
							>
								<img src={forwardArrow} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia2'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={Si} alt='' />
								<img src={tag} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									height='18'
									onClick={(e) => setActiveInner('eight')}
									viewBox='0 0 16 18'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia2'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={Si} alt='' />
								<img src={tag} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									height='18'
									onClick={(e) => setActiveInner('nine')}
									viewBox='0 0 16 18'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia2'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={Si} alt='' />
								<img src={tag} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									height='18'
									onClick={(e) => setActiveInner('ten')}
									viewBox='0 0 16 18'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia2'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={No} alt='' />
								<img src={Recomendacion} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									height='18'
									onClick={(e) => setActiveInner('eleven')}
									viewBox='0 0 16 18'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={closeSm} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={closeSm} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia2'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={No} alt='' />
								<img src={Recomendacion} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									height='18'
									viewBox='0 0 16 18'
									onClick={(e) => setActiveInner('twelve')}
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={closeSm} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia2'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={Si} alt='' />
								<img src={tag} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									height='18'
									viewBox='0 0 16 18'
									onClick={(e) => setActiveInner('thirteen')}
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={tick} alt='' />
							</div>
						</td>
					</tr>
					<tr className='activate familia2'>
						<td>
							<div className='display_flex gray'>
								<p>-</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>PF</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<img src={No} alt='' />
								<img src={Recomendacion} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<a href='#'>X6000</a>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>
									Salchichas Sureñas <br /> PF (6x1Kg)
								</p>
								<svg
									width='16'
									height='18'
									onClick={(e) => setActiveInner('fourteen')}
									viewBox='0 0 16 18'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect
										x='2'
										y='3'
										width='12'
										height='12'
										rx='6'
										fill='#C1E5FE'
									/>
									<path
										d='M7 5L11 9L7 13'
										stroke='white'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>5 Of.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex   green'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>1.0%</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>$1.766</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>1.0%</p> <img src={tick} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p> <img src={closeSm} alt='' />
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>$1.766</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>1.0%</p>
								<img src={closeSm} alt='' />
							</div>
						</td>
					</tr>
				</tbody>
			</Table>
		</div>
	)
}
