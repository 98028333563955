import {useState} from 'react'
import AvatarPop from '../assets/img/AvatarPop.svg'
import Actividad from '../assets/img/Actividad.svg'
import Resumen from '../assets/img/Icono Resumen.svg'
import Cerrar from '../assets/img/Cerrar.svg'
import Avatargirl from '../assets/img/Avatargirl.svg'

export const CodigoPop = ({setCodigoPop}) => {
	const [TextareaFocus, setTextareaFocus] = useState(false)
	const [disableCheck, setdisableCheck] = useState(true)
	return (
		<div className='popupWrapper OfertaWrapper TottusPop CodigoPop'>
			<div className='popup'>
				<div className='close_icon'>
					<img
						src={Cerrar}
						alt=''
						onClick={(e) => {
							setCodigoPop(false)
						}}
					/>
				</div>
				<div className='left_side'>
					<h1>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_2505_117424'
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='24'
								height='24'
							>
								<rect width='24' height='24' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_2505_117424)'>
								<path
									d='M2 6H3.83299V19H2V6ZM7.45825 6H8.35438V19H7.45825V6ZM4.72912 6H6.56212V19H4.72912V6ZM19.2709 6H22V19H19.2709V6ZM10.1874 6H11.9796V19H10.1874V6ZM16.5418 6H17.4379V19H16.5418V6ZM12.9165 6H15.6456V19H12.9165V6Z'
									fill='#AEB0B0'
								/>
							</g>
						</svg>
						X6000
						<p className='special_p'>Salchichas Sureñas PF (6x1Kg)</p>
					</h1>

					<table>
						<tbody>
							<tr>
								<td>
									<p>Línea</p>
									<h1>Cecinas</h1>
								</td>
								<td>
									<p>Familia</p>
									<h1>Vienesas</h1>
								</td>
								<td>
									<p>Sub Familia</p>
									<h1>-</h1>
								</td>
								<td className='border-gray' width='12%'>
									<p>Marca</p>
									<h1>PF</h1>
								</td>
								<td>
									<p>Peso Caja</p>
									<h1>1Kg</h1>
								</td>
								<td>
									<p>Peso Unidad</p>
									<h1>250g</h1>
								</td>
								<td width='5%'>
									<p>Unidades</p>
									<h1>4</h1>
								</td>
								<td>
									<p>Formato Venta</p>
									<h1>UK</h1>
								</td>
							</tr>
						</tbody>
					</table>

					<h1 style={{marginTop: 30}}>
						<img src={Resumen} alt='' />
						Detalles Optimización
					</h1>

					<div className='table_grid'>
						<table>
							<tbody>
								<tr>
									<td>MAPE</td>
									<td>
										<p>Log-Log</p>
										<h1>40,5%</h1>
									</td>
									<td className='border-gray' width='15%'>
										<p>Elast</p>
										<h1>-1.5</h1>
									</td>
									<td>
										<p>Log</p>
										<h1>50,5%</h1>
									</td>
									<td>
										<p>Lin</p>
										<h1>60,5%</h1>
									</td>
									<td>
										<p>Log-Log</p>
										<h1>80,0%</h1>
									</td>
								</tr>
							</tbody>
						</table>
						<table>
							<tbody>
								<tr>
									<td>
										<p>Precio Act.</p>
										<h1>$4.050</h1>
									</td>
									<td>
										<p>Precio Opt.</p>
										<h1>$4.250</h1>
									</td>
									<td>
										<p>Vol. Act.</p>
										<h1>14.560</h1>
									</td>
									<td>
										<p>Vol. Opt.</p>
										<h1>12.660</h1>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<h1 style={{marginTop: 20}}>
						<img src={Actividad} alt='' />
						Actividad
					</h1>

					<div className='profile_box_wrapper profile_box_wrapperNew'>
						<div className='profile_box' style={{alignItems: 'flex-start'}}>
							<img src={Avatargirl} alt='' />
							<div className='presentation' style={{position: 'relative'}}>
								<textarea
									cols='30'
									rows='10'
									className={`${TextareaFocus && 'messageType'}`}
									placeholder='Escribe un comentario...'
									onChange={(e) => {
										if (e.target.value !== '') {
											setdisableCheck(false)
										} else {
											setdisableCheck(true)
										}
									}}
									onFocus={(e) => {
										setTextareaFocus(true)
									}}
									onBlur={(e) => {
										setTextareaFocus(false)
									}}
								></textarea>
								<button className='comment' disabled={disableCheck}>
									Comment
								</button>
							</div>
						</div>

						<div className='profile_box responder'>
							<img src={Avatargirl} alt='' />
							<div className='presentation'>
								<div className='top_area'>
									<h1>María M.</h1>
									<p>hace 10 mínutos</p>
								</div>
								<input type='text' placeholder='Saquemos el Jamón Ahumado' />
								<button>Responder</button>
							</div>
						</div>

						<div className='profile_box'>
							<img src={AvatarPop} alt='' />
							<div className='presentation'>
								<div className='top_area'>
									<h1>Juan Martínez</h1>
									<p>ha movido esta promoción al estado</p>
									<span>EN EDICIÓN</span>
								</div>
								<p>31 / 01 / 2022 a las 14:25</p>
							</div>
						</div>
						<div className='profile_box'>
							<img src={AvatarPop} alt='' />
							<div className='presentation'>
								<div className='top_area'>
									<h1>Pedro Perez</h1>
									<p>ha movido esta promoción al estado</p>
								</div>
								<p>31 / 01 / 2022 a las 14:25</p>
							</div>
						</div>
						<div className='profile_box'>
							<img src={AvatarPop} alt='' />
							<div className='presentation'>
								<div className='top_area'>
									<h1>María M.</h1>
									<p>ha movido esta promoción al estado</p>
								</div>
								<p>31 / 01 / 2022 a las 14:25</p>
							</div>
						</div>
					</div>
				</div>
				<div className='right_side'>
					<h1>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_2505_117566'
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='24'
								height='24'
							>
								<rect width='24' height='24' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_2505_117566)'>
								<path
									d='M11.4773 13L6.90924 18.25C6.72072 18.4666 6.50319 18.575 6.25666 18.575C6.01013 18.575 5.7926 18.4666 5.60408 18.25C5.41555 18.0333 5.32129 17.7833 5.32129 17.5C5.32129 17.2166 5.41555 16.9666 5.60408 16.75L10.8683 10.7C11.0423 10.5 11.2453 10.4 11.4773 10.4C11.7094 10.4 11.9124 10.5 12.0864 10.7L14.9578 14L16.4152 12.1L11.5426 6.92498L6.90924 12.25C6.72072 12.4666 6.50319 12.575 6.25666 12.575C6.01013 12.575 5.7926 12.4666 5.60408 12.25C5.41555 12.0333 5.32129 11.7833 5.32129 11.5C5.32129 11.2166 5.41555 10.9666 5.60408 10.75L10.89 4.67498C11.0495 4.49164 11.2453 4.39564 11.4773 4.38698C11.7094 4.37898 11.9124 4.46664 12.0864 4.64998L17.6551 10.525L20.5265 6.77498C20.686 6.55831 20.8928 6.44998 21.1469 6.44998C21.4004 6.44998 21.6141 6.54998 21.7882 6.74998C21.9477 6.93331 22.0312 7.15398 22.0387 7.41198C22.0457 7.67064 21.9694 7.89998 21.8099 8.09998L18.9168 11.85L21.6576 14.75C21.8607 14.95 21.9622 15.2 21.9622 15.5C21.9622 15.8 21.8679 16.0583 21.6794 16.275C21.5054 16.475 21.2951 16.575 21.0486 16.575C20.802 16.575 20.5845 16.4833 20.396 16.3L17.6986 13.45L15.5669 16.2C15.4073 16.4166 15.2008 16.5293 14.9473 16.538C14.6933 16.546 14.4792 16.45 14.3052 16.25L11.4773 13Z'
									fill='#AEB0B0'
								/>
							</g>
						</svg>
						Curva: Modelo Log-Log
					</h1>
					<div className='gray_area'></div>
				</div>
			</div>
		</div>
	)
}
