import React from 'react'

export const CheckboxStepPicture = ({
	img,
	settag,
	setwarning,
	setCadenaSeleccionada,
	grocers,
	checked,
}) => {
	const randomId = Math.random()
	return (
		<div className='CheckboxStepPictureWrapper'>
			<input
				type='checkbox'
				id={`checkbox_${randomId}`}
				checked={checked}
				onClick={(e) => {
					settag(true)
					setwarning(null)
					setCadenaSeleccionada(grocers)
				}}
			/>
			<label htmlFor={`checkbox_${randomId}`} className='CheckboxStepPicture'>
				<img src={img} alt='' />
			</label>
		</div>
	)
}
