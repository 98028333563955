import {useState} from 'react'
import Table from 'react-bootstrap/Table'
import {Link} from 'react-router-dom'
import {Navbar} from './Navbar'
import {TopNavigator} from './TopNavigator'
import {CodigoPop} from './CodigoPop'
import {VienesasTableNew} from './VienesasTableNew'
import {ToolTipCustom} from './ToolTipCustom'
import {CataLogoResumenFooterNew} from './CataLogoResumenFooterNew'

import '../assets/css/CataLogoResumen.css'
import greenArrow from '../assets/img/greenArrow.svg'
import orangeArrow from '../assets/img/orangeArrow.svg'

export const VienesasNew = () => {
	const [MouseDownState, setMouseDownState] = useState(null)
	const [CodigoPopState, setCodigoPop] = useState(false)

	return (
		<div className='CataLogoResumen'>
			{CodigoPopState && <CodigoPop setCodigoPop={setCodigoPop} />}

			<TopNavigator />
			<Navbar no_need_search />
			<div className='top_area top_area_differ'>
				<div className='option_table'>
					<Table>
						<tbody>
							<tr>
								<td>
									<Link to='/Seguimiento'>Seguimiento Catálogo</Link>
								</td>
								<td>
									<Link to='/CataLogoResumen'>Resumen Línea</Link>
								</td>
								<td>
									<Link to='/Vienesas' className='active'>
										Vienesas
									</Link>
								</td>
								<td>
									<Link to='/'>Jamones</Link>
								</td>
								<td>
									<Link to='/'>Mortadelas</Link>
								</td>
								<td>
									<Link to='/'>Longanizas</Link>
								</td>
								<td>
									<Link to='/'>Pates</Link>
								</td>
								<td>
									<Link to='/'>Madurados</Link>
								</td>
								<td>
									<Link to='/'>Arrollados</Link>
								</td>
								<td>
									<Link to='/SeguimientoLinea'>Seguimiento Línea</Link>
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>
			<div className='extralinewrapper d-flex'>
				<div className='link_area'>
					<p>Jumbo Abril 2022</p>
					<p>/</p>
					<p>Cecinas</p>
					<p>/</p>
					<p>Vienesas</p>
				</div>
				<nav>
					<li>
						<div
							className='box'
							onMouseUp={(e) => {
								e.target
									.querySelector('.ToolTipCustom')
									.classList.remove('show')

								clearInterval(MouseDownState)
							}}
							onMouseDown={(e) => {
								if (e.button !== 0) {
									return false
								}
								const MouseDownTimeOut = setTimeout(() => {
									e.target.querySelector('.ToolTipCustom').classList.add('show')
								}, [500])

								setMouseDownState(MouseDownTimeOut)
							}}
						>
							<ToolTipCustom value='$1650' />
							<h1>Prod. c/Mod.</h1>
							<p className='green'>
								<img src={greenArrow} alt='' />
								5/17 (42,1%)
							</p>
						</div>
					</li>

					<li>
						<div
							className='box'
							onMouseUp={(e) => {
								e.target
									.querySelector('.ToolTipCustom')
									.classList.remove('show')

								clearInterval(MouseDownState)
							}}
							onMouseDown={(e) => {
								if (e.button !== 0) {
									return false
								}
								const MouseDownTimeOut = setTimeout(() => {
									e.target.querySelector('.ToolTipCustom').classList.add('show')
								}, [500])

								setMouseDownState(MouseDownTimeOut)
							}}
						>
							<ToolTipCustom value='$5.550' />
							<h1>N° Act. / Imp. Vol.</h1>
							<p className='orange'>
								<img src={orangeArrow} alt='' />8 (25,6%)
							</p>
						</div>
					</li>

					<li>
						<div
							className='box'
							onMouseUp={(e) => {
								e.target
									.querySelector('.ToolTipCustom')
									.classList.remove('show')

								clearInterval(MouseDownState)
							}}
							onMouseDown={(e) => {
								if (e.button !== 0) {
									return false
								}
								const MouseDownTimeOut = setTimeout(() => {
									e.target.querySelector('.ToolTipCustom').classList.add('show')
								}, [500])

								setMouseDownState(MouseDownTimeOut)
							}}
						>
							<ToolTipCustom value='$2.350' />
							<h1>Var $.</h1>
							<p className='orange'>
								<img src={orangeArrow} alt='' />
								7,2%
							</p>
						</div>
					</li>

					<li>
						<div
							className='box'
							onMouseUp={(e) => {
								e.target
									.querySelector('.ToolTipCustom')
									.classList.remove('show')

								clearInterval(MouseDownState)
							}}
							onMouseDown={(e) => {
								if (e.button !== 0) {
									return false
								}
								const MouseDownTimeOut = setTimeout(() => {
									e.target.querySelector('.ToolTipCustom').classList.add('show')
								}, [500])

								setMouseDownState(MouseDownTimeOut)
							}}
						>
							<ToolTipCustom value='$1.250' />
							<h1>Var Vol.</h1>
							<p className='green'>
								<img src={greenArrow} alt='' />
								-12,3%
							</p>
						</div>
					</li>
				</nav>

				<div className='breadcrumb'>
					<h1>META</h1>
					<span>Volumen</span>
					<p>123,0 Ton.</p>
				</div>

				<div className='nav_wrapper_box'>
					<div className='inner_box'>
						<h1>ACTUAL</h1>
						<nav>
							<li>
								<div className='box'>
									<h1>Volumen</h1>
									<p>64,70%</p>
								</div>
							</li>

							<li>
								<div className='box'>
									<h1>Meta</h1>
									<p>64,70%</p>
								</div>
							</li>

							<li>
								<div className='box'>
									<h1>Cumpl</h1>
									<p className='orange'>
										<img src={orangeArrow} alt='' />
										95,3%
									</p>
								</div>
							</li>

							<li>
								<div className='box'>
									<h1>Var Vol.</h1>
									<p className='green'>
										<img src={greenArrow} alt='' />
										-12,3%
									</p>
								</div>
							</li>
						</nav>
					</div>

					<div className='inner_box orangec'>
						<h1>ÓPTIMO</h1>
						<nav>
							<li>
								<div className='box'>
									<h1>Volumen</h1>
									<p>64,70%</p>
								</div>
							</li>

							<li>
								<div className='box'>
									<h1>Meta</h1>
									<p>64,70%</p>
								</div>
							</li>

							<li>
								<div className='box'>
									<h1>Cumpl</h1>
									<p className='orange'>
										<img src={orangeArrow} alt='' />
										95,3%
									</p>
								</div>
							</li>

							<li>
								<div className='box'>
									<h1>Var Vol.</h1>
									<p className='green'>
										<img src={greenArrow} alt='' />
										-12,3%
									</p>
								</div>
							</li>
						</nav>
					</div>

					<div className='inner_box green'>
						<h1>PROPUESTO</h1>
						<nav>
							<li>
								<div className='box'>
									<h1>Volumen</h1>
									<p>64,70%</p>
								</div>
							</li>

							<li>
								<div className='box'>
									<h1>Meta</h1>
									<p>64,70%</p>
								</div>
							</li>

							<li>
								<div className='box'>
									<h1>Cumpl</h1>
									<p className='orange'>
										<img src={orangeArrow} alt='' />
										95,3%
									</p>
								</div>
							</li>

							<li>
								<div className='box'>
									<h1>Var Vol.</h1>
									<p className='green'>
										<img src={greenArrow} alt='' />
										-12,3%
									</p>
								</div>
							</li>
						</nav>
					</div>
				</div>
			</div>

			<VienesasTableNew setCodigoPop={setCodigoPop} />

			<CataLogoResumenFooterNew />
		</div>
	)
}
