import React, {useState} from 'react'
import lock from '../../../assets/img/lock.svg'
import {LoaderNew} from '../../LoaderNew'
export const PasswordChange = ({setpasswordChangePop}) => {
	const [passwordDone, setpasswordDone] = useState(false)
	const [currentPassword, setcurrentPassword] = useState('')
	const [newPassword, setnewPassword] = useState('')
	const [newPasswordMore, setnewPasswordMore] = useState('')
	return (
		<div className='PasswordChangePopup'>
			<div
				className='bg-black'
				onClick={(e) => {
					setpasswordDone(false)
					setpasswordChangePop(false)
				}}
			></div>
			{passwordDone === 'load' && (
				<div className='loader-wrapper'>
					<LoaderNew notdo />
				</div>
			)}

			{passwordDone === 'one' && (
				<div className='tick-pop'>
					<svg
						width='40'
						height='40'
						viewBox='0 0 40 40'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect width='40' height='40' rx='20' fill='#F0F4FC' />
						<g clipPath='url(#clip0_345_87117)'>
							<mask
								id='mask0_345_87117'
								maskUnits='userSpaceOnUse'
								x='4'
								y='4'
								width='32'
								height='32'
							>
								<rect x='4' y='4' width='32' height='32' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_345_87117)'>
								<path
									d='M16.7342 27.6333C16.5342 27.6333 16.3454 27.5999 16.1676 27.5333C15.9898 27.4666 15.8231 27.3555 15.6676 27.1999L9.93424 21.4666C9.64536 21.1777 9.50669 20.8164 9.51824 20.3826C9.52891 19.9497 9.67869 19.5888 9.96758 19.2999C10.2565 19.011 10.6178 18.8666 11.0516 18.8666C11.4845 18.8666 11.8342 19.011 12.1009 19.2999L16.7342 23.9333L27.9009 12.7666C28.1676 12.4777 28.5231 12.3333 28.9676 12.3333C29.412 12.3333 29.7787 12.4777 30.0676 12.7666C30.3342 13.0555 30.4676 13.4164 30.4676 13.8493C30.4676 14.283 30.3342 14.6444 30.0676 14.9333L17.8009 27.1999C17.6454 27.3555 17.4787 27.4666 17.3009 27.5333C17.1231 27.5999 16.9342 27.6333 16.7342 27.6333Z'
									fill='#3676F5'
								/>
							</g>
						</g>
						<defs>
							<clipPath id='clip0_345_87117'>
								<rect x='4' y='4' width='32' height='32' rx='16' fill='white' />
							</clipPath>
						</defs>
					</svg>

					<p>Tu contraseña ha sido cambiada con éxito</p>
					<button
						onClick={(e) => {
							setpasswordDone(false)
							setpasswordChangePop(false)
						}}
					>
						Aceptar
					</button>
					<svg
						width='10'
						height='10'
						viewBox='0 0 10 10'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M4.99994 6.0666L1.79993 9.2666C1.65549 9.41105 1.47771 9.48327 1.2666 9.48327C1.05549 9.48327 0.877713 9.41105 0.733268 9.2666C0.588824 9.12216 0.516602 8.94438 0.516602 8.73327C0.516602 8.52216 0.588824 8.34438 0.733268 8.19993L3.93327 4.99994L0.733268 1.79993C0.588824 1.65549 0.516602 1.47771 0.516602 1.2666C0.516602 1.05549 0.588824 0.877713 0.733268 0.733268C0.877713 0.588824 1.05549 0.516602 1.2666 0.516602C1.47771 0.516602 1.65549 0.588824 1.79993 0.733268L4.99994 3.93327L8.19993 0.733268C8.34438 0.588824 8.52216 0.516602 8.73327 0.516602C8.94438 0.516602 9.12216 0.588824 9.2666 0.733268C9.41105 0.877713 9.48327 1.05549 9.48327 1.2666C9.48327 1.47771 9.41105 1.65549 9.2666 1.79993L6.0666 4.99994L9.2666 8.19993C9.41105 8.34438 9.48327 8.52216 9.48327 8.73327C9.48327 8.94438 9.41105 9.12216 9.2666 9.2666C9.12216 9.41105 8.94438 9.48327 8.73327 9.48327C8.52216 9.48327 8.34438 9.41105 8.19993 9.2666L4.99994 6.0666Z'
							fill='#ADADAD'
						/>
					</svg>
				</div>
			)}

			{!passwordDone && (
				<div className='change-pop'>
					<div className='header-password-change'>
						<svg
							width='40'
							height='40'
							viewBox='0 0 40 40'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<rect width='40' height='40' rx='20' fill='#F0F4FC' />
							<g clipPath='url(#clip0_215_143793)'>
								<mask
									id='mask0_215_143793'
									maskUnits='userSpaceOnUse'
									x='4'
									y='4'
									width='32'
									height='32'
								>
									<rect x='4' y='4' width='32' height='32' fill='#D9D9D9' />
								</mask>
								<g mask='url(#mask0_215_143793)'>
									<path
										d='M12.1 33.5667C11.2556 33.5667 10.5391 33.2725 9.95067 32.684C9.36133 32.0947 9.06667 31.3778 9.06667 30.5334V17.4C9.06667 16.5778 9.36133 15.8667 9.95067 15.2667C10.5391 14.6667 11.2556 14.3667 12.1 14.3667H13.2V11.9334C13.2 10.0445 13.8609 8.43869 15.1827 7.11603C16.5053 5.79425 18.1111 5.13336 20 5.13336C21.8889 5.13336 23.4947 5.79425 24.8173 7.11603C26.1391 8.43869 26.8 10.0445 26.8 11.9334V14.3667H27.9C28.7444 14.3667 29.4613 14.6667 30.0507 15.2667C30.6391 15.8667 30.9333 16.5778 30.9333 17.4V30.5334C30.9333 31.3778 30.6391 32.0947 30.0507 32.684C29.4613 33.2725 28.7444 33.5667 27.9 33.5667H12.1ZM12.1 30.5334H27.9V17.4H12.1V30.5334ZM20 26.6334C20.7333 26.6334 21.3613 26.372 21.884 25.8494C22.4058 25.3276 22.6667 24.7 22.6667 23.9667C22.6667 23.2334 22.4058 22.6054 21.884 22.0827C21.3613 21.5609 20.7333 21.3 20 21.3C19.2667 21.3 18.6391 21.5609 18.1173 22.0827C17.5947 22.6054 17.3333 23.2334 17.3333 23.9667C17.3333 24.7 17.5947 25.3276 18.1173 25.8494C18.6391 26.372 19.2667 26.6334 20 26.6334ZM16.2333 14.3667H23.7667V11.9334C23.7667 10.8889 23.4 10 22.6667 9.2667C21.9333 8.53336 21.0444 8.1667 20 8.1667C18.9556 8.1667 18.0667 8.53336 17.3333 9.2667C16.6 10 16.2333 10.8889 16.2333 11.9334V14.3667Z'
										fill='#3676F5'
									/>
								</g>
							</g>
							<defs>
								<clipPath id='clip0_215_143793'>
									<rect
										width='32'
										height='32'
										fill='white'
										transform='translate(4 4)'
									/>
								</clipPath>
							</defs>
						</svg>
					</div>

					<div className='body-change-password'>
						<h1>Cambiar Contraseña</h1>
						<p>
							Las contraseñas fuertes incluyen números, letras y signos de
							puntuación
						</p>
						<div className='input-wrapper'>
							<label htmlFor=''>Ingresa tu contraseña actual</label>
							<div className='input-closet'>
								<img src={lock} alt='' />
								<input
									type='password'
									value={currentPassword}
									onChange={(e) => {
										setcurrentPassword(e.target.value)
									}}
									className={`${
										currentPassword.length > 0 && 'no-placeholder'
									}`}
								/>
								<p className='placeholder'>**********</p>
							</div>
						</div>
						<div className='break-password'></div>
						<div className='input-wrapper'>
							<label htmlFor=''>Ingresa tu nueva contraseña</label>
							<div className='input-closet'>
								<img src={lock} alt='' />
								<input
									type='password'
									value={newPassword}
									onChange={(e) => {
										setnewPassword(e.target.value)
									}}
									className={`${newPassword.length > 0 && 'no-placeholder'}`}
								/>
								<p className='placeholder'>**********</p>
							</div>
						</div>
						<div className='input-wrapper'>
							<label htmlFor=''>Ingresa tu contraseña una vez más</label>
							<div className='input-closet'>
								<img src={lock} alt='' />
								<input
									type='password'
									value={newPasswordMore}
									onChange={(e) => {
										setnewPasswordMore(e.target.value)
									}}
									className={`${
										newPasswordMore.length > 0 && 'no-placeholder'
									}`}
								/>
								<p className='placeholder'>**********</p>
							</div>
						</div>

						<div className='buttons-wrapper'>
							<button
								onClick={(e) => {
									setpasswordDone('load')
									setTimeout(() => {
										setpasswordDone('one')
									}, 2000)
								}}
							>
								Cambiar Contraseña
							</button>
							<button
								onClick={(e) => {
									setpasswordChangePop(false)
								}}
							>
								Cancelar
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
