import React from 'react'

import tick from '../assets/img/tick.svg'
export const InnerActiveSqguimiento = ({ActiveInner, current}) => {
	return (
		<>
			<tr
				className={`activate familia1 InnerActiveSqguimiento ${
					ActiveInner === current && 'activeRow'
				}`}
			>
				<td colSpan={5} className='inner_active'>
					<span></span>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>La Serena</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>$1.766</p>
					</div>
				</td>
				<td>
					<div className='display_flex   green'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>1.0%</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>$1.766</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>1.0%</p> <img src={tick} alt='' />
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						<p>1.0%</p> <img src={tick} alt='' />
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						<p>1.0%</p>
						<img src={tick} alt='' />
					</div>
				</td>
			</tr>
			<tr
				className={`activate familia1 InnerActiveSqguimiento ${
					ActiveInner === current && 'activeRow'
				}`}
			>
				<td colSpan={5} className='inner_active'>
					<span></span>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>Antofagasta</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>$1.766</p>
					</div>
				</td>
				<td>
					<div className='display_flex   green'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>1.0%</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>$1.766</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>1.0%</p> <img src={tick} alt='' />
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						<p>1.0%</p> <img src={tick} alt='' />
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						<p>1.0%</p>
						<img src={tick} alt='' />
					</div>
				</td>
			</tr>
			<tr
				className={`activate familia1 InnerActiveSqguimiento ${
					ActiveInner === current && 'activeRow'
				}`}
			>
				<td colSpan={5} className='inner_active'>
					<span></span>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>Valparaiso</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>$1.766</p>
					</div>
				</td>
				<td>
					<div className='display_flex   green'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>1.0%</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>$1.766</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>1.0%</p> <img src={tick} alt='' />
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						<p>1.0%</p> <img src={tick} alt='' />
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>$1.766</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						<p>1.0%</p>
						<img src={tick} alt='' />
					</div>
				</td>
			</tr>
		</>
	)
}
