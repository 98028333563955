import React, {PureComponent} from 'react'
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts'

const data = [
	{
		name: 'Page A',
		pesos: 4000,
		percentage: 2400,
		amt: 2400,
	},
	{
		name: 'Page B',
		pesos: 3000,
		percentage: 1398,
		amt: 2210,
	},
	{
		name: 'Page C',
		pesos: 2000,
		percentage: 9800,
		amt: 2290,
	},
	{
		name: 'Page D',
		pesos: 2780,
		percentage: 3908,
		amt: 2000,
	},
	{
		name: 'Page E',
		pesos: 1890,
		percentage: 4800,
		amt: 2181,
	},
	{
		name: 'Page F',
		pesos: 2390,
		percentage: 3800,
		amt: 2500,
	},
	{
		name: 'Page G',
		pesos: 3490,
		percentage: 4300,
		amt: 2100,
	},
]

export const StackedChartComponent = () => {
	return (
		<div style={{height: 300, width: '100%'}}>
			<ResponsiveContainer width='100%' height='100%'>
				<BarChart
					width={500}
					height={300}
					data={data}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' stroke='#F1F1F1' />
					<XAxis
						dataKey='name'
						style={{
							fontSize: '10px',
							fontFamily: 'Montserrat',
						}}
						tick={{fill: '#7A7A7A'}}
						tickLine={{stroke: '#fff'}}
						stroke='#F1F1F1'
					/>
					<YAxis
						style={{
							fontSize: '10px',
							fontFamily: 'Montserrat',
						}}
						tick={{fill: '#7A7A7A'}}
						tickLine={{stroke: '#fff'}}
						stroke='#F1F1F1'
					/>
					<Tooltip />
					<Legend
						formatter={(value, entry, index) => (
							<span
								className='text-color-class'
								style={{
									fontSize: 13,
									color: '#000',
									fontWeight: 600,
									fontFamily: 'Montserrat',
								}}
							>
								{value}
							</span>
						)}
					/>
					<Bar dataKey='percentage' stackId='a' fill='#3676F5' barSize={20} />
					<Bar dataKey='pesos' stackId='a' fill='#FFAC24' barSize={20} />
				</BarChart>
			</ResponsiveContainer>
		</div>
	)
}
