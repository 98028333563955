import {useState} from 'react'
import filter from '..//assets/img/filter.svg'
import {Filter} from '../utils/Filter'
export const FilterArea = ({point, collection, setCollection}) => {
	const [active, setActive] = useState(false)
	return (
		<div className='input'>
			<img src={filter} alt='' />
			{collection.length > 0 ? (
				<>
					<div
						style={{cursor: 'pointer'}}
						className='input_filter_div'
						onClick={(e) => {
							setActive(!active)
						}}
					>
						{collection.map((EachCollection) => (
							<span key={EachCollection.name}>{EachCollection.name}</span>
						))}
					</div>
					<svg
						width='15'
						height='15'
						viewBox='0 0 15 15'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect
							width='18'
							height='18'
							transform='translate(0 17) rotate(-90)'
							fill='white'
						/>
						<path
							d='M7.35355 3.64645C7.15829 3.45118 6.84171 3.45118 6.64645 3.64645C6.45118 3.84171 6.45118 4.15829 6.64645 4.35355L7.35355 3.64645ZM11 8L11.3536 8.35355C11.5488 8.15829 11.5488 7.84171 11.3536 7.64645L11 8ZM6.64645 11.6464C6.45118 11.8417 6.45118 12.1583 6.64645 12.3536C6.84171 12.5488 7.15829 12.5488 7.35355 12.3536L6.64645 11.6464ZM6.64645 4.35355L10.6464 8.35355L11.3536 7.64645L7.35355 3.64645L6.64645 4.35355ZM10.6464 7.64645L6.64645 11.6464L7.35355 12.3536L11.3536 8.35355L10.6464 7.64645Z'
							fill='#E8E8E8'
						/>
					</svg>
				</>
			) : (
				<input
					type='text'
					placeholder='Filtrar'
					readOnly
					style={{cursor: 'pointer'}}
					onClick={(e) => {
						setActive(!active)
					}}
				/>
			)}

			{active && (
				<ul className='filter_list'>
					{Filter.map((EachFilter) => (
						<>
							{EachFilter.id === '10' ? (
								<li style={{padding: '6px 0.8rem'}}>{EachFilter.name}</li>
							) : (
								<li>
									<input
										type='checkbox'
										name='filter_1'
										id={`${point}_${EachFilter.id}`}
										onChange={(e) => {
											const FilterArea = collection.filter((EachCollection) => {
												if (EachCollection.id === EachFilter.id) {
													return true
												}
												return false
											})

											if (FilterArea.length > 0) {
												const FilterAreaInner = collection.filter(
													(EachCollection) => {
														if (EachCollection.id !== EachFilter.id) {
															return true
														}
														return false
													}
												)
												setCollection(FilterAreaInner)
											} else {
												setCollection([
													...collection,
													{id: EachFilter.id, name: EachFilter.name},
												])
											}
										}}
									/>
									<label htmlFor={`${point}_${EachFilter.id}`}>
										<div className='checbox_wrapper'>
											<span className='ball'></span>
										</div>
										<p>{EachFilter.name}</p>
									</label>
								</li>
							)}
						</>
					))}
				</ul>
			)}
		</div>
	)
}
