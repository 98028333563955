import img1 from '../assets/img/Mayorista.png'
import Jumbo from '../assets/img/Jumbo.svg'
import Líder from '../assets/img/Lider.png'
import Santa from '../assets/img/Santa Isabel.svg'
export const Products = [
	{
		topText: 'En Edición',
		mainp: 'Nombre de Tarjeta',
		bottomp1: 'Abril 2022',
		bottomp2: 'Catálogo',
		Img: img1,
	},
	{
		topText: 'En Edición',
		mainp: 'Jumbo Abril 2022',
		bottomp1: 'CATÁLOGO',
		bottomp2: 'MODERNO',
		Img: Jumbo,
	},

	{
		topText: 'DESCARTADA',
		mainp: 'Lider 12-21',
		bottomp1: 'CATÁLOGO',
		bottomp2: 'MODERNO',
		Img: Líder,
	},

	{
		topText: 'PREAPROBADA',
		mainp: 'Salchichas Agosto 22',
		bottomp1: 'MODALIDAD',
		bottomp2: 'CANAL',
		Img: Santa,
	},
]
