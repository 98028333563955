import React, {useState} from 'react'
import {Navbar} from './Navbar'
import '../assets/css/Seguimiento.css'
import {CataLogoResumenFooter} from './CataLogoResumenFooter'
import Table from 'react-bootstrap/Table'
import {Link} from 'react-router-dom'
import {SeguimientoTable} from './SeguimientoTable'
import {OfertaFooter} from './OfertaFooter'
import {TopNavigatorOferta} from './TopNavigatorOferta'
import {SeguimientoTableOferta} from './SeguimientoTableOferta'
export const SeguimientoOferta = () => {
	const [active, setActive] = useState(false)

	return (
		<div className='CataLogoResumen'>
			<TopNavigatorOferta />
			<Navbar no_need_search />
			<div className='top_area top_area_differ'>
				<div className='option_table'>
					<Table>
						<tbody>
							<tr>
								<td>
									<Link to='/ResumenDeOferta'>Resumen Oferta</Link>
								</td>
								<td>
									<Link to='/SeguimientoOferta' className='active'>
										Seguimiento Oferta
									</Link>
								</td>
								<td>
									<Link to='/Vienesas'>Ingreso Productos</Link>
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>
			<div className='link_area'>
				<p>Jumbo Abril 2022</p>
				<p>/</p>
				<p>Seguimiento Catálogo</p>
			</div>

			<SeguimientoTableOferta />

			<OfertaFooter />
		</div>
	)
}
