import React, {useState} from 'react'
import {CatalogoDropdown} from '../componentsRef/CatalogoDropdown'

import Cerrar from '../assets/img/Cerrar.svg'

export const PromotionalVariableNew = ({setPromotionalVariable}) => {
	const [Mecanica, setMecanica] = useState('1xY')
	const [Exhibición, setExhibición] = useState('Isla')
	const [Activación, setActivación] = useState('Lista')
	const [Adicional, setAdicional] = useState('')
	const [Adherencia, setAdherencia] = useState('')

	return (
		<div className='popupWrapper ' id='popupCatalog' style={{zIndex: 99999}}>
			<div className='popup SaveCatalog ExportPop PromotionalVariable PromotionalVariableNew'>
				<div className='close_icon'>
					<img
						src={Cerrar}
						alt=''
						onClick={(e) => {
							setPromotionalVariable(false)
						}}
					/>
				</div>
				<div className='topexport'>
					<h1 style={{marginLeft: 0}}>Variables Promocionales</h1>
				</div>
				<p>
					<a href='#'>X6000</a> Salchichas Sureñas PF (6x1Kg)
				</p>

				<div className='input_container'>
					<label htmlFor=''>Material POP</label>
					<div className='input_wrapper'>
						<CatalogoDropdown
							setVal={setMecanica}
							head='Selecciona'
							list={['Opcion 1', 'Opcion 2', 'Opcion 3', 'Opcion 4']}
						/>
					</div>
				</div>

				<div className='input_container'>
					<label htmlFor=''>Incentivo Económico</label>
					<div className='input_wrapper'>
						<input
							type='text'
							placeholder='$'
							className={`${Adicional !== '' && 'active'}`}
							value={Adicional}
							onChange={(e) => {
								setAdicional(e.target.value)
							}}
						/>
					</div>
				</div>

				<div className='input_container'>
					<label htmlFor=''>Seguimiento</label>
					<div className='input_wrapper'>
						<CatalogoDropdown
							head='Selecciona'
							list={['Lista']}
							setVal={setActivación}
						/>
					</div>
				</div>
				<div className='input_container'>
					<label htmlFor=''>Premios</label>
					<div className='input_wrapper'>
						<CatalogoDropdown
							head='Selecciona'
							list={['Isla']}
							setVal={setExhibición}
						/>
					</div>
				</div>
				<div className='buttons_wrapper' style={{marginTop: 30}}>
					<button>Cancelar</button>
					<button>Aceptar</button>
				</div>
			</div>
		</div>
	)
}
