import PromoApi from '../utils/config/apiPromobooster'
import {envVariables} from '../utils/envVariables/envVariables'
import {useContext} from 'react'
import {AuthContext} from '../context/auth/AuthContext'

const PATH = {
	getAll: 'promotion/listall',
	createNewCatalog: 'promotion/create',
	getAllChains: 'promotion/data_create',
}

export const getAllCatalogs = async () => {
	const token = localStorage.getItem('token')
	return PromoApi.get(`${envVariables.baseUrl}/${PATH.getAll}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}).then((response) => {
		// Manejar la respuesta exitosa aquí
		// console.log('Respuesta exitosa:', response.data);
		console.log("conexion exitosa 1");
		return response
	})
	  .catch((error) => {
		// const {setAuthState} = useContext(AuthContext)
		// setAuthState(null)
		if (error.response && error.response.status === 401) {
		  // Manejar el error 401 (No Autorizado) aquí
		  console.error('Error 401: No Autorizado', error);
		  window.location.href = '/';
		  // Puedes redirigir al usuario a la página de inicio de sesión u otras acciones relacionadas con la autenticación aquí.
		} else {
		  // Manejar otros errores de solicitud aquí
		  console.error('Error de solicitud:', error);
		  window.location.href = '/';
		}
		
	  });
}

export const createNewCatalogs = async (body) => {
	const token = localStorage.getItem('token')
	return PromoApi.post(
		`${envVariables.baseUrl}/${PATH.createNewCatalog}`,
		body,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	).then((response) => {
		// Manejar la respuesta exitosa aquí
		// console.log('Respuesta exitosa:', response.data);
		console.log("conexion exitosa 1");
		return response
	})
	  .catch((error) => {
		// const {setAuthState} = useContext(AuthContext)
		// setAuthState(null)
		if (error.response && error.response.status === 401) {
		  // Manejar el error 401 (No Autorizado) aquí
		  console.error('Error 401: No Autorizado', error);
		  window.location.href = '/';
		  // Puedes redirigir al usuario a la página de inicio de sesión u otras acciones relacionadas con la autenticación aquí.
		} else {
		  // Manejar otros errores de solicitud aquí
		  console.error('Error de solicitud:', error);
		  window.location.href = '/';
		}
		
	  });
}

export const getAllChains = async () => {
	const token = localStorage.getItem('token')
	return PromoApi.get(`${envVariables.baseUrl}/${PATH.getAllChains}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}).then((response) => {
		// Manejar la respuesta exitosa aquí
		// console.log('Respuesta exitosa:', response.data);
		console.log("conexion exitosa 1");
		return response
	})
	  .catch((error) => {
		// const {setAuthState} = useContext(AuthContext)
		// setAuthState(null)
		if (error.response && error.response.status === 401) {
		  // Manejar el error 401 (No Autorizado) aquí
		  console.error('Error 401: No Autorizado', error);
		  window.location.href = '/';
		  // Puedes redirigir al usuario a la página de inicio de sesión u otras acciones relacionadas con la autenticación aquí.
		} else {
		  // Manejar otros errores de solicitud aquí
		  console.error('Error de solicitud:', error);
		  window.location.href = '/';
		}
		
	  });
}
