import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import loaderImg from '../assets/img/loader_img.png'
export const LoaderNew = ({notdo}) => {
	const navigate = useNavigate()
	useEffect(() => {
		if (!notdo) {
			setTimeout(() => {
				navigate('/LoginNew')
			}, 2000)
		}
	}, [])
	return (
		<div className='LoaderNew'>
			<img src={loaderImg} alt='' />
			<svg
				width='25'
				height='30'
				viewBox='0 0 25 30'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M0 0V29.4H7.476V21.126H12.81C20.328 21.126 24.654 17.136 24.654 10.248C24.654 3.696 20.328 0 12.81 0H0ZM7.476 5.88H12.432C15.708 5.88 17.598 7.434 17.598 10.5C17.598 13.608 15.708 15.246 12.432 15.246H7.476V5.88Z'
					fill='#2E2E2E'
				/>
			</svg>
			<svg
				width='6'
				height='6'
				viewBox='0 0 6 6'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='3' cy='3' r='3' fill='url(#paint0_linear_459_158955)' />
				<defs>
					<linearGradient
						id='paint0_linear_459_158955'
						x1='0.243243'
						y1='1.16798e-06'
						x2='7.34243'
						y2='0.446138'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#FFAC23' />
						<stop offset='1' stopColor='#FF5334' />
					</linearGradient>
				</defs>
			</svg>
		</div>
	)
}
