import React, {useState} from 'react'

export const NotificationBox = ({
	firstClassName,
	changeTextName,
	condition,
}) => {
	const [active, setActive] = useState(false)
	return (
		<a href='#' className={`${firstClassName} NotificationBox`}>
			{condition === 'comentó' && (
				<svg
					width='32'
					height='32'
					viewBox='0 0 32 32'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect width='32' height='32' rx='16' fill='#BDE9E8' />
					<mask
						id='mask0_201_637636'
						maskUnits='userSpaceOnUse'
						x='6'
						y='6'
						width='20'
						height='20'
					>
						<rect x='6' y='6' width='20' height='20' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_201_637636)'>
						<path
							d='M7.50024 22.2083V9.39583C7.50024 8.86806 7.68413 8.42 8.05191 8.05167C8.42024 7.68389 8.8683 7.5 9.39608 7.5H22.6044C23.1322 7.5 23.5802 7.68389 23.9486 8.05167C24.3164 8.42 24.5002 8.86806 24.5002 9.39583V19.2708C24.5002 19.7986 24.3164 20.2467 23.9486 20.615C23.5802 20.9828 23.1322 21.1667 22.6044 21.1667H10.8336L9.10441 22.8958C8.81274 23.1875 8.47247 23.2536 8.08358 23.0942C7.69469 22.9342 7.50024 22.6389 7.50024 22.2083ZM9.39608 20.0833L10.1877 19.2708H22.6044V9.39583H9.39608V20.0833Z'
							fill='#29BAB8'
						/>
					</g>
				</svg>
			)}

			{condition === 'editó' && (
				<svg
					width='32'
					height='32'
					viewBox='0 0 32 32'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect width='32' height='32' rx='16' fill='#FEE6C1' />
					<mask
						id='mask0_201_638037'
						maskUnits='userSpaceOnUse'
						x='6'
						y='6'
						width='20'
						height='20'
					>
						<rect x='6' y='6' width='20' height='20' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_201_638037)'>
						<path
							d='M9.29287 24.5417C8.76509 24.5417 8.31731 24.3575 7.94953 23.9892C7.5812 23.6214 7.39703 23.1736 7.39703 22.6458V11.1042C7.39703 10.5764 7.5812 10.1283 7.94953 9.76C8.31731 9.39222 8.76509 9.20833 9.29287 9.20833H16.7304L14.8345 11.1042H9.29287V22.6458H20.8345V17.1042L22.7304 15.2083V22.6458C22.7304 23.1736 22.5465 23.6214 22.1787 23.9892C21.8104 24.3575 21.3623 24.5417 20.8345 24.5417H9.29287ZM18.4595 9.83333L19.7512 11.0833L14.272 16.5417V17.6667H15.3762L20.8554 12.1875L22.1262 13.4375L16.6887 18.8958C16.5081 19.0625 16.3034 19.1981 16.0745 19.3025C15.8451 19.4064 15.6054 19.4583 15.3554 19.4583H13.4387C13.1748 19.4583 12.9493 19.3644 12.762 19.1767C12.5743 18.9894 12.4804 18.7639 12.4804 18.5V16.5833C12.4804 16.3333 12.529 16.0936 12.6262 15.8642C12.7234 15.6353 12.8623 15.4306 13.0429 15.25L18.4595 9.83333ZM22.1262 13.4375L18.4595 9.83333L20.4595 7.8125C20.8484 7.4375 21.3068 7.25 21.8345 7.25C22.3623 7.25 22.8068 7.44444 23.1679 7.83333L24.1262 8.79167C24.4873 9.16667 24.6679 9.61472 24.6679 10.1358C24.6679 10.6564 24.4873 11.0972 24.1262 11.4583L22.1262 13.4375Z'
							fill='#FFAC23'
						/>
					</g>
				</svg>
			)}

			{condition === 'movió' && (
				<svg
					width='32'
					height='32'
					viewBox='0 0 32 32'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect width='32' height='32' rx='16' fill='#DBE7FF' />
					<mask
						id='mask0_201_638522'
						maskUnits='userSpaceOnUse'
						x='6'
						y='6'
						width='20'
						height='20'
					>
						<rect x='6' y='6' width='20' height='20' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_201_638522)'>
						<path
							d='M13.5407 19.7291C13.3602 19.5208 13.2699 19.288 13.2699 19.0308C13.2699 18.7741 13.3602 18.5555 13.5407 18.3749L14.9574 16.9583H9.2074C8.94351 16.9583 8.72129 16.8644 8.54073 16.6766C8.36018 16.4894 8.2699 16.2638 8.2699 15.9999C8.2699 15.736 8.36018 15.5105 8.54073 15.3233C8.72129 15.1355 8.94351 15.0416 9.2074 15.0416H14.9574L13.5407 13.6249C13.3463 13.4305 13.2491 13.2046 13.2491 12.9474C13.2491 12.6908 13.3463 12.4652 13.5407 12.2708C13.7074 12.0902 13.9227 11.9999 14.1866 11.9999C14.4505 11.9999 14.6727 12.0902 14.8532 12.2708L17.9157 15.3333C18.013 15.4305 18.0824 15.5346 18.1241 15.6458C18.1657 15.7569 18.1866 15.8749 18.1866 15.9999C18.1866 16.1249 18.1657 16.243 18.1241 16.3541C18.0824 16.4652 18.013 16.5694 17.9157 16.6666L14.8324 19.7499C14.6518 19.9305 14.433 20.0138 14.1757 19.9999C13.9191 19.986 13.7074 19.8958 13.5407 19.7291ZM16.9157 23.6666C16.638 23.6666 16.4088 23.5763 16.2282 23.3958C16.0477 23.2152 15.9574 22.993 15.9574 22.7291C15.9574 22.4513 16.0477 22.2221 16.2282 22.0416C16.4088 21.861 16.638 21.7708 16.9157 21.7708H21.8324V10.2291H16.9157C16.638 10.2291 16.4088 10.1388 16.2282 9.95825C16.0477 9.7777 15.9574 9.54853 15.9574 9.27075C15.9574 9.00686 16.0477 8.78464 16.2282 8.60409C16.4088 8.42353 16.638 8.33325 16.9157 8.33325H21.8324C22.3602 8.33325 22.8082 8.51714 23.1766 8.88492C23.5443 9.25325 23.7282 9.70131 23.7282 10.2291V21.7708C23.7282 22.2985 23.5443 22.7466 23.1766 23.1149C22.8082 23.4827 22.3602 23.6666 21.8324 23.6666H16.9157Z'
							fill='#3676F5'
						/>
					</g>
				</svg>
			)}

			{condition === 'gray' && (
				<svg
					width='32'
					height='32'
					viewBox='0 0 32 32'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect width='32' height='32' rx='16' fill='#FAFAFA' />
					<mask
						id='mask0_201_639617'
						maskUnits='userSpaceOnUse'
						x='6'
						y='6'
						width='20'
						height='20'
					>
						<rect x='6' y='6' width='20' height='20' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_201_639617)'>
						<path
							d='M9.29287 24.5417C8.76509 24.5417 8.31731 24.3575 7.94953 23.9892C7.5812 23.6214 7.39703 23.1736 7.39703 22.6458V11.1042C7.39703 10.5764 7.5812 10.1283 7.94953 9.76C8.31731 9.39222 8.76509 9.20833 9.29287 9.20833H16.7304L14.8345 11.1042H9.29287V22.6458H20.8345V17.1042L22.7304 15.2083V22.6458C22.7304 23.1736 22.5465 23.6214 22.1787 23.9892C21.8104 24.3575 21.3623 24.5417 20.8345 24.5417H9.29287ZM18.4595 9.83333L19.7512 11.0833L14.272 16.5417V17.6667H15.3762L20.8554 12.1875L22.1262 13.4375L16.6887 18.8958C16.5081 19.0625 16.3034 19.1981 16.0745 19.3025C15.8451 19.4064 15.6054 19.4583 15.3554 19.4583H13.4387C13.1748 19.4583 12.9493 19.3644 12.762 19.1767C12.5743 18.9894 12.4804 18.7639 12.4804 18.5V16.5833C12.4804 16.3333 12.529 16.0936 12.6262 15.8642C12.7234 15.6353 12.8623 15.4306 13.0429 15.25L18.4595 9.83333ZM22.1262 13.4375L18.4595 9.83333L20.4595 7.8125C20.8484 7.4375 21.3068 7.25 21.8345 7.25C22.3623 7.25 22.8068 7.44444 23.1679 7.83333L24.1262 8.79167C24.4873 9.16667 24.6679 9.61472 24.6679 10.1358C24.6679 10.6564 24.4873 11.0972 24.1262 11.4583L22.1262 13.4375Z'
							fill='#949494'
						/>
					</g>
				</svg>
			)}

			<div className='presentation'>
				<h1>
					<span>Usuario</span>
					{changeTextName}
					<span>Promoción</span>
				</h1>
				<p>hace 15min</p>
			</div>

			{active ? (
				<svg
					width='32'
					height='32'
					onClick={(e) => {
						setActive(false)
					}}
					viewBox='0 0 32 32'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask
						id='mask0_144_543594'
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='32'
						height='32'
					>
						<rect width='32' height='32' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_144_543594)'>
						<path
							d='M7.7668 18.8C6.98902 18.8 6.32813 18.5222 5.78413 17.9666C5.23924 17.4111 4.9668 16.7555 4.9668 16C4.9668 15.2222 5.23924 14.5608 5.78413 14.016C6.32813 13.472 6.98902 13.2 7.7668 13.2C8.52235 13.2 9.17258 13.472 9.71746 14.016C10.2615 14.5608 10.5335 15.2222 10.5335 16C10.5335 16.7555 10.2615 17.4111 9.71746 17.9666C9.17258 18.5222 8.52235 18.8 7.7668 18.8ZM16.0001 18.8C15.2446 18.8 14.589 18.5222 14.0335 17.9666C13.4779 17.4111 13.2001 16.7555 13.2001 16C13.2001 15.2222 13.4779 14.5608 14.0335 14.016C14.589 13.472 15.2446 13.2 16.0001 13.2C16.7779 13.2 17.4392 13.472 17.9841 14.016C18.5281 14.5608 18.8001 15.2222 18.8001 16C18.8001 16.7555 18.5281 17.4111 17.9841 17.9666C17.4392 18.5222 16.7779 18.8 16.0001 18.8ZM24.2335 18.8C23.4779 18.8 22.8277 18.5222 22.2828 17.9666C21.7388 17.4111 21.4668 16.7555 21.4668 16C21.4668 15.2222 21.7388 14.5608 22.2828 14.016C22.8277 13.472 23.4779 13.2 24.2335 13.2C25.0112 13.2 25.6726 13.472 26.2175 14.016C26.7615 14.5608 27.0335 15.2222 27.0335 16C27.0335 16.7555 26.7615 17.4111 26.2175 17.9666C25.6726 18.5222 25.0112 18.8 24.2335 18.8Z'
							fill='#7A7A7A'
						/>
					</g>
				</svg>
			) : (
				<svg
					width='32'
					height='32'
					onClick={(e) => {
						setActive(true)
					}}
					viewBox='0 0 32 32'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask
						id='mask0_201_637977'
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='32'
						height='32'
					>
						<rect width='32' height='32' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_201_637977)'>
						<path
							d='M7.7668 18.8C6.98902 18.8 6.32813 18.5222 5.78413 17.9666C5.23924 17.4111 4.9668 16.7555 4.9668 16C4.9668 15.2222 5.23924 14.5608 5.78413 14.016C6.32813 13.472 6.98902 13.2 7.7668 13.2C8.52235 13.2 9.17257 13.472 9.71746 14.016C10.2615 14.5608 10.5335 15.2222 10.5335 16C10.5335 16.7555 10.2615 17.4111 9.71746 17.9666C9.17257 18.5222 8.52235 18.8 7.7668 18.8ZM16.0001 18.8C15.2446 18.8 14.589 18.5222 14.0335 17.9666C13.4779 17.4111 13.2001 16.7555 13.2001 16C13.2001 15.2222 13.4779 14.5608 14.0335 14.016C14.589 13.472 15.2446 13.2 16.0001 13.2C16.7779 13.2 17.4392 13.472 17.9841 14.016C18.5281 14.5608 18.8001 15.2222 18.8001 16C18.8001 16.7555 18.5281 17.4111 17.9841 17.9666C17.4392 18.5222 16.7779 18.8 16.0001 18.8ZM24.2335 18.8C23.4779 18.8 22.8277 18.5222 22.2828 17.9666C21.7388 17.4111 21.4668 16.7555 21.4668 16C21.4668 15.2222 21.7388 14.5608 22.2828 14.016C22.8277 13.472 23.4779 13.2 24.2335 13.2C25.0112 13.2 25.6726 13.472 26.2175 14.016C26.7615 14.5608 27.0335 15.2222 27.0335 16C27.0335 16.7555 26.7615 17.4111 26.2175 17.9666C25.6726 18.5222 25.0112 18.8 24.2335 18.8Z'
							fill='#ADADAD'
						/>
					</g>
				</svg>
			)}
			{active && (
				<div className='option_notification'>
					<a href='#'>
						<svg
							width='16'
							height='16'
							viewBox='0 0 16 16'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_144_632968'
								maskUnits='userSpaceOnUse'
								x='2'
								y='2'
								width='12'
								height='12'
							>
								<rect x='2' y='2' width='12' height='12' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_144_632968)'>
								<path
									d='M6.77534 10.8625C6.70034 10.8625 6.62951 10.85 6.56284 10.825C6.49618 10.8 6.43368 10.7583 6.37534 10.7L4.22534 8.55C4.11701 8.44167 4.06501 8.30617 4.06934 8.1435C4.07334 7.98117 4.12951 7.84583 4.23784 7.7375C4.34618 7.62917 4.48168 7.575 4.64434 7.575C4.80668 7.575 4.93784 7.62917 5.03784 7.7375L6.77534 9.475L10.9628 5.2875C11.0628 5.17917 11.1962 5.125 11.3628 5.125C11.5295 5.125 11.667 5.17917 11.7753 5.2875C11.8753 5.39583 11.9253 5.53117 11.9253 5.6935C11.9253 5.85617 11.8753 5.99167 11.7753 6.1L7.17534 10.7C7.11701 10.7583 7.05451 10.8 6.98784 10.825C6.92118 10.85 6.85034 10.8625 6.77534 10.8625Z'
									fill='#7A7A7A'
								/>
							</g>
						</svg>

						<p>Marcar como no leída</p>
					</a>

					<a href='#'>
						<svg
							width='16'
							height='16'
							viewBox='0 0 16 16'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_144_573146'
								maskUnits='userSpaceOnUse'
								x='2'
								y='2'
								width='12'
								height='12'
							>
								<rect x='2' y='2' width='12' height='12' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_144_573146)'>
								<path
									d='M5.46257 12.6C5.15424 12.6 4.88757 12.4896 4.66257 12.269C4.43757 12.048 4.32507 11.7791 4.32507 11.4625V5.03745C4.16674 5.03745 4.03341 4.98112 3.92507 4.86845C3.81674 4.75612 3.76257 4.62078 3.76257 4.46245C3.76257 4.30412 3.81674 4.17078 3.92507 4.06245C4.03341 3.95412 4.16674 3.89995 4.32507 3.89995H6.43757C6.43757 3.73328 6.49174 3.59578 6.60007 3.48745C6.70841 3.37912 6.84174 3.32495 7.00007 3.32495H9.00007C9.15841 3.32495 9.29174 3.37912 9.40007 3.48745C9.50841 3.59578 9.56257 3.73328 9.56257 3.89995H11.6751C11.8334 3.89995 11.9667 3.95412 12.0751 4.06245C12.1834 4.17078 12.2376 4.30412 12.2376 4.46245C12.2376 4.62078 12.1834 4.75612 12.0751 4.86845C11.9667 4.98112 11.8334 5.03745 11.6751 5.03745V11.4625C11.6751 11.7791 11.5626 12.048 11.3376 12.269C11.1126 12.4896 10.8459 12.6 10.5376 12.6H5.46257ZM5.46257 5.03745V11.4625H10.5376V5.03745H5.46257ZM6.45007 9.96245C6.45007 10.1125 6.50007 10.2396 6.60007 10.344C6.70007 10.448 6.82507 10.5 6.97507 10.5C7.12507 10.5 7.25224 10.448 7.35657 10.344C7.46057 10.2396 7.51257 10.1125 7.51257 9.96245V6.53745C7.51257 6.38745 7.46057 6.26028 7.35657 6.15595C7.25224 6.05195 7.12507 5.99995 6.97507 5.99995C6.82507 5.99995 6.70007 6.05195 6.60007 6.15595C6.50007 6.26028 6.45007 6.38745 6.45007 6.53745V9.96245ZM8.48757 9.96245C8.48757 10.1125 8.53974 10.2396 8.64407 10.344C8.74807 10.448 8.87507 10.5 9.02507 10.5C9.17507 10.5 9.30007 10.448 9.40007 10.344C9.50007 10.2396 9.55007 10.1125 9.55007 9.96245V6.53745C9.55007 6.38745 9.50007 6.26028 9.40007 6.15595C9.30007 6.05195 9.17507 5.99995 9.02507 5.99995C8.87507 5.99995 8.74807 6.05195 8.64407 6.15595C8.53974 6.26028 8.48757 6.38745 8.48757 6.53745V9.96245Z'
									fill='#7A7A7A'
								/>
							</g>
						</svg>

						<p>Eliminar esta notificación</p>
					</a>
				</div>
			)}
		</a>
	)
}
